import { IReportPost } from 'root/models';
import { FeedUpdate } from 'root/pages/Feed/Container/Update';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ReportPostForm } from '../../Component/ReportPostForm';
import { ReportPostActionType, ReportPostGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'reportPost-form': ReportPostForm,
    'update-post-form': FeedUpdate
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.reportPost.detailLoading
    })
  }
})

export class ReportPostUpdate extends Vue {

  public get reportPostId(): string {
    return this.$route.params.reportPostId;
  }

  public get detail(): IReportPost {
    return this.$store
      .getters[ReportPostGetterType.ReportPost](this.reportPostId);
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        ReportPostActionType.ReportPostGetById, {
          id: this.reportPostId
        }
      );
    });
  }
}
