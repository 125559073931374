import { PaginationState } from 'root/store';
import { getSelectArrayOfArray } from 'root/utils';
import Vue from 'vue';
import Component from 'vue-class-component';
import { FeedActionType } from '../../Store/types';

@Component({
  template: require('./view.html'),
})

export class FeedSearchForm extends Vue {

  public sizes: number[] = getSelectArrayOfArray([20, 50, 100, 200]);

  public size: number = 20;
  public searchText: string = '';
  public isFeatured: boolean = false;
  public isPinned: boolean = false;
  public isAdvertisement: boolean = false;

  public handleSizeChange() {
    this.$store.dispatch(FeedActionType.FeedPaginationChange, {
      pagination: PaginationState({
        size: this.size
      })
    });
  }

  public handleSearchBtn() {

    const filterParams = {
      title: this.searchText,
      isFeatured: this.isFeatured ? true : '',
      isPinned: this.isPinned ? true : '',
      isAdvertisement: this.isAdvertisement ? true : ''
    };
    this.$store.dispatch(FeedActionType.FeedFilter, {
      params: {
        filterParams,
        pagination: PaginationState({
          size: this.size
        })
      }
    });
  }

  public handleResetBtn() {
    this.searchText = '';
    this.isFeatured = false;
    this.isPinned = false;
    this.isAdvertisement = false;

    const filterParams = {};

    this.$store.dispatch(FeedActionType.FeedFilter, {
      params: {
        filterParams,
        pagination: PaginationState({
          page: 1
        })
      }
    });
  }

}
