import { Form } from 'element-ui';
import { IReportPost, IReportPostCreateForm, ReportPostCreateDefaultForm } from 'root/models';
import { RouterDictionaryFeed } from 'root/pages/Feed';
import { FeedUpdate } from 'root/pages/Feed/Container/Update';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryReportPost } from '../..';
@Component({
  template: require('./view.html'),
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean
  },
  components: {
    'update-post-form': FeedUpdate
  },
  watch: {
    data() {
      this.dataChange();
    }
  }
})
export class ReportPostForm extends Vue {
  public $refs: {
    form: Form;
  };

  public form: IReportPostCreateForm = ReportPostCreateDefaultForm();
  public data: IReportPost;

  public getPostLink(id) {
    return `${RouterDictionaryFeed.Feed}/update/${id}`;
  }

  public handleIgnore() {
    this.$router.push({
      path: RouterDictionaryReportPost.ReportPost
    });
  }

  public mounted() {
    this.dataChange();
  }

  public dataChange() {
    if (!this.data) {
      return;
    }

    this.form = { ...this.data };
  }

}
