import { IVersion } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { VERSION_MODULE_NAME } from './types';

export type IVersionState = ICRUDState<IVersion>;

export const defaultState: IVersionState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(VERSION_MODULE_NAME),
};
