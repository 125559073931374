export enum TypeReferenceNotificationSetting {
  Plan = 'plan',
  Project = 'project',
  ProjectTask = 'project-task'
}

export interface IUserNotificationSettingFormSetting {
  email?: boolean;
  appPush?: boolean;
}

export interface IUserNotificationSettingForm {
  referenceId: number;
  type: TypeReferenceNotificationSetting;
  setting: IUserNotificationSettingFormSetting;
}

export type IUserNotificationSetting = IUserNotificationSettingForm;
