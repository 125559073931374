import { IBanner } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
import { omit } from 'lodash';
import { parseParamUrl } from 'root/helpers';

export interface IBannerHttpService extends ICRUDHttpService<IBanner> {
  updatePositionBanners(form: any): Promise<any>;
};

export function BannerHttpService(options: IHttpServiceClientOptions): IBannerHttpService {

  const basePath = '/admin/banners';
  const { httpService, mock } = options;
  const baseUrl = httpService.getUrl(basePath, mock);

  function updatePositionBanners(form: any): Promise<any> {

    return httpService.post(`${parseParamUrl(baseUrl, null)}/update_positions`, form);
  }


  return {...CRUDHttpService({
    ...options,
    basePath,
    serializeUpdate
  }),
    updatePositionBanners
  };
}

function serializeUpdate(model: any): any {
  return omit(model, ['id', 'position']);
}
