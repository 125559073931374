import { Form } from 'element-ui';
import { ruleRequired, ruleRequiredNumber } from 'root/helpers';
import { WalletPackage, WalletPackageForm, WalletPackageFormDefault, walletStatusCode } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: require('./view.html'),
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean,
  },
  watch: {
    data() {
      this.dataChange();
    }
  },
})
export class PackagesForm extends Vue {
  public $refs: {
    form: Form;
  };
  public form: WalletPackageForm = WalletPackageFormDefault();
  public data: WalletPackage;
  public isCreate: boolean;
  public optionsStatus = [ 
    {
      type: 'Active',
      value: walletStatusCode.Active,
    },
    {
      type: 'Deleted',
      value: walletStatusCode.Deleted,
    }];

  public get rules() {
    return {
      status:[
        ruleRequired('Please input type')
      ],
      amount:[
        ruleRequiredNumber('Please input amount')
      ],
      packageType: [
        ruleRequired('Please input type')
      ],
      chargeAmount: [
        ruleRequiredNumber('Please input amount')
      ]
    };
  }

  public handleSubmit() {
    this.$refs.form.validate(valid => {
      if (!valid) {
        return;
      }      
      this.$emit('submit', this.form);
    });
  }

  public mounted() {
    this.dataChange();
  }
  public dataChange() {
    if (!this.data) {
      return;
    }
    Object.assign(this.form, this.data);
  }
}
