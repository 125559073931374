import { formatTimeString } from "root/helpers";
import { IState } from "root/store";
import Vue from "vue";
import Component from "vue-class-component";
import { mapState } from "vuex";
import { FilterForm } from "../../Component/FilterForm";
import { ActionTypeWallet } from "../../Store/types";
import { IWallet, WalletList } from "root/models";
import { NoteForm } from "../../Component/NoteForm";
import "./view.scss";

@Component({
  template: require("./view.html"),
  computed: {
    ...mapState({
      data: (state: IState) => state.wallet.walletTransactions,
      loading: (state: IState) => state.wallet.loading,
      pagination: (state: IState) => state.wallet.pagination,
    }),
  },
  components: {
    "search-form": FilterForm,
    "note-form": NoteForm,
  },
  watch: {
    dialogNoteId() {
      this.getDataCurrentNote();
    },
  },
})
export class WalletTransactions extends Vue {
  public $refs: {
    table: any;
  };
  public filterParams = {
    page: 1,
    size: 20,
    sortBy: "",
    sortByField: "",
    name: "",
  };
  public sortOrders: string[] = ["ascending", "descending", null];
  public data: WalletList[];
  public pagination: any;
  public dialogNoteId: string = "";
  public dialogNoteVisible: boolean = false;

  public currentPage = 1;
  public mounted() {
    this.$store.dispatch(ActionTypeWallet.GetPaymentTransactions, this.filterParams);
  }
  public changePagination(event) {
    this.filterParams.page = event;
    this.$store.dispatch(ActionTypeWallet.GetPaymentTransactions, this.filterParams);
  }
  public handleSortChange(obj) {
    this.filterParams.sortBy = obj.order ? obj.order : "";
    this.filterParams.sortByField = obj.prop;

    this.$store.dispatch(ActionTypeWallet.GetPaymentTransactions, this.filterParams);
  }
  public handleSearch(search) {
    this.filterParams.page = 1;
    this.filterParams.name = search;
    this.$store.dispatch(ActionTypeWallet.GetPaymentTransactions, this.filterParams);
  }
  public handleResetFilter() {
    this.$refs.table.clearSort();
    this.filterParams.page = 1;
    this.filterParams.name = "";
    this.filterParams.sortBy = "";
    this.filterParams.sortByField = "";
    this.$store.dispatch(ActionTypeWallet.GetPaymentTransactions, this.filterParams);
  }
  public listSubDes(value) {
    try {
      const data = JSON.parse(value);
      var data1 = "";
      for (var i in data) {
        if (!!data[i]) {
          data1 += "<div class='wallet__transaction__item'>" + i + " :" + parseFloat(data[i]).toFixed(2) + "</div>";
        }
      }
      return data1;
    } catch {
      return "<div class='wallet__transaction__item'>" + value + "</div>";
    }
  }

  public formatTimeString(time) {
    return formatTimeString(time);
  }

  public getDataCurrentNote() {
    const data = this.data.find((item) => item.id === this.dialogNoteId);
    return data;
  }

  public openNoteDialog(id) {
    this.dialogNoteId = id;
    this.dialogNoteVisible = true;
  }

  public handleSubmit(form) {
    this.$store.dispatch(ActionTypeWallet.TransactionNoteUpdate, {
      form: form,
      onSuccess: () => {
        this.$message({
          type: "success",
          message: "Update success",
        });
        this.$store.dispatch(ActionTypeWallet.GetPaymentTransactions, { page: this.pagination.page, size: 20 });
        this.dialogNoteVisible = false;
      },
      onFailure: () => {
        this.$message({
          type: "error",
          message: "Update failed",
        });
      },
    });
  }
}
