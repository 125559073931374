import { ReportCompetition } from './Container';
import { ReportCompetitionList } from './Container/List';

export enum RouterDictionaryReportCompetition {
  ReportCompetition = '/report-competition',
}

export const ReportCompetitionRoutes = {
  path: RouterDictionaryReportCompetition.ReportCompetition,
  component: ReportCompetition,
  children: [
    {
      path: '',
      component: ReportCompetitionList,
      name: 'Report Competition List',
    },
  ]
};
