import { sfrHttpClient } from 'root/api/http';
import { IState, MutationType } from 'root/store/types';
import { ActionContext } from 'vuex';
import { IProfileState } from './mutations';
import { ActionTypeProfile, MutationTypeProfile } from './types';

export const actions = {
  [ActionTypeProfile.UpdateProfile](
    { commit }: ActionContext<IProfileState, IState>,
    form
  ) {
    commit(MutationTypeProfile.LoadingProfile, true);
    sfrHttpClient.profile.updateProfile(form).then((result) => {
      commit(MutationType.SetProfile, result);
      commit(MutationTypeProfile.LoadingProfile, false);
    });
  },
  [ActionTypeProfile.LoadingProfile](
    { commit }: ActionContext<IProfileState, IState>
  ) {
    commit(MutationTypeProfile.LoadingProfile, true);
  }
};
