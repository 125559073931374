import Vue from "vue";
import Component from "vue-class-component";
import { SFRCheckbox } from "root/components/SFRCheckbox";
import { sfrHttpClient } from 'root/api/http';
import { Form, Input } from "element-ui";
import { IApplicantCreateForm, SubGenre, ApplicantCreateFormDefault } from 'root/models';
import { IState } from 'root/store';
import { cloneDeep } from 'lodash';


import { captureVideoThumbnail,
  extractRootDomain,
  ruleEmail,
  getUrlInText,
  parseFileType,
  ruleRequired,
  ruleTrim,
  verifiedImage} from 'root/helpers';
import { FileType, IAttachment, IExternalLink } from 'root/models';
import { SFRAudioPlayer } from "../../SFRAudioPlayer";
import { LinkPreviewCard } from "root/components/LinkPreviewCard";
import { mapState } from 'vuex';

@Component({
  template: require("./view.html"),
  components: {
    "sfr-checkbox": SFRCheckbox,
    "sfr-audio-player": SFRAudioPlayer,
    "link-preview-card": LinkPreviewCard
  },
  props: {
    // buttonType: {
    //   type: String,
    //   default: ButtonCreatePostType.TextField
    // },
    dataProp: Object,
    // visibleDialogProp: Boolean,
    form: Object,
    is_agree: Boolean
  },

  computed:{
    genreData(){
      return Object.keys(this.subGenre)
    },
    subGenreData(){
      return this.subGenre[this.form.applicant.genre] || []
    },
  },

  watch:{
    subGenreData(){
      this.form.applicant.subGenre = this.subGenreData[0] || ''
    }
  }
})
export class Step2 extends Vue {
  public is_agree: boolean;
  public genreData: Object;
  public subGenreData: String[];
  public subGenre: Object = SubGenre();
  public previewLoading: boolean = false;
  public $refs: {
    form: Form;
    inputTagFriend: Input;
    inputTitle: Input;
    audio: HTMLAudioElement;
    linkPrevue: any;
  };
  public form: IApplicantCreateForm;
  public dataProp: IApplicantCreateForm;
  public handleChangeAgree(value) {
    this.$emit('handleIsAgree',value)
  }

  //upload
  public videoDurationMax: number = 3000; // 8
  public maximumTitle: number = 2200;
  public visibleDialog: boolean = false;
  public loading: boolean = false;
  public uploading: boolean = false;
  public cancel: boolean = false;
  public confirmFileType: boolean = false;
  public progressing: number = 0;
  // public maxOfTagFriends: number = 40;
  public visiblePreviewDialog: boolean = false;
  public reviewFile: IAttachment = null;
  // public showTagFriends: boolean = false;
  public fileTypeVideo = FileType.Video;
  public fileTypeImage = FileType.Image;
  public fileTypeAudio = FileType.Audio;
  public audioThumbnail: File = null;
  public urlNeedPreview: string = '';
  public extractRootDomain = extractRootDomain;


  public get rules() {
    return {
      required: error => {
        return ruleRequired(error);
      },
      trim: ruleTrim(),
      email: ruleEmail("email.valid.signup")
    };
  }

  public get currentAttachmentType(): string {
    const attachments = <any>cloneDeep(this.form.attachments);

    if (attachments.length === 0) {
      // text only
      return "";
    }

    return parseFileType(
      attachments[0].type ? attachments[0].type : attachments[0].fileType
    );
  }
  public get disableUploadAttachment(): boolean {
    const attachments = cloneDeep(this.form.attachments);

    return (
      attachments.length === 9 ||
      attachments.length === 0 ||
      (attachments.length === 1 && !!attachments[0].thumbnail)
    );
  }
  public beforeUploadAttachment(files) {
    if (files.length + this.form.attachments.length > 9) {
      this.$message.error("Please upload less than 9 photos!");
    }
  }



  public async setExternalLinkData(form: IExternalLink) {
    const _form = cloneDeep(form),
      {thumbnailUrl} = _form;

    try {
      const verifiedThumbnail = await verifiedImage(thumbnailUrl);

      if (verifiedThumbnail) {
        _form.thumbnailUrl = thumbnailUrl;
      }

      return _form;
    } catch (error) {
      _form.thumbnailUrl = '';

      return _form;
    }
  }

  public handleRemoveAttachment(e: any, index: number) {
    e.stopPropagation();
    const attachments = cloneDeep(this.form.attachments);
    attachments.splice(index, 1);
    this.audioThumbnail = null;
    this.form.attachments = attachments;
  }
  public addAudioThumnail(file: any) {
    const thumbnail = file.raw;
    if (!thumbnail.type || parseFileType(thumbnail.type) !== FileType.Image) {
      const errorText = `${this.$t(
        "error.attachment.format.photo"
      ).toString()}`;
      this.$message.error(errorText);

      return;
    }
    // attachments[0].thumbnail = thumbnail;
    this.audioThumbnail = thumbnail;
    this.$emit('handleAddThumbnail',thumbnail)
  }
  public removeAudioThumbnail() {
    if (this.audioThumbnail) {
      this.audioThumbnail = null;
      return;
    }
    const attachments = cloneDeep(this.form.attachments),
      attachment = attachments[0];
    if (this.currentAttachmentType === FileType.Video) {
      delete attachment.customThumbnailUrl;
    } else {
      delete attachment.thumbnailUrl;
    }
    attachments[0] = attachment;
    this.form.attachments = attachments;
  }
  public async addAttachment(file: any) {
    this.confirmFileType = false;
    if (this.$refs.form) {
      this.$refs.form.clearValidate();
    }
    let attachment = file.raw;
    const type = parseFileType(attachment.type);
    if (
      !attachment.type ||
      (type !== FileType.Video && type !== FileType.Audio)
    ) {
      const errorText = `${this.$t("error.attachment.format").toString()}`;
      this.$message.error(errorText);
      return;
    }
    try {
      if (type === FileType.Video) {
        this.previewLoading = true;
        const thumbnail = await captureVideoThumbnail(attachment, () => {
          // console.log('Math.round', progress);
        });
        // const thumbnail = await get();
        attachment.thumbnail = thumbnail;
        this.form.attachments = [attachment];
        this.previewLoading = false;
      }
      let _attachments = cloneDeep(this.form.attachments);
      console.log(_attachments);
      _attachments = _attachments.filter(att => !att.thumbnail);
      this.previewLoading = false;

      this.form.attachments = [..._attachments, attachment];
    } catch (error) {
      this.previewLoading = false;
      console.log(error);
      this.$message.error("Add attachment fail, try it again!");
    }
  }

  public previewAttachmentUrl(file: any) {
    if (file.thumbnailUrl) {
      return file.thumbnailUrl;
    }
    if (file.thumbnail) {
      return window.URL.createObjectURL(file.thumbnail);
    }

    return window.URL.createObjectURL(file);
  }

  public videoLinkPreview(file: any) {
    if (file.fileUrl) {
      return file.fileUrl;
    }
    return window.URL.createObjectURL(file);
  }

  public handleCancelUpload() {
    this.loading = false;
    this.progressing = 0;
    this.cancel = true;
    this.$refs.form.clearValidate();
  }

  public handlePreview(model: IAttachment) {
    this.visiblePreviewDialog = true;
    this.reviewFile = model;
  }
  public mounted() {
  }

  public get previewThumbnail() {
    if (this.currentAttachmentType === FileType.Audio) {
      return !this.audioThumbnail && !this.form.attachments[0].thumbnailUrl;
    }
    return !this.audioThumbnail && !this.form.attachments[0].customThumbnailUrl;
  }

  public get linkPreviewThumbnail() {
    if (this.currentAttachmentType === FileType.Audio) {
      return this.audioThumbnail
        ? this.previewAttachmentUrl(this.audioThumbnail)
        : this.form.attachments[0].thumbnailUrl;
    }
    return this.audioThumbnail
      ? this.previewAttachmentUrl(this.audioThumbnail)
      : this.form.attachments[0].customThumbnailUrl;
  }
}
