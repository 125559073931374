import { i18n } from 'root/locales';
import { INavigatorItem } from 'root/models';
import {
  RouterDictionaryCompetition,
  RouterDictionaryFeed,
  // RouterDictionaryReportCategory,
  RouterDictionaryNotification,
  RouterDictionaryReportPost,
  RouterDictionaryTemplateEmail,
  RouterDictionaryUser,
  RouterDictionaryCategory,
  RouterDictionaryBanner,
  RouterDictionaryVersion,
  RouterDictionaryWallet,
  RouterDictionaryMaintenance,
  RouterDictionaryBadword,
  RouterDictionaryStickerCategory,
  RouterDictionaryGifCategory,
  RouterDictionaryEvent,
  RouterDictionaryReportCompetition,
} from 'root/pages';
// import { RouteDictionary } from 'root/router';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    currentRoute() {
      return this.$route.path;
    }
  }
})
export class SFRNavigation extends Vue {
  public listDefault: INavigatorItem[] = [];

  // public dashboardSubMenus: INavigatorItem[] = [
  //   {
  //     title: i18n().t('dashboard').toString(),
  //     pathName: RouteDictionary.Dashboard
  //   }
  // ];
  public notificationSubMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryNotification.Notification
    },
    {
      title: i18n().t('create').toString(),
      pathName: RouterDictionaryNotification.NotificationCreate
    }
  ];
  public competitionSubMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryCompetition.Competition
    },
    {
      title: i18n().t('create').toString(),
      pathName: RouterDictionaryCompetition.CompetitionCreate
    },
    {
      title: i18n().t('Report').toString(),
      pathName: RouterDictionaryReportCompetition.ReportCompetition
    }
  ];
  public userSubMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryUser.User
    },
    {
      title: i18n().t('create').toString(),
      pathName: RouterDictionaryUser.UserCreate
    }
  ];

  public bannerSubMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryBanner.Banner
    },
    {
      title: i18n().t('create').toString(),
      pathName: RouterDictionaryBanner.BannerCreate
    }
  ];

  public walletSubMenus: INavigatorItem[] = [
    {
      title: "Packages",
      pathName: RouterDictionaryWallet.WalletPackages
    },
    {
      title: "Create Packages",
      pathName: RouterDictionaryWallet.WalletPackagesCreate
    },
    {
      title: "Transactions",
      pathName: RouterDictionaryWallet.WalletTransactions
    },
    {
      title: "Payments",
      pathName: RouterDictionaryWallet.WalletPayments
    },
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryWallet.WalletList
    }
  ];

  public feedSubMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryFeed.Feed
    },
    // {
    //   title: i18n().t('create').toString(),
    //   pathName: RouterDictionaryFeed.FeedCreate
    // }
  ];
  // public reportCategorySubMenus: INavigatorItem[] = [
  //   {
  //     title: i18n().t('list').toString(),
  //     pathName: RouterDictionaryReportCategory.ReportCategory
  //   },
  //   {
  //     title: i18n().t('create').toString(),
  //     pathName: RouterDictionaryReportCategory.ReportCategoryCreate
  //   }
  // ];
  public reportPostSubMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryReportPost.ReportPost
    },
    // {
    //   title: i18n().t('create').toString(),
    //   pathName: RouterDictionaryReportPost.ReportPostCreate
    // }
  ];
  public TemplateEmailSubMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryTemplateEmail.TemplateEmail
    },
    // {
    //   title: i18n().t('create').toString(),
    //   pathName: RouterDictionaryReportPost.ReportPostCreate
    // }
  ];
  public MaintenanceSubMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryMaintenance.Maintenance
    }
  ];
  public categorySubMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryCategory.Category
    },
    {
      title: i18n().t('create').toString(),
      pathName: RouterDictionaryCategory.CategoryCreate
    }
  ];

  public VersionSubMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryVersion.Version
    }
  ];

  public BadwordSubMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryBadword.Badword
    },
    // {
    //   title: i18n().t('create').toString(),
    //   pathName: RouterDictionaryBadword.BadwordCreate
    // }
  ];

  public StickerCategoryMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryStickerCategory.StickerCategoriesList
    },
    {
      title: i18n().t('create').toString(),
      pathName: RouterDictionaryStickerCategory.StickerCategoriesCreate
    }
  ];

  public GifCategoryMenus: INavigatorItem[] = [
    {
      title: i18n().t('list').toString(),
      pathName: RouterDictionaryGifCategory.GifCategory
    },
    {
      title: i18n().t('create').toString(),
      pathName: RouterDictionaryGifCategory.GifCategoryCreate
    }
  ]

  public mounted() {
    this.$nextTick(() => {
      this.listDefault = [
        // {
        //   title: this.$t('dashboard').toString(),
        //   key: 'dashboard',
        //   subMenus: this.dashboardSubMenus
        // },
        {
          title: this.$t('notification').toString(),
          key: 'notification',
          subMenus: this.notificationSubMenus
        },
        {
          title: 'Homepage Banner',
          key: 'banner',
          icon: 'sidebar-banners',
          subMenus: this.bannerSubMenus
        },
        {
          title: 'Homepage Categories',
          key: 'category',
          icon: '',
          subMenus: this.categorySubMenus
        },
        {
          title: this.$t('user').toString(),
          key: 'user',
          icon: 'sidebar-users',
          subMenus: this.userSubMenus
        },
        {
          title: this.$t('feed').toString(),
          key: 'feed',
          icon: 'sidebar-users',
          subMenus: this.feedSubMenus
        },
        // {
        //   title: this.$t('competition').toString(),
        //   key: 'staff_management',
        //   icon: 'sidebar-users',
        //   subMenus: this.competitionSubMenus
        // },
        // {
        //   title: this.$t('reportCategory').toString(),
        //   key: 'reportCategory',
        //   icon: '',
        //   subMenus: this.reportCategorySubMenus
        // },
        {
          title: 'Sticker Category',
          key: '',
          icon: '',
          subMenus: this.StickerCategoryMenus,
        },
        {
          title: 'Gif Category',
          key: '',
          icon: '',
          subMenus: this.GifCategoryMenus,
        },
        {
          title: this.$t('reportPost').toString(),
          key: 'reportPost',
          icon: '',
          subMenus: this.reportPostSubMenus
        },
        {
          title: 'Template Email',
          key: 'templateEmail',
          icon: '',
          subMenus: this.TemplateEmailSubMenus
        },
        {
          title: 'Maintenance',
          key: 'maintenance',
          icon: '',
          subMenus: this.MaintenanceSubMenus
        },
        {
          title: 'Version',
          key: 'version',
          icon: '',
          subMenus: this.VersionSubMenus
        },
        {
          title: 'Profanity',
          key: 'profanity',
          icon: '',
          subMenus: this.BadwordSubMenus
        },
        // {
        //   title: 'Wallet',
        //   key: '',
        //   icon: '',
        //   subMenus: this.walletSubMenus
        // }
      ];
    });
  }

}
