import { IBanner } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { BANNER_MODULE_NAME } from './types';

export type IBannerState = ICRUDState<IBanner>;

export const defaultState: IBannerState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(BANNER_MODULE_NAME),
};
