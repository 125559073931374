export interface IGifCategory extends IGifCategoryCreateForm {
    id: string;
}

export interface IGifCategoryCreateForm {
    name: string;
    urls: string[];
    avatarUrl: string,
    createdByName: string,
}

export const GifCategoryCreateFormDefault = (): IGifCategoryCreateForm => {
    return {
        name: '',
        urls: [],
        avatarUrl: '',
        createdByName: '',
    };
  };
  