import Vue from "vue";
import { Form } from "element-ui";
import Component from "vue-class-component";
import { SFRCheckbox } from "root/components/SFRCheckbox";
import { cloneDeep } from "lodash";
import { IState } from "root/store";
import { mapState } from "vuex";
import {
  ruleEmail,
  parseFileType,
  resetOrientation,
  ruleRequired,
  ruleMaxLength,
  checkSizeImageBeforeUpdate,
} from "root/helpers";
import { ICompetitionCreateForm, FileType, IAttachment } from "root/models";
import "./styles.scss";

@Component({
  template: require("./view.html"),
  components: {
    "sfr-checkbox": SFRCheckbox,
  },
  props: {
    form: Object,
  },
  computed: {
    ...mapState({
      countryOptions: (state: IState) => {
        const countries = cloneDeep(state.global.globalConfig.countries);
        const countriesAll = [{phoneCode: "", countryName: "Global"}, ...countries]
        
        if (countries.length < 1) {
          return [];
        }

        return countriesAll.map((country: any) => {
          return {
            value: country.countryName,
            label: country.countryName,
          };
        });
      },
    }),
  },
})
export class Step1 extends Vue {
  public previewLoading: boolean = false;
  public confirmFileType: boolean = false;
  public uploading: boolean = false;
  public progressing: number = 0;
  public loading: boolean = false;
  public cancel: boolean = false;
  public audioThumbnail: File = null;
  public visiblePreviewDialog: boolean = false;
  public visiblePreviewDialogLogo: boolean = false;

  public reviewFile: IAttachment = null;
  public $refs: {
    form: Form;
  };
  public form: ICompetitionCreateForm;

  public handlefqaChecked(value) {
    this.form.isFaq = value;
    if (!this.form.isFaq){
      this.form.faq = '';
    }
  }

  public handleinfoChecked(value) {
    this.form.isOtherInfo = value;
    if (!this.form.isOtherInfo){
      this.form.otherInfo = '';
    }
  }
  public handleCancelUpload() {
    this.loading = false;
    this.progressing = 0;
    this.cancel = true;
    this.$refs.form.clearValidate();
  }
  public get rules() {
    return {
      required: (error) => {
        return ruleRequired(error);
      },
      email: ruleEmail("email.valid.signup"),
      sortName: ruleMaxLength(15),
    };
  }

  public handleOldChecked() {
    this.form.allAges = !this.form.allAges;
    if (this.form.allAges === true) {
      this.form.fromAge = null;
      this.form.toAge = null;
    }
  }

  public handleChangeAge(value) {
    if (value !== null) {
      this.form.allAges = false;
    }
  }

  public handleRemoveAttachment() {
    this.form.image = "";
    this.visiblePreviewDialog = false;
  }

  public handleRemoveLogo() {
    this.form.logo = "";
    this.visiblePreviewDialogLogo = false;
  }

  public handlePreview(model: any) {
    if (model) {
      this.visiblePreviewDialog = true;
      this.reviewFile = model;
    }
  }

  public handlePreviewLogo(model: any) {
    if (model) {
      this.visiblePreviewDialogLogo = true;
      this.reviewFile = model;
    }
  }

  public previewAttachmentUrl(file: any) {
    if (typeof file == "string") {
      return file;
    }
    if (file instanceof Blob) {
      return window.URL.createObjectURL(file);
    }
  }
  public async addAttachment(file: any) {
    const maxHeight = 400;
    const maxWidth = 800;
    this.confirmFileType = false;
    if (this.$refs.form) {
      this.$refs.form.clearValidate();
    }
    let attachment = file.raw;
    const type = parseFileType(attachment.type);
    if (!attachment.type || type !== FileType.Image) {
      const errorText = `${this.$t("error.attachment.format").toString()}`;
      this.$message.error(errorText);

      return;
    }

    try {
      if (type === FileType.Image) {
        attachment = await resetOrientation(attachment, -1);
      }
      checkSizeImageBeforeUpdate(attachment, { maxHeight, maxWidth })
        .then(() => {
          this.previewLoading = false;
          this.form.image = attachment;
        })
        .catch(() => {
          this.$message.error(`The ratio of this image must be ${maxWidth}x${maxHeight}`);
          this.form.image = "";
          this.visiblePreviewDialog = false;
        });
    } catch (error) {
      this.previewLoading = false;
      this.$message.error("Add attachment fail, try it again!");
    }
  }

  public async addAttachmentLogo(file: any) {
    const maxHeight = 500;
    const maxWidth = 500;
    this.confirmFileType = false;
    if (this.$refs.form) {
      this.$refs.form.clearValidate();
    }
    let attachment = file.raw;
    const type = parseFileType(attachment.type);
    if (!attachment.type || type !== FileType.Image) {
      const errorText = `${this.$t("error.attachment.format").toString()}`;
      this.$message.error(errorText);

      return;
    }

    try {
      if (type === FileType.Image) {
        attachment = await resetOrientation(attachment, -1);
      }
      checkSizeImageBeforeUpdate(attachment, { maxHeight, maxWidth })
        .then(() => {
          this.previewLoading = false;
          this.form.logo = attachment;
        })
        .catch(() => {
          this.$message.error(`The ratio of this image must be ${maxWidth}x${maxHeight}`);
          this.form.logo = "";
          this.visiblePreviewDialog = false;
        });
    } catch (error) {
      this.previewLoading = false;
      this.$message.error("Add attachment fail, try it again!");
    }
  }
  public get currentAttachmentType(): string {
    const attachments = this.form.image;
    if (attachments) {
      return "";
    }
    return parseFileType(attachments.type ? attachments.type : attachments.fileType);
  }
}
