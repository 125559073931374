import { Event } from './Container';
import { EventCreate } from './Container/Create';
import { EventList } from './Container/List';

export enum RouterDictionaryEvent {
  Event = '/event',
  EventCreate = '/event/create',
  EventUpdate = '/event/:eventId'
}

export const EventRoutes = {
  path: RouterDictionaryEvent.Event,
  component: Event,
  name: "Event List",
  children: [
    {
      path: '',
      component: EventList,
      name: "Event List",
    },
    {
      path: RouterDictionaryEvent.EventCreate,
      component: EventCreate,
      name: "Event Create",
    },
    {
      path: RouterDictionaryEvent.EventUpdate,
      component: EventCreate,
      name: "Event Update",

    }
  ]
};
