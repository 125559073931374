import { displayDuration } from 'root/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    src: String
  },
  watch: {
    src() {
      this.progressing = 0;
      this.currentTime = '00:00';
      this.duration = '00:00';
      this.playing = false;
    }
  }
})

export class SFRAudioPlayer extends Vue {
  public $refs: {
    audio: HTMLAudioElement
  };
  public progressing: number = 0;
  public currentTime: string = '00:00';
  public duration: string = '00:00';
  public playing: boolean = false;

  public clickPlay() {
    if (this.playing) {
      this.$refs.audio.pause();
    } else {
      this.$refs.audio.play();
    }
  }

  public mounted() {
    this.$nextTick(() => {
      const { audio } = this.$refs;

      audio.addEventListener('loadeddata', () => {
        this.duration = displayDuration(audio.duration);
      });
      audio.addEventListener('play', () => {
        this.playing = true;
      });
      audio.addEventListener('pause', () => {
        this.playing = false;
      });
      audio.addEventListener('ended', () => {
        this.playing = false;
      });

      audio.addEventListener('timeupdate', () => {
        const percentage = Math.floor((100 / audio.duration) * audio.currentTime);
        // Update the progress bar's value
        this.currentTime = displayDuration(audio.currentTime);
        this.progressing = percentage;
      });
    });
  }
}
