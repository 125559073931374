import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryGifCategory } from '../../';
import { GifCategoryActionType } from '../../Store/types';
import { GifForm } from '../../Component/GifForm';
@Component({
  template: require('./view.html'),
  components: {
    GifForm
  },
})
export class GifCategoryCreate extends Vue {
  
}
