import { ISticker } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IStickerHttpService extends ICRUDHttpService<ISticker> {
  
}
export function StickerHttpService(options: IHttpServiceClientOptions): IStickerHttpService {
  const basePath = '/admin/stickers';
  return {...CRUDHttpService({
    ...options,
    basePath,
  }),
  };
}