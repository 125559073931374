import { Form } from 'element-ui';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { UserForm } from '../../Component/UserForm';
import { UserActionType, UserGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.user.detailLoading
    })
  },
  components: {
    'user-form': UserForm
  }
})
export class UserDetail extends Vue {
  public $refs: {
    form: Form
  };

  public get userId(): string {
    return this.$route.params.userId;
  }

  public get detail() {
    return this.$store.getters[UserGetterType.User](this.userId);
  }

  public mounted() {
    console.log(this.userId);
    this.$nextTick(() => {
      this.$store.dispatch(UserActionType.UserGetById, {
        id: this.userId
      });
    });
  }
}
