import { IReportPost } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { REPORTCOMPETITION_MODULE_NAME } from './types';

export type IReportCompetitionState = ICRUDState<IReportPost>;

export const defaultState: IReportCompetitionState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(REPORTCOMPETITION_MODULE_NAME),
};
