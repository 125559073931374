import { IHttpServiceClientOptions } from '../Core';

export interface IGlobalHttpService {
  getConfiguration(): Promise<any>;
  getLinkContent(url: string): Promise<any>;
}

export function GlobalHttpService(options: IHttpServiceClientOptions): IGlobalHttpService {

  const url = options.httpService.getUrl('/globals/settings', options.mock);
  // const url = options.httpService.getUrl('/global/configuration', true);

  function getConfiguration(): Promise<any> {
    return options.httpService.get(url);
  }
  function getLinkContent(_url: string): Promise<any> {
    const getContentUrl = options.httpService.getUrl(`/globals/urlcontent?url=${_url}`, options.mock);

    return options.httpService.get(getContentUrl);
  }

  return {
    getConfiguration,
    getLinkContent
  };
}
