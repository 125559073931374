import { camelCase, startCase } from 'lodash';
import { PaginationState } from 'root/store';
import { computedPagination } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryBadword } from '../..';
import { BadwordActionType, BADWORD_MODULE_NAME } from '../../Store/types';
import './style.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, BADWORD_MODULE_NAME)
  }
})

export class BadwordList extends Vue {

  public handleEditBtn(row) {
    this.$router.push({
      path: `${RouterDictionaryBadword.Badword}/update/${row.id}`
    });
  }

  public convertText(text: string) {
    return startCase(camelCase(text));
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(BadwordActionType.BadwordFilter, {
        params: {
          pagination: PaginationState()
        }
      });
    });
  }

  public confirmDelete(row) {
    this.$confirm('This will permanently delete this list bad word. Continue?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.handleDelete(row.id);
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
  }

  public handleDelete(id) {
    this.$store.dispatch(BadwordActionType.BadwordDelete, {
      id: id,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }
}
