import { Form } from 'element-ui';
import { ArtworkCreateDefaultForm, IArtworkCreateForm } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: require('./view.html'),
})
export class ArtworkCreate extends Vue {
  public $refs: {
    form: Form
  };
  public form: IArtworkCreateForm = ArtworkCreateDefaultForm();

}
