import { Form } from 'element-ui';
import { flattenDeep, isEmpty, compact } from 'lodash';
import { SvgIcon } from 'root/components/SvgIcon';
import { formatTimeDuration } from 'root/helpers/formatter';
import { FeedCreateDefaultForm, IFeed, IFeedCreateForm, ICategory } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryFeed } from '../..';
import { RouterDictionaryUser } from '../../../User';
import './view.scss';
import Multiselect from 'vue-multiselect';
import { serializeCategory } from 'root/helpers';
import { CategoryActionType } from 'root/pages/Category/Store/types';
import { mapState } from 'vuex';
import { IState } from 'root/store';
import { sfrHttpClient } from 'root/api/http';

@Component({
  template: require('./view.html'),
  components: {
    Multiselect,
    'svg-icon': SvgIcon
  },
  computed: {
    ...mapState({
      categories: (state: IState) => state.category.data
    })
  },
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean,
    referenceId: String,
  },
  watch: {
    data() {
      this.dataChange();
    },
    categories() {
      if(this.categories) {
        this.optionsCategories = compact(this.categories.map(serializeCategory));
      }
    }
  }
})
export class FeedForm extends Vue {
  public $refs: {
    form: Form
  };

  public categories: ICategory[];

  public optionsCategories: any = [];
  public valueCategories = [];
  public isLoadingCategories: boolean = false;
  public form: IFeedCreateForm = FeedCreateDefaultForm();
  public data: IFeed;
  public referenceId: string;

  public getUserLink(id) {
    return `${RouterDictionaryUser.User}/update/${id}`;
  }

  public getPostLink(id) {
    return `${RouterDictionaryFeed.Feed}/update/${id}`;
  }

  public getUserWebLink(id) {
    return `${process.env.WEBSITE}/feed/${id}`;
  }

  public formatTimeDuration(time) {
    return formatTimeDuration(time);
  }

  public handleSubmit() {
    this.updateCategory();
    this.$emit('submit', this.form);
  }

  public handleDelete() {
    this.$confirm('This will permanently delete the post. Continue?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.$emit('delete');
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
  }

  public handleDeleteComment(row) {
    this.$emit('handleDeleteComment', row.id);
  }

  public mounted() {
    this.dataChange();
  }

  private dataChange() {
    if (!this.data) {
      return;
    }

    this.$store.dispatch(CategoryActionType.CategoryFilter);

    const comments = flattenDeep(this.data.comments.map((comment) => {
      if (!isEmpty(comment.childComments)) {
        return [].concat(comment, comment.childComments);
      }

      return comment;
    }));

    setTimeout(async () => {
      const feedId = this.referenceId || this.$route.params.feedId;
      await sfrHttpClient.feed.getCategoriesByFeed(feedId).then((res) => {
        this.valueCategories = compact(res.map(serializeCategory));
      }).catch( (error) => {
        this.$message({
          type: 'error',
          message: error.message
        });
      });
    },0);

    this.form = { ...this.data, comments };
  }

  public updateCategory() {
    const categoryIds: string[] = this.valueCategories.map(item => item.id);
    const feedId = this.referenceId || this.$route.params.feedId;
    setTimeout(async () => {
      await sfrHttpClient.feed.updateFeedToCategory(feedId, categoryIds).then((res) => {
        console.log('Update category success!')
      }).catch( (error) => {
        this.$message({
          type: 'error',
          message: error.message
        });
      });
    },0);
  }

  public showDownloadable() {
    return this.form.attachments && this.form.attachments.length > 0 && this.form.attachments[0].fileType === 'audio';
  }
}
