import { PaginationState } from 'root/store';
import { getSelectArrayOfArray } from 'root/utils';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ReportPostActionType } from '../../Store/types';
@Component({
  template: require('./view.html'),
})

export class ReportSearchForm extends Vue {

  public sizes: number[] = getSelectArrayOfArray([20, 50, 100, 200]);

  public size: number = 20;

  public handleSizeChange() {
    this.$store.dispatch(ReportPostActionType.ReportPostPaginationChange, {
      pagination: PaginationState({
        size: this.size
      })
    });
  }

  public handleResetBtn() {
    this.$store.dispatch(ReportPostActionType.ReportPostFilter, {
      params: {
        pagination: PaginationState({
          size: this.size
        })
      }
    });
  }
}
