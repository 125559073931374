import { IGifCategory } from './GifCategory';
export interface IGif extends IGifCreateForm {
    id: string;
}

export interface IGifCreateForm {
    name: string,
    gifCategoryId: string,
    url: string,
    hint: string,
    category: IGifCategory,
}

export const GifCreateFormDefault = (): IGifCreateForm => {
    return {
        name: '',
        gifCategoryId: '',
        url: '',
        hint: '',
        category: null,
    };
  };
  