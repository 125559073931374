import { INotification } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
export type INotificationHttpService = ICRUDHttpService<INotification>;

export function NotificationHttpService(options: IHttpServiceClientOptions): INotificationHttpService {

  const basePath = '/admin/notifications';

  return CRUDHttpService({
    ...options,
    basePath
  });
}
