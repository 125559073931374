import { omit } from 'lodash';
import { IMaintenance } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
export type IMaintenanceHttpService = ICRUDHttpService<IMaintenance>;

export function MaintenanceHttpService(options: IHttpServiceClientOptions): IMaintenanceHttpService {

  const basePath = '/admin/maintenances';

  return CRUDHttpService({
    ...options,
    basePath,
    serializeUpdate
  });
}

const serializeUpdate = (maintenance: IMaintenance): any => {

  const newMaintenance = omit(maintenance, ['id']);

  return {
    ...newMaintenance
  };
};
