export const GIF_CATEGORY_MODULE_NAME = 'gifCategory';

export enum GifCategoryActionType {
  GifCategoryCreate = 'gifCategoryCreate',
  GifCategoryUpdate = 'gifCategoryUpdate',
  GifCategoryGetById = 'gifCategoryGetById',
  GifCategoryDelete = 'gifCategoryDelete',
  GifCategoryFetchMany = 'gifCategoryFetchMany',
  GifCategoryPaginationChange = 'gifCategoryPaginationChange',
  GifCategoryFilter = 'gifCategoryFilter',
  GifDelete = 'gifDelete',
  GifCreate = 'gifCreate'
}

export enum GifCategoryMutationType {
  GifCategoryLoading = 'gifCategoryLoading',
  GifCategoryLoaded = 'gifCategoryLoaded',
}

export enum GifCategoryGetterType {
  GifCategory = 'gifCategory',
  GifCategories = 'gifCategories',
}
