import {getFirebaseApp } from '../firebase';
import { IAuthService } from './types';

export function FirebaseAuthService(): IAuthService {

  return {
    signInWithEmailAndPassword: ({ email, password }) =>
      <any> getFirebaseApp()
        .auth()
        .signInWithEmailAndPassword(email, password),
    signOut: () => getFirebaseApp().auth().signOut(),
    onAuthStateChanged: (func) => getFirebaseApp().auth().onAuthStateChanged(func),
    getIdToken: () => {
      const user = getFirebaseApp().auth().currentUser;

      return user ? user.getIdToken() : Promise.resolve(null);
    },
    createUserWithEmailAndPassword: (email, password) =>
      getFirebaseApp().auth().createUserWithEmailAndPassword(email, password),
    getCurrentUser: () => {
      const user = getFirebaseApp().auth().currentUser;

      return user ? user : Promise.resolve(null);
    },
    forgotPassword: (email: string): Promise<void> => {
      return getFirebaseApp().auth().sendPasswordResetEmail(email);
    }
  };
}
