import _ from 'lodash';


export function limit40Mention(content: string) {
  if (!process.env.HASHTAG_FEARTURE) {
    return false;
  }
  if (!content) {
    return false;
  }
  var regexpMention = /(^\@)([a-zA-Z\d\_\.]+)(?:$|\s)/gm;
  var countMention: number = 0;
  content.split("\n").forEach(sentence => {
    sentence.split(" ").forEach(word => {
      regexpMention.lastIndex = 0;
      if(word && regexpMention.test(word)){
        countMention += 1;
      }
    })
  })

  if (countMention > 40) return true;
  return false;
}
export function displayDuration(seconds: number): string {
  // const hours = seconds / 3600,
  const minutes = (seconds % 3600) / 60;

  const format = (val: number) => {
    return ('0' + Math.floor(val)).slice(-2);
  };
  seconds %= 60;

  return [minutes, seconds].map(format).join(':');
}