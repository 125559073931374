import { Form } from 'element-ui';
import { IReportCategory, IReportCategoryCreateForm, ReportCategoryCreateDefaultForm } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
  template: require('./view.html'),
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean
  },
  watch: {
    data() {
      this.dataChange();
    }
  }
})
export class ReportCategoryForm extends Vue {
  public $refs: {
    form: Form;
  };

  public form: IReportCategoryCreateForm = ReportCategoryCreateDefaultForm();
  public data: IReportCategory;

  public handleSubmit() {
    this.$refs.form.validate((valid) => {
      if (!valid) {
        return;
      }

      this.$emit('submit', this.form);
    });
  }

  public handleDelete() {
    this.$confirm('This will permanently delete the report category. Continue?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.$emit('delete');
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
  }

  public mounted() {
    this.dataChange();
  }

  public dataChange() {
    if (!this.data) {
      return;
    }

    this.form = { ...this.data };
  }
}
