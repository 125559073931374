import VueRouter from 'vue-router';
import { ActionContext } from 'vuex';

import { sfrHttpClient } from 'root/api/http';
import { parseParamUrl } from 'root/helpers';
import { IErrorException } from 'root/models';
import { MutationTypeProfile } from 'root/pages/Profile/Store/types';
import {
  Logger, SFRAuth
} from 'root/services';
import { i18n } from '../locales';
import { RouteDictionary } from '../router';
import { ActionType, IState, Layout, MutationType, TypeAlert } from './types';

export const actions = {
  // global
  [ActionType.InitializeSettings](
    { commit }: ActionContext<IState, IState>
  ) {
    const locale = 'en';
    i18n().locale = locale;
    commit(MutationType.SetCurrentLocale, locale);
  },
  [ActionType.SetLayout](
    { commit }: ActionContext<IState, IState>
  ) {
    commit(MutationType.SetLayout);
  },
  [ActionType.SetGlobalConfig](
    { commit }: ActionContext<IState, IState>,
    globalConfig
  ) {
    commit(MutationType.SetGlobalConfig, globalConfig);
  },
  [ActionType.GoBack](
    _: ActionContext<any, any>,
    router: VueRouter,
  ) {
    router.back();
  },
  [ActionType.GoToRoute](
    _: ActionContext<any, any>,
    { router, route, paramUrl }
  ) {
    const method = typeof route === 'string' ? 'push'
      : (route.method || 'push');
    router[method](parseParamUrl(route, paramUrl));
  },
  [ActionType.CollapseMenu](
    { commit }: ActionContext<any, any>,
    isCollapse: boolean,
  ) {
    commit(MutationType.SetMenuCollapse, isCollapse);
  },
  [ActionType.CatchException](
    { commit }: ActionContext<any, any>,
    ex: IErrorException
  ) {
    console.error(ex);
    const message = ex.code
      ? (i18n().t(ex.code) !== ex.code ? i18n().t(ex.code) : ex.message)
      : ex.message;
    commit(MutationType.OpenTopAlert, {
      message,
      type: TypeAlert.Error
    });
  },
  [ActionType.CloseTopAlert](
    { commit }: ActionContext<IState, IState>
  ) {
    commit(MutationType.CloseTopAlert);
  },
  // login
  [ActionType.ChangeStateAuth](
    { commit, dispatch, state }: ActionContext<IState, IState>,
    { router, user }
  ) {

    // if (user) {
    //   commit(MutationType.Authenticated, user);
    //   commit(MutationType.SetLayout, Layout.Home);
    // } else {
    //   commit(MutationType.ClosePlashScreen);
    //   commit(MutationType.SetLayout, Layout.Login);
    //   commit(MutationType.ClosePlashScreen);
    //   router.replace(RouteDictionary.Login);
    // }
    if (user) {
      // get profile 
      sfrHttpClient.profile.getProfile()
        .then((profile) => {
      commit(MutationType.ClosePlashScreen);
          // can not get profile, go back to login page
      if (!profile) {
        Logger.warn('Can not get profile. This user doesn\'t exist in SFR System');

        commit(MutationType.SetLayout, Layout.Login);
        router.replace(RouteDictionary.Login);

        return SFRAuth.signOut();
      }
      // localStorage.setItem(KeyUserIDForOneSignal, profile.id);
      commit(MutationType.Authenticated, profile);
      commit(MutationType.SetLayout, Layout.Home);

      // sendTagForNotification({ user: profile });

      if (state.route.path === RouteDictionary.Login) {
        state.global.lastRoutePath && state.global.lastRoutePath !== RouteDictionary.Login ?
              router.replace(state.global.lastRoutePath) : router.replace(RouteDictionary.Dashboard);
      }
        }).catch((error) => {
          // show message
          console.log('error get profile', error);
          // dispatch(ActionType.CatchException, error);
          dispatch(ActionType.Unauthenticated);

          return SFRAuth.signOut();
        });
    } else {
      commit(MutationType.ClosePlashScreen);
      commit(MutationType.SetLayout, Layout.Login);
      commit(MutationType.ClosePlashScreen);
      router.replace(RouteDictionary.Login);
    }
  },
  [ActionType.Unauthenticated](
    { commit, dispatch }: ActionContext<IState, IState>
  ) {
    commit(MutationType.OpenPlashScreen);
    SFRAuth.signOut().then(() => {
        commit(MutationType.Unauthenticated);
        commit(MutationType.ClearStore);
        commit(MutationType.SetLayout, Layout.Login);
        commit(MutationType.ClosePlashScreen);
    }).catch((error) => {
      dispatch(ActionType.CatchException, error);
    });
  },
  async [ActionType.ReloadProfile](
    { commit, dispatch }: ActionContext<IState, IState>
  ) {
    commit(MutationTypeProfile.LoadingProfile, true);
    try {
      const profile = await sfrHttpClient.profile.getProfile();
      commit(MutationType.Authenticated, profile);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeProfile.LoadingProfile, false);
  },
  // user-notification-setting
  async [ActionType.UserNotificationSettingUpdate](
    { dispatch }: ActionContext<IState, IState>,
    { form, onSuccess }
  ) {
    try {
      const data = await sfrHttpClient.profile.updateUserNotificationSetting(form);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
