import { Form } from 'element-ui';
import { walletStatusCode, TransactionNoteFormDefault, TransactionNoteForm, WalletPackage} from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ruleRequired } from 'root/helpers';

@Component({
  template: require('./view.html'),
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean,
  },
  watch: {
    data() {
      this.dataChange();
    }
  },
})
export class NoteForm extends Vue {
  public $refs: {
    form: Form;
  };
  public form: TransactionNoteForm = TransactionNoteFormDefault();
  public data: WalletPackage;
  public isCreate: boolean;
  public optionsStatus = [ 
    {
      type: 'Active',
      value: walletStatusCode.Active,
    },
    {
      type: 'Deleted',
      value: walletStatusCode.Deleted,
    }];

  public get rules() {
    return {
      txnNote: [
        ruleRequired('Please input type')
      ],
    };
  }

  public handleSubmit() {
    this.$refs.form.validate(valid => {
      if (!valid) {
        return;
      }     
      this.$emit('submit', this.form);
    });
  }

  public mounted() {
    this.dataChange();
    // this.$nextTick(() => {
    //   this.$refs.form.clearValidate(); 
    // });
  }
  public dataChange() {
    if (!this.data) {
      return;
    }
    Object.assign(this.form, {...this.data, ...(!this.data.txnNote && {txnNote: ''})});
  }
}
