export const NOTIFICATION_MODULE_NAME = 'notification';

export enum NotificationActionType {
  NotificationCreate = 'notificationCreate',
  NotificationUpdate = 'notificationUpdate',
  NotificationFilter = 'notificationFilter',
  NotificationGetById = 'notificationGetById',
  NotificationDelete = 'notificationDelete',
}

export enum NotificationMutationType {
  NotificationLoading = 'notificationLoading',
  NotificationLoaded = 'notificationLoaded',
  NotificationRefreshModels = 'notificationRefreshModels',
  NotificationUpdateModels = 'notificationUpdateModels',
  NotificationFilterModels = 'notificationFilterModels',
}

export enum NotificationGetterType {
  Notification = 'notification',
  Notifications = 'notifications',
  NotificationFilterResults = 'notificationFilterResults'
}
