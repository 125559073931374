import Vue from 'vue';
import Component from 'vue-class-component';
import { PaginationState } from 'root/store';
import { ICategory } from "root/models";
import { CategoryActionType, CATEGORY_MODULE_NAME } from '../../Store/types';
import { RouterDictionaryCategory } from "../..";
import { computedPagination } from 'root/store/helpers';
import draggable from 'vuedraggable';
import './view.scss';
import { startCase, camelCase } from 'lodash';

@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, CATEGORY_MODULE_NAME)
  },
  components: {
    draggable
  },
  watch: {
    data() {
      if(this.data){
        this.categories = this.data;
      }
    }
  }
})

export class CategoryList extends Vue {
  public data: ICategory[];
  public categories: ICategory[] = [];
  public loading: boolean;
  public loadingDragging: boolean = false;
  public colsName: string[] = ['title', 'type', 'countItems', 'position', 'orderBy', 'status', 'action'];

  public handleEditBtn(row) {
    this.$router.push({
      path: `${RouterDictionaryCategory.Category}/update/${row.id}`
    });
  }

  public onChangeDrag(e){
    this.loadingDragging = true;
    const form = this.categories.map((category, index) => ({id: category.id, new_position: index + 1}) )
    this.$store.dispatch(CategoryActionType.UpdatePositionCategories, form).then((res) => {
      this.getListCategories()
      this.loadingDragging = false;
      this.$message({
        type: 'success',
        message: 'Change position successful!'
      });
    }).catch((error) => {
      this.loadingDragging = false;
      this.$message({
        type: 'error',
        message: `${error.message}`
      });
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.getListCategories()
    });
  }

  public getListCategories() {
    this.$store.dispatch(CategoryActionType.CategoryFilter, {
      params: {
        pagination: PaginationState()
      }
    });
  }

  public convertText(text: string) {
    return startCase(camelCase(text))
  }

}
