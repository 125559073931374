import { Artwork } from './Container';
import { ArtworkCreate } from './Container/Create';
import { ArtworkList } from './Container/List';

export enum RouterDictionaryArtwork {
  Artwork = '/artwork',
  ArtworkCreate = '/artwork/create',
  ArtworkUpdate = '/artwork/:artworkId'
}

export const ArtworkRoutes = {
  path: RouterDictionaryArtwork.Artwork,
  component: Artwork,
  children: [
    {
      path: '',
      name: 'Artwork List',
      component: ArtworkList
    },
    {
      path: RouterDictionaryArtwork.ArtworkCreate,
      component: ArtworkCreate,
      name: 'Artwork Create',
    },
    {
      path: RouterDictionaryArtwork.ArtworkUpdate,
      component: ArtworkCreate,
      name: 'Artwork Update',
    }
  ]
};
