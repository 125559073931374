export const STICKER_MODULE_NAME = 'sticker';

export enum StickerActionType {
  StickerCreate = 'stickerCreate',
  StickerUpdate = 'stickerUpdate',
  StickerGetById = 'stickerGetById',
  StickerDelete = 'stickerDelete',
  StickerFetchMany = 'stickerFetchMany',
  StickerPaginationChange = 'stickerPaginationChange',
  StickerFilter = 'stickerFilter'
}

export enum StickerMutationType {
  StickerLoading = 'stickerLoading',
  StickerLoaded = 'stickerLoaded',
}

export enum StickerGetterType {
  Sticker = 'sticker',
  Stickers = 'stickers',
}
