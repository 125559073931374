import { Form } from 'element-ui';
import { formatTimeString, ruleEmail, ruleMaxLength, ruleMinLength, ruleRequired, ruleUsernameCharacter } from 'root/helpers';
import { IUser, IUserCreateForm, IUserForm, Permissions } from 'root/models/User';
import { getPublicLink, uploadImageS3 } from 'root/services/upload';
import { getSelectArrayOfArray, getSelectArrayOfEnum } from 'root/utils';
import Vue from 'vue';
import Component from 'vue-class-component';
import './view.scss';
@Component({
  template: require('./view.html'),
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean
  },
  watch: {
    data() {
      this.dataChange();
    }
  }
})
export class UserForm extends Vue {
  public $refs: {
    form: Form
  };
  public isCreate: boolean

  public form: IUserForm = IUserCreateForm();
  public data: IUser;
  public imageUploading: boolean = false;

  public get rules() {
    return {
      email: [
        ruleRequired('Please input Email'),
        ruleEmail('Email is invalid')
      ],
      username: [
        ruleRequired('Please input Username'),
        ruleMaxLength(40, `Username consists of lowercase a-z, 0-9,
        but no space, for symbols, only allows: “_”, “.” ,
        at most 40 characters.`),
        ruleUsernameCharacter(`Username consists of lowercase a-z, 0-9,
        but no space, for symbols, only allows: “_”, “.” ,
        at most 40 characters.`)
      ],
      password: [
        ruleRequired('Please input Password'),
        ruleMinLength(8)
      ],
      country: [
        ruleRequired('Please select a Country')
      ],
      role: [
        ruleRequired('Please select a Role')
      ]
    };
  }

  public formatTimeString(time: any) {
    return formatTimeString(time);
  }

  public handlePreviewImage(file) {
    this.imageUploading = true;
    uploadImageS3('user/avatar/', file.raw).then(async (result: any) => {
      this.imageUploading = false;
      this.form.avatar = getPublicLink(result.key);
      this.$message({
        type: 'success',
        message: 'Upload successful'
      });
    }).catch((error) => {
      this.imageUploading = false;
      console.log(error);
      this.$message({
        type: 'error',
        message: 'Upload failed'
      });
    });
  }

  public get gender() {
    return getSelectArrayOfArray(this.$store.state.global.globalConfig.gender);
  }

  public get role() {
    return getSelectArrayOfArray(this.$store.state.global.globalConfig.role);
  }

  public get profession() {
    return getSelectArrayOfArray(this.$store.state.global.globalConfig.profession);
  }

  public get countries() {
    const countries = this.$store.state.global.globalConfig.countries;

    return countries.map((country) => {
      return {
        label: country.countryName,
        value: country.countryName
      };
    });

  }

  public get permissions() {
    return getSelectArrayOfEnum(Permissions);
  }

  public handleAvatarClick() {
    //
  }

  public handleSubmit() {
    this.$refs.form.validate((valid) => {
      if (!valid) {
        return;
      }

      this.$emit('submit', this.form);
    });
  }

  public handleDelete() {
    this.$confirm('This will permanently delete the user. Continue?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.$emit('delete');
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
  }

  public mounted() {
    this.dataChange();
  }

  public dataChange() {
    if (!this.data) {
      return;
    }
    if (!this.isCreate) this.data.password = '';
    Object.assign(this.form, this.data);
  }
}
