export interface IMaintenanceCreateForm {
  maintenance: boolean;
  imageUrl: string;
  title: string;
  description: string;
}

export interface IMaintenance extends IMaintenanceCreateForm {
  id: string;
}

export const MaintenanceCreateDefaultForm = (): IMaintenanceCreateForm => {
  return {
    maintenance: false,
    imageUrl: '',
    title: '',
    description: ''
  };
};
