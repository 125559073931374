export const COMPETITION_MODULE_NAME = 'competition';

export enum CompetitionActionType {
  CompetitionCreate = 'competitionCreate',
  CompetitionUpdate = 'competitionUpdate',
  CompetitionFilter = 'competitionFilter',
  CompetitionGetById = 'competitionGetById',
  GetCompetitionDetail = 'competitionDetail',
  CompetitionDelete = 'competitionDelete',
  UpdateStatusCompetition = 'updateStatusCompetition',
  DeactiveCompetition = 'deactiveCompetition',
  ToggleActive = 'toggleActive',
  GetApplicantionByCompetition = 'getApplicantionByCompetition',
  GetContestantsByCompetition = 'getContestantsByApplicant',
  ApplicantChangeStatus = 'applicantChangeStatus',
  CreateApplicant = 'createApplicant',
  UpdateApplicant = 'updateApplicant',
  GetApplicantById = 'getApplicantById',
  UpdateCompetition = 'updateCompetition',
  PayCompetition = 'payCompetition'
}

export enum CompetitionMutationType {
  SetSubmited = 'setSubmited',
  SetNotSubmit = 'setNotSubmit',
  SetCompetitionDetail = 'competitionDetail',
  CompetitionLoading = 'competitionLoading',
  CompetitionLoaded = 'competitionLoaded',
  CompetitionRefreshModels = 'competitionRefreshModels',
  CompetitionUpdateModels = 'competitionUpdateModels',
  CompetitionFilterModels = 'competitionFilterModels',
  SetCompetitionApplicants = 'setCompetitionApplicants',
  SetApplicantListLoading = 'setApplicantListLoading',
  SetApplicantListLoaded = 'setApplicantListLoaded',
  SetDetailLoading = 'setDetailLoading',
  SetDetailLoaded = 'setDetailLoaded',
  createApplicant = 'createApplicant',
  ApplicantLoading = 'applicantLoading',
  ApplicantLoaded = 'applicantLoaded',
  SetApplicantDetail = 'setApplicantDetail',
  SetDetailApplicantLoading = "setDetailApplicantLoading",
  SetDetailApplicantLoaded = "setDetailApplicantLoaded",
  UpdateCompetition = 'updateCompetition',
  SetSelectedTimeline = 'setSelectedTimeline',
  UnsetSelectedTimeline = 'unsetSelectedTimeline',
}

export enum CompetitionGetterType {
  Competition = 'competition',
  Competitions = 'competitions',
  CompetitionFilterResults = 'competitionFilterResults',
}
