import { Component } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { IState } from '../types';
import { defaultGetterOptions, ICommonGetterOptions } from './getters';
export function computedPagination(this: Component, name: string, options?: ICommonGetterOptions) {
  options = defaultGetterOptions(name, options);

  return {
    ...mapState({
      loading: (state: IState) => state[name].loading,
    }),
    ...mapGetters({
      data: `${name}FilterResults`
    }),
    currentPage: {
      get() {
        return this.$store.state[name].pagination.page;
      },
      set(val) {
        this.$store.dispatch(`${name}PaginationChange`, {
          pagination: {
            ...this.$store.state[name].pagination,
            page: val,
          }
        });
      }
    },
    pagination: {
      get() {
        return this.$store.state[name].pagination;
      }, set(val) {
        this.$store.dispatch(`${name}PaginationChange`, val);
      }
    },
    pageSize: {
      get() {
        return this.$store.state[name].pagination.size;
      },
      set(val) {
        this.store.dispatch(`${name}PaginationChange`, {
          ...this.$store.state[name].pagination,
          size: val,
        });
      }
    },
  };
}
