import { extractRootDomain } from 'root/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    data: Object,
    fullWidth: Boolean
  }
})

export class LinkPreviewCard extends Vue {
  public extractRootDomain = extractRootDomain;
}
