import { IVersion } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
import { omit } from 'lodash';

export type IVersionHttpService = ICRUDHttpService<IVersion>;

export function VersionHttpService(options: IHttpServiceClientOptions): IVersionHttpService {

  const basePath = '/admin/versions';

  return {...CRUDHttpService({
      ...options,
      basePath,
      serializeUpdate
    })
  };
}

function serializeUpdate(model: any): any {
  return omit(model, ['id']);
}
