import Vue from 'vue';
import Component from 'vue-class-component';
import { StickerForm } from '../Component/StickerForm';
import { StickerCategoriesActionType } from '../../Store/types';
import { RouterDictionaryStickerCategory } from '../..';

@Component({
  template: require('./view.html'),
  components: {
    'sticker-form': StickerForm
  }
})
export class StickerCategoriesCreate extends Vue {
  public uploading: boolean = false;

  public handleSubmit(form) {
    const body = {
      name: form.name,
      price: form.price,
      createdByName: form.createdByName,
      avatarUrl: form.avatarUrl
    }
    this.uploading = true;
    this.$store.dispatch(StickerCategoriesActionType.StickerCategoriesCreate, {
      form: body,
      onSuccess: () => {
        this.uploading = false;
        this.$message({
          type: 'success',
          message: 'Create success'
        });
        this.$router.push({
          path: RouterDictionaryStickerCategory.StickerCategoriesList
        });
      },
      onFailure: () => {
        this.$message({
          type: 'error',
          message: 'Create failed'
        });
      }
    });
  }
}
