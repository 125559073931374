// register the plugin
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// import page
import {
  ArtworkRoutes,
  CompetitionRoutes,
  DashboardContainer,
  FeedRoutes,
  LoginContainer,
  NotificationRoutes,
  ProfileRoutes,
  ReportCategoryRoutes,
  ReportPostRoutes,
  ReportCompetitionRoutes,
  TemplateEmailRoutes,
  UserRoutes,
  CategoryRoutes,
  BannerRoutes,
  VersionRoutes,
  WalletRoutes,
  MaintenanceRoutes,
  BadwordRoutes,
  StickerCategoryRoutes,
  GifCategoryRoutes
} from '../pages';

// route dictionary
import { RouteDictionary } from './types';

// export * from "./link";
export * from './types';

// register the plugin
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: RouteDictionary.Dashboard,
      component: DashboardContainer,
    },
    {
      path: RouteDictionary.Login,
      component: LoginContainer
    },
    CompetitionRoutes,
    UserRoutes,
    FeedRoutes,
    ArtworkRoutes,
    ProfileRoutes,
    ReportCategoryRoutes,
    ReportPostRoutes,
    ReportCompetitionRoutes,
    NotificationRoutes,
    TemplateEmailRoutes,
    CategoryRoutes,
    BannerRoutes,
    VersionRoutes,
    WalletRoutes,
    MaintenanceRoutes,
    BadwordRoutes,
    StickerCategoryRoutes,
    GifCategoryRoutes
  ]
});

export default router;
