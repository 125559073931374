import Vue from 'vue';
import Vuex from 'vuex';

import { actions } from './actions';
import { getters } from './getters';
import { globalState, mutations } from './mutations';

// module
import * as banner from 'root/pages/Banner/Store';
import * as category from 'root/pages/Category/Store';
import * as competition from 'root/pages/Competition/Store';
import * as feed from 'root/pages/Feed/Store';
import * as notification from 'root/pages/Notification/Store';
import * as profile from 'root/pages/Profile/Store';
import * as promotion from 'root/pages/Promotion/Store';
import * as reportCategory from 'root/pages/ReportCategory/Store';
import * as reportPost from 'root/pages/ReportPost/Store';
import * as reportCompetition from 'root/pages/ReportCompetition/Store';
import * as templateEmail from 'root/pages/TemplateEmail/Store';
import * as user from 'root/pages/User/Store';
import * as version from 'root/pages/Version/Store';
import * as wallet from 'root/pages/Wallet/Store';
import * as maintenance from 'root/pages/Maintenance/Store';
import * as badword from 'root/pages/Badword/Store';
import * as gif from 'root/pages/Gif/Store';
import * as gifCategory from 'root/pages/gifCategory/Store';
import * as stickerCategories from 'root/pages/StickerCategories/Store';
import * as sticker from 'root/pages/Sticker/Store';

export * from './types';

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  getters,
  mutations,
  state: <any> globalState,
  modules: {
    banner,
    category,
    competition,
    profile,
    promotion,
    user,
    feed,
    reportCategory,
    reportPost,
    reportCompetition,
    notification,
    templateEmail,
    version,
    wallet,
    maintenance,
    badword,
    gif,
    gifCategory,
    sticker,
    stickerCategories
  }
});

export * from './types';
