import { Feed } from './Container';
import { FeedCreate } from './Container/Create';
import { FeedDetail } from './Container/Detail';
import { FeedList } from './Container/List';
import { FeedUpdate } from './Container/Update';

export enum RouterDictionaryFeed {
  Feed = '/feed',
  FeedCreate = '/feed/create',
  FeedDetail = '/feed/:feedId',
  FeedUpdate = '/feed/update/:feedId',
}

export const FeedRoutes = {
  path: RouterDictionaryFeed.Feed,
  component: Feed,
  children: [
    {
      path: '',
      component: FeedList,
      name: 'Feed List',
    },
    {
      path: RouterDictionaryFeed.FeedCreate,
      component: FeedCreate,
      name: 'Feed Create'
    },
    {
      path: RouterDictionaryFeed.FeedDetail,
      component: FeedDetail,
      name: 'Feed Detail'
    },
    {
      path: RouterDictionaryFeed.FeedUpdate,
      component: FeedUpdate,
      name: 'Feed Update'
    }
  ]
};
