import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryNotification } from '../..';
import { NotificationForm } from '../../Component/NotificationForm';
import { NotificationActionType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'notification-form': NotificationForm
  },
  props: {
    loading: Boolean,
  },
})

export class NotificationCreate extends Vue {

  public loading: boolean = false;

  public handleSubmit(form) {
    this.loading = true;
    this.$store.dispatch(NotificationActionType.NotificationCreate, {
      form,
      onSuccess: () => {
        this.loading = false;
        this.$message({
          type: 'success',
          message: 'Create successful'
        });
        this.$router.push({
          path: RouterDictionaryNotification.Notification
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'warning',
          message: `${error.message}`
        });
      }
    });
  }

}
