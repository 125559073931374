export interface IVersionCreateForm {
  platform: string;
  version: string;
}

export interface IVersion extends IVersionCreateForm {
  id: string;
}

export const VersionCreateDefaultForm = (): IVersionCreateForm => {
  return {
    platform: '',
    version: ''
  };
};
