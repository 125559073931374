import { IBanner } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryBanner } from '../..';
import { BannerForm } from '../../Component/BannerForm';
import { BannerActionType, BannerGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'banner-form': BannerForm
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.banner.detailLoading
    })
  }
})

export class BannerUpdate extends Vue {
  public get bannerId(): string {
    return this.$route.params.bannerId;
  }

  public get detail(): IBanner {
    return this.$store
      .getters[BannerGetterType.Banner](this.bannerId);
  }

  public handleSubmit(form) {
    this.$store.dispatch(BannerActionType.BannerUpdate, {
      form,
      id: this.bannerId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Update successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'warning',
          message: `${error.message}`
        });
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        BannerActionType.BannerGetById, {
          id: this.bannerId
        }
      );
    });
  }
}
