import { IProfile } from 'root/models';
import { CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeProfile } from './types';

export interface IProfileState extends ICRUDState<IProfile> {
  loading: false;
  popupProfile: false;
}

export const defaultState: IProfileState = {
  ...CRUDState(),
  loading: false,
  popupProfile: false
};

export const mutations = {
  [MutationTypeProfile.LoadingProfile](state: IProfileState, loading) {
    state.loading = loading;
  }
};
