export const CATEGORY_MODULE_NAME = 'category';

export enum CategoryActionType {
  CategoryCreate = 'categoryCreate',
  CategoryUpdate = 'categoryUpdate',
  CategoryFilter = 'categoryFilter',
  CategoryGetById = 'categoryGetById',
  CategoryDelete = 'categoryDelete',
  UpdatePositionCategories = 'updatePositionCategories',
  GetDetailCategory = 'getDetailCategory'
}

export enum CategoryMutationType {
  CategoryLoading = 'categoryLoading',
  CategoryLoaded = 'categoryLoaded',
  CategoryRefreshModels = 'categoryRefreshModels',
  CategoryUpdateModels = 'categoryUpdateModels',
  CategoryFilterModels = 'categoryFilterModels',
}

export enum CategoryGetterType {
  Category = 'category',
  Categorys = 'categorys',
  CategoryFilterResults = 'categoryFilterResults'
}
