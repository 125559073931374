import { ICategory } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { CATEGORY_MODULE_NAME } from './types';

export type ICategoryState = ICRUDState<ICategory>;

export const defaultState: ICategoryState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(CATEGORY_MODULE_NAME),
};
