import { IStickerCategories } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { StickerForm } from '../Component/StickerForm';
import { StickerCategoriesActionType, StickerCategoriesGetterType } from '../../Store/types';
import { RouterDictionaryStickerCategory } from '../..';

@Component({
  template: require('./view.html'),
  components: {
    'sticker-form': StickerForm
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.stickerCategories.detailLoading
    })
  }
})

export class StickerCategoriesUpdate extends Vue {
  public get stickerID(): string {
    return this.$route.params.stickerID;
  }

  public get detail(): IStickerCategories {
    return this.$store
      .getters[StickerCategoriesGetterType.StickerCategories](this.stickerID);
  }

  public handleSubmit(form) {
    const body = {
      name: form.name,
      price: form.price,
      createdByName: form.createdByName,
      avatarUrl: form.avatarUrl
    }
    this.$store.dispatch(StickerCategoriesActionType.StickerCategoriesUpdate, {
      form: body,
      id: this.stickerID,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Update successful'
        });
        this.$router.push({
          path: RouterDictionaryStickerCategory.StickerCategoriesList
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'warning',
          message: `${error.message}`
        });
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        StickerCategoriesActionType.StickerCategoriesGetById, {
          id: this.stickerID
        }
      );
    });
  }
}

