export const STICKER_CATEGORIES_MODULE_NAME = 'stickerCategories';

export enum StickerCategoriesActionType {
  StickerCategoriesCreate = 'stickerCategoriesCreate',
  StickerCategoriesUpdate = 'stickerCategoriesUpdate',
  StickerCategoriesGetById = 'stickerCategoriesGetById',
  StickerCategoriesDelete = 'stickerCategoriesDelete',
  StickerCategoriesFetchMany = 'stickerCategoriesFetchMany',
  StickerCategoriesPaginationChange = 'stickerCategoriesPaginationChange',
  StickerCategoriesFilter = 'stickerCategoriesFilter',
  StickerDelete = 'stickerDelete',
  StickerCreate = 'stickerCreate',
}

export enum StickerCategoriesMutationType {
  StickerCategoriesLoading = 'stickerCategoriesLoading',
  StickerCategoriesLoaded = 'stickerCategoriesLoaded',
}

export enum StickerCategoriesGetterType {
  StickerCategories = 'stickerCategories',
  StickersCategories = 'stickersCategories',
}
