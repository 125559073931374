import { Form } from 'element-ui';
import { flatten, includes } from 'lodash';
import { ruleRequired, serializeSimplyUser, serializeSimplyFeed, serializeSimplyCompetition } from 'root/helpers';
import { PaginationState } from 'root/store';
import { CategoryCreateDefaultForm, ICategory, ICategoryCreateForm, OrderCategoryType, CategoryType } from 'root/models';
import { isArray, isUndefined } from 'util';
import Vue from 'vue';
import Component from 'vue-class-component';
import Multiselect from 'vue-multiselect'
import { UserActionType } from 'root/pages/User/Store/types';
import { FeedActionType } from 'root/pages/Feed/Store/types';
import { CompetitionActionType } from 'root/pages/Competition/Store/types';
import { CategoryActionType } from '../../Store/types';
import _ from 'lodash';
import './view.scss';

@Component({
  template: require('./view.html'),
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean
  },
  components: {
    multiselect: Multiselect
  },
  watch: {
    data() {
      this.dataChange();
    }
  },
})
export class CategoryForm extends Vue {
  public $refs: {
    form: Form;
  };

  public form: ICategoryCreateForm = CategoryCreateDefaultForm();
  public loading: boolean;
  public data: ICategory;
  public imageUploading: boolean = false;
  ////////////////// ----------- select type
  public optionsType = [
    CategoryType.Post, CategoryType.User, CategoryType.Competition
  ]
  ////////////////// ----------- search orderBy
  public optionsOrderby: string[] = [
    OrderCategoryType.ByFollower, OrderCategoryType.NoOrder, OrderCategoryType.ByAlphabet, OrderCategoryType.Randomize
  ];
  // variable search items
  public value = []
  public isLoadingSearch: boolean = false;
  public items = [];
  public size: number = 20;
  public filterOptions = _.debounce((query) => query && this.asyncFind(query), 300);
  // -------------

  public get rules() {
    return {
      title: [
        ruleRequired('Please input Title')
      ],
      type: [
        ruleRequired('Please update Type Category')
      ],
      orderBy: [
        ruleRequired('Please input Type Order')
      ]
    };
  }

  public async isValidForm() {
    const promises = flatten(Object.values(this.$refs).map((element: any) => {
      if (isArray(element)) {
        return (<any> element).map((form) => {
          return new Promise((resolve, _reject) => {
            form.validate((valid, result) => {
              resolve({ valid, result });
            });
          });
        });
      }

      return new Promise((resolve, _reject) => {
        element.validate((valid, result) => {
          resolve({ valid, result });
        });
      });
    }));

    return Promise.all(promises).then((arrayResolve) => {
      const errors = arrayResolve.map((resolve) => {
        if (isUndefined(resolve) || (<any> resolve).valid) {
          return;
        }
        this.$message({
          type: 'error',
          message: Object.values((<any> resolve).result)[0][0].message
        });

        return false;
      });

      return !includes(errors, false);
    });
  }

  public async handleSubmit() {
    this.form.listIds = this.value.map(item => item.id);
    const valid = await this.isValidForm();
    if (!valid) {
      return;
    };
    this.$emit('submit', this.form);
  }

  public mounted() {
    this.dataChange();
  }

  public dataChange() {
    if (!this.data) {
      return;
    }
    this.isLoadingSearch = true;
    this.$store.dispatch(CategoryActionType.GetDetailCategory, {
      id: this.data.id
    }).then((res) => {
      this.getDefaultOptions(res.data, this.form.type)
      this.isLoadingSearch = false;
    }).catch((error) => {
      this.$message({
        type: 'error',
        message: `${error.message}`
      });
    });

    this.form = { ...this.data };
  }

  public onSelectType (option) {
    this.form.type = option;
    this.asyncFind('')
    this.value = [];
  }

  ////////////////// ----------- search user / competition / post
  public placeholderSelectItem() {
    switch (this.form.type) {
      case 'post':
        return 'Search by id of post'
        break;
      case 'competition':
        return 'Search competition'
        break;
      default:
        return 'Search by username of user'
        break;
    }
  }

  public asyncFind (query) {
    this.isLoadingSearch = true
    const _this = this;
    if ( this.form.type === CategoryType.User ){
      this.$store.dispatch(UserActionType.UserFilter, {
        params : {
          filterParams: {name: query},
          pagination: PaginationState({
            size: this.size
          }),
          onSuccess: (result) => {
            _this.items = result.map(serializeSimplyUser);
            _this.isLoadingSearch = false;
          },
        }
      })
      return;
    }

    if ( this.form.type === CategoryType.Post ) {
      this.$store.dispatch(FeedActionType.FeedFilter, {
        params : {
          filterParams: {name: query},
          pagination: PaginationState({
            size: this.size
          }),
          onSuccess: (result) => {
            _this.items = result.map(serializeSimplyFeed);
            _this.isLoadingSearch = false;
          },
        }
      })
      return;
    }

    if ( this.form.type === CategoryType.Competition ) {
      this.$store.dispatch(CompetitionActionType.CompetitionFilter, {
        params : {
          filterParams: {keywords: query, type: "notCancelled"},
          pagination: PaginationState({
            size: this.size
          }),
          onSuccess: (result) => {
            _this.items = result.map(serializeSimplyCompetition);
            _this.isLoadingSearch = false;
          },
        }
      })
      return;
    }
  }

  public getDefaultOptions(list, type) {
    switch (type) {
      case CategoryType.Post:
        this.items = list.map(serializeSimplyFeed)
        this.value = this.items;
        break;
      case CategoryType.Competition:
        this.items = list.map(serializeSimplyCompetition)
        this.value = this.items;
        break;
      default:
        this.items = list.map(serializeSimplyUser)
        this.value = this.items;
        break;
    }
  }

  public handleDelete() {
    this.$confirm('This will permanently delete this category. Continue?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.$emit('delete');
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
  }
}
