import moment from 'moment';
import { formatTimeDuration } from 'root/helpers/formatter';
import { IReportPost } from 'root/models';
import { CompetitionActionType } from 'root/pages/Competition/Store/types';
import { RouterDictionaryFeed } from 'root/pages/Feed';
import { FeedUpdate } from 'root/pages/Feed/Container/Update';
import { RouterDictionaryUser } from 'root/pages/User';
import { IPaginationState, PaginationState } from 'root/store';
import { computedPagination } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryReportCompetition} from '../..';
import { ReportSearchForm } from '../../Component/SearchForm';
import { ReportCompetitionActionType, ReportCompetitionGetterType, REPORTCOMPETITION_MODULE_NAME } from '../../Store/types';
import './view.scss';
@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, REPORTCOMPETITION_MODULE_NAME)
  },
  components: {
    'report-search-form': ReportSearchForm,
    'feed-update-form': FeedUpdate
  }
})

export class ReportCompetitionList extends Vue {
  public loadingChangeStatus: boolean = false;
  public dialogReportCompetition: boolean = false;
  public dialogReferenceId: string = '';
  public getListCompetitions() {
    this.$store.dispatch(ReportCompetitionActionType.ReportCompetitionFilter);
  }

  public formatDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }
  
  public parseType(status, statusCompetition) {
    return status === 'active' && statusCompetition === 'approved' ? 'success' :
    status === 'deleted' && statusCompetition === 'approved' ? 'danger' : 'success';
  }

  public sort(field, type) {
    this.$store.dispatch(CompetitionActionType.CompetitionFilter, {
      params: {
        pagination: PaginationState(),
        filterParams: {
          sortByField: field, 
          sortBy: type,
        }
      }
    })
  }

  public deactiveCompetition(id) {
    this.loadingChangeStatus = true;
    this.$store.dispatch(CompetitionActionType.DeactiveCompetition, id).then((res) => {
      this.getListCompetitions()
      this.loadingChangeStatus = false;
      this.$message({
        type: 'success',
        message: res.status === 'deleted' ? 'Deactive successful!' : 'Reactive successful!'
      });
    }).catch((error) => {
      this.loadingChangeStatus = false;
      this.$message({
        type: 'error',
        message: `${error.message}`
      });
    });
  }

  public openReportDialog(referenceId) {
    this.dialogReferenceId = referenceId;
    this.$store.dispatch(ReportCompetitionActionType.ReportCompetitionGetById, {
      id: referenceId,
      data: this.dialogReferenceId
    }).then(() => {
      this.dialogReportCompetition = true;
    })    
    console.log(this.dialogReferenceId, "sss");
  }

  public get detail(): IReportPost {
    return this.$store
      .getters[ReportCompetitionGetterType.ReportCompetition](this.dialogReferenceId);
  }
  
  public handleResetList() {
    this.$nextTick(() => {
      this.$store.dispatch(CompetitionActionType.CompetitionFilter, {
        params: {
          pagination: PaginationState({
            page: 1
          })
        }
      });
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ReportCompetitionActionType.ReportCompetitionFilter, {
        params: {
          pagination: PaginationState()
        }
      });
    });
  }
}
