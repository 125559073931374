import { formatDateString, formatTimeString } from 'root/helpers';
import { computedPagination } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryNotification } from '../..';
import { NotificationActionType, NOTIFICATION_MODULE_NAME } from '../../Store/types';

@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, NOTIFICATION_MODULE_NAME)
  }
})

export class NotificationList extends Vue {

  public handleEditBtn(row) {
    this.$router.push({
      path: `${RouterDictionaryNotification.Notification}/update/${row.id}`
    });
  }

  public formatTimeString(time) {
    return formatTimeString(time);
  }

  public formatDateString(time) {
    return formatDateString(time);
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(NotificationActionType.NotificationFilter);
    });
  }
}
