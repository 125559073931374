import { PaginationState } from 'root/store';
import { computedPagination } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryReportCategory } from '../..';
import { ReportCategoryActionType, REPORTCATEGORY_MODULE_NAME } from '../../Store/types';

@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, REPORTCATEGORY_MODULE_NAME)
  }
})

export class ReportCategoryList extends Vue {

  public handleEditBtn(row) {
    this.$router.push({
      path: `${RouterDictionaryReportCategory.ReportCategory}/update/${row.id}`
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ReportCategoryActionType.ReportCategoryFilter, {
        params: {
          pagination: PaginationState()
        }
      });
    });
  }
}
