import Vue from 'vue';
import Component from 'vue-class-component';
import './view.scss';

@Component({
  template: require('./view.html'),
  watch: {
    $route() {
      this.RouteChange();
    }
  }
})
export class Breadcrumb extends Vue {
  public routeList: any = [];
  public RouteChange() {
    
    const pathes = this.$route.matched.map((e) => {
      return {
        path: e.path,
        name: e.name
      };
    });
    this.routeList = pathes;
    this.routeList[this.routeList.length - 1]['noRedirect'] = true;
  }

  public created() {
    this.RouteChange();
  }
}
