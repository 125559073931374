import { IBadword } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryBadword } from '../..';
import { BadwordForm } from '../../Component/BadwordForm';
import { BadwordActionType, BadwordGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'badword-form': BadwordForm
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.notification.detailLoading
    })
  }
})

export class BadwordUpdate extends Vue {

  public get BadwordId(): string {
    return this.$route.params.BadwordId;
  }

  public get detail(): IBadword {
    return this.$store.getters[BadwordGetterType.Badword](this.BadwordId);
  }

  public handleSubmit(form) {
    this.$store.dispatch(BadwordActionType.BadwordUpdate, {
      form,
      id: this.BadwordId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Update successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public handleDelete() {
    this.$store.dispatch(BadwordActionType.BadwordDelete, {
      id: this.BadwordId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
        this.$router.push({
          path: RouterDictionaryBadword.Badword
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        BadwordActionType.BadwordGetById, {
          id: this.BadwordId
        }
      );
    });
  }
}
