import { Gender, StatusCode } from './Common';

export enum Country {
  Singapore = 'Singapore',
  Thailand = 'Thailand',
  Philippines = 'Philippines'
}

export enum PhoneCode {
  Singapore = '+65',
  Thailand = '+66',
  Philippines = '+63'
}

export interface IPhone {
  phoneCode: PhoneCode;
  phoneNumber: string;
}

export const Permissions = {
  Admin: 'admin'
};

export interface IUserForm {
  firstName: string;
  lastName: string;
  gender: Gender;
  username: string;
  displayName: string;
  bio: string;
  birthday: Date;
  country: string;
  role: string;
  profession: string[];
  email: string;
  webpage: string;
  password: string;
  avatar: string;
  permissions: string[];
  isVerified: boolean;
  emailVerified: boolean;

}

export const IUserCreateForm = (): IUserForm => {
  return {
    firstName: '',
    lastName: '',
    gender: undefined,
    username: '',
    displayName: '',
    bio: '',
    birthday: undefined,
    country: '',
    role: undefined,
    profession: undefined,
    email: '',
    webpage: '',
    password: '',
    avatar: '',
    permissions: [],
    isVerified: false,
    emailVerified: false
  };
};

export interface IUser extends IUserForm {
  id: number;
  numPosts: number;
  numFollowers: number;
  numFollowing: number;
  numFeatureFeed: number;
  createdAt: Date;
  provider: string;
}

export interface IUserFilter {
  keyword?: string;
  status?: StatusCode;
}

export interface IUserItem {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface IProfile extends IUser {
  numberOfNewMessage: number;
  numberOfNewNotification: number;
}

export enum FavoriteType {
  Message = 'message',
  Plan = 'plan',
  Project = 'project',
  ProjectTask = 'project-task',
  PlanItem = 'plan-item'
}
export interface IFavoriteForm {
  type: FavoriteType;
  relateId: string;
}

export interface IFavorite extends IFavoriteForm {
  id: string;
  title: string;
  description: string;
  avatarUrl: string;
  metadata?: any;
}
