import { formatTimeDuration } from 'root/helpers/formatter';

export function getSelectArrayOfEnum(e: any): any[] {
  return Object.keys(e).map((key) => {
    return {
      value: e[key],
      label: key
    };
  });
}

export function getSelectArrayOfArray(a: any): any[] {
  return a.map((e) => {
    return {
      value: e,
      label: e
    };
  });
}

export function formatJson(filterVal, jsonData) {
  return jsonData.map((v) => filterVal.map((j) => {
    if (j === 'timestamp') {
      return formatTimeDuration(v[j]);
    }
    if (typeof v[j] === 'boolean') {
      v[j] = v[j] ? 1 : 0;
    }

    return v[j];
  }));
}
