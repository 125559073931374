export const USER_MODULE_NAME = 'user';

export enum UserActionType {
  UserCreate = 'userCreate',
  UserUpdate = 'userUpdate',
  UserFilter = 'userFilter',
  UserGetById = 'userGetById',
  UserDelete = 'userDelete',
  UserPaginationChange = 'userPaginationChange'
}

export enum UserMutationType {
  UserLoading = 'userLoading',
  UserLoaded = 'userLoaded',
  UserRefreshModels = 'userRefreshModels',
  UserUpdateModels = 'userUpdateModels',
  UserFilterModels = 'userFilterModels',
}

export enum UserGetterType {
  User = 'user',
  Users = 'users',
  UserFilterResults = 'userFilterResults'
}
