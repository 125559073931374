import { isEmpty } from '@hgiasac/helper';
import { PhoneNumberUtil } from 'google-libphonenumber';
// import { i18n } from 'root/locales';

export interface IMessage {
  text: string;
}

export function ruleTrim(message = 'field_not_space') {
  return wrapRule((_rule, value, callback) => {
    return (value && value.trim() !== '') || parseMessage(message, callback);
  });
}

function parseMessage(message: string | IMessage, callback) {
  // const result = typeof message === 'string' ?
  //   i18n().t(message).toString() : message.text;
  const result = typeof message === 'string' ?
    message.toString() : message.text;

  return callback(new Error(result));
}

function wrapRule(rule) {
  return { validator: rule, trigger: 'blur' };
}

export function ruleRequired(message = 'field_required') {
  return wrapRule((_rule, value, callback) => {
    if (value === null || value === undefined) {
      return parseMessage(message, callback);
    }

    return (typeof value === 'string' && !!value.trim() && callback())
      || (Array.isArray(value) ? value.length > 0 && callback() : (value ? callback() : false))
      || parseMessage(message, callback);
  });
}
export function ruleRequiredNumber(message = 'field_required') {
  return wrapRule((_rule, value, callback) => {    
    return (value >= 0)
          ? callback()
          : parseMessage(message, callback);
  });
}

export function ruleEmail(message = 'email_invalid') {
  return wrapRule((_rule, value, callback) => {
    // tslint:disable
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // tslint:enable

    return !value || pattern.test(value.trim())
          ? callback()
          : parseMessage(message, callback);
  });
}

export function rulePhoneNumber(countryCode?: string, message?: string) {
  message = message || 'Invalid phone number';
  const util = PhoneNumberUtil.getInstance();
  const pattern = /^[0-9]+\s*$/;

  return wrapRule((_rule, value, callback) => {

    if (value.length < 8 || !pattern.test(value)) {
      return parseMessage(message, callback);
    }

    const num = util.parse(value, countryCode);

    return util.isValidNumber(num)
          ? callback()
          : parseMessage(message, callback);
  });
}

export function ruleMinLength(min: number, message?: string) {

  message = message || `At least ${min} characters`;

  return wrapRule((_rule, value, callback) => {
    return (value && value.length >= min)
          ? callback()
          : parseMessage(message, callback);
  });
}

export function ruleMaxLength(max: number, message?: string) {

  message = message || `Maximum ${max} characters`;

  return wrapRule((_rule, value, callback) => {
    return (value && value.length <= max)
          ? callback()
          : parseMessage(message, callback);
  });
}

export function ruleBetween(min: number, max: number, message?: string) {

  message = message || `Character length must be between ${min} and ${max}`;

  return wrapRule((_rule, value, callback) => {
    return (value && value.length >= min && value.length <= max)
          ? callback()
          : parseMessage(message, callback);
  });
}

export function matchYoutubeUrl(message = 'youtube_link_invalid') {
  return wrapRule((_rule, value, callback) => {
    // tslint:disable
    const pattern = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    // tslint:enable

    return !value || pattern.test(value.trim())
          ? callback()
          : parseMessage(message, callback);
  });
}

export function greaterThan(lessValue, message = 'greater_than') {
  return wrapRule((_rule, value, callback) => {
    return !value || value > lessValue
          ? callback()
          : parseMessage(message, callback);
  });
}

export function afterDate(lessValue, message = 'greater_than') {
  return wrapRule((_rule, value, callback) => {
    const dateValue = Date.parse(value);
    const lessDate = Date.parse(lessValue);
    console.log(dateValue, lessDate);

    return !dateValue || dateValue >= lessDate
      ? callback()
      : parseMessage(message, callback);
  });
}

export function ruleBeAURL(message = 'URL is invalid') {
  const pattern = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

  return wrapRule((_rule, value, callback) => {
    return pattern.test(value)
          ? callback()
          : parseMessage(message, callback);
  });
}

export function ruleUsernameCharacter(message = 'only a-z 0-9') {
  const pattern = /^[a-z0-9_.]+$/;

  return wrapRule((_rule, value, callback) => {
    return pattern.test(value)
          ? callback()
          : parseMessage(message, callback);
  });
}
