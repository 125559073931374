import { sfrHttpClient } from 'root/api/http';
import { IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IFeedState } from '.';
import { FeedActionType, FEED_MODULE_NAME } from './types';

const {
  create,
  filter,
  getById,
  update,
  deleteById
} = sfrHttpClient.feed;

export const actions = {
  ...crudActions(FEED_MODULE_NAME, {
    create,
    filter,
    getById,
    update,
    deleteById
  }),
  async [FeedActionType.FeedDeleteComment](
    {}: ActionContext<IFeedState, IState> , {feedId, commentId}): Promise<any> {
    return sfrHttpClient.feed.deleteComment(feedId, commentId);

  }
};
