import { Form } from 'element-ui';
import { flatten, includes } from 'lodash';
import { omit } from 'lodash';
import { checkFileTypeImage, ruleRequired } from 'root/helpers';
import { ITemplateEmail, ITemplateEmailCreateForm, TemplateEmailCreateDefaultForm } from 'root/models';
import { getPublicLink, uploadImageS3 } from 'root/services/upload';
import { isArray, isUndefined } from 'util';
import Vue from 'vue';
import Component from 'vue-class-component';
import { VueEditor } from 'vue2-editor';

@Component({
  template: require('./view.html'),
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean
  },
  components: {
    'vue-editor': VueEditor
  },
  watch: {
    data() {
      this.dataChange();
    }
  },
})
export class TemplateEmailForm extends Vue {
  public $refs: {
    form: Form;
  };

  public form: ITemplateEmailCreateForm = TemplateEmailCreateDefaultForm();
  public loading: boolean;
  public data: ITemplateEmail;
  public imageUploading: boolean = false;
  public customToolbar: any = [['underline']];
  public get rules() {
    return {
      content: [
        ruleRequired('Please input Content')
      ],
      imageUrl: [
        ruleRequired('Please update a Image')
      ],
      footerContent: [
        ruleRequired('Please input Footer Content')
      ]
    };
  }

  public handlePreviewImage(file) {
    this.imageUploading = true;

    if (!checkFileTypeImage(file)) {
      this.$message({
        type: 'error',
        message: 'This file is not an image'
      });
      this.imageUploading = false;

      return;
    }
    uploadImageS3('competition/', file.raw).then(async (result: any) => {
      this.imageUploading = false;
      this.form.imageUrl = getPublicLink(result.key);
      this.$refs.form.validateField('imageUrl', () => {
        //
      });
      this.$message({
        type: 'success',
        message: 'Upload successful'
      });
    }).catch((error) => {
      this.imageUploading = false;
      this.$message({
        type: 'error',
        message: error.message
      });
    });
  }

  public async isValidForm() {
    const promises = flatten(Object.values(this.$refs).map((element: any) => {
      if (isArray(element)) {
        return (<any> element).map((form) => {
          return new Promise((resolve, _reject) => {
            form.validate((valid, result) => {
              resolve({ valid, result });
            });
          });
        });
      }

      return new Promise((resolve, _reject) => {
        element.validate((valid, result) => {
          resolve({ valid, result });
        });
      });
    }));

    return Promise.all(promises).then((arrayResolve) => {
      const errors = arrayResolve.map((resolve) => {
        if (isUndefined(resolve) || (<any> resolve).valid) {
          return;
        }
        this.$message({
          type: 'error',
          message: Object.values((<any> resolve).result)[0][0].message
        });

        return false;
      });

      return !includes(errors, false);
    });
  }

  public async handleSubmit() {
    const valid = await this.isValidForm();
    if (!valid) {
      return;
    }
    this.$emit('submit', omit(this.form, ['typeEmail']));

  }

  public mounted() {
    this.dataChange();
  }

  public dataChange() {
    if (!this.data) {
      return;
    }

    this.form = { ...this.data };
  }

}
