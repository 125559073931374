export interface IBannerCreateForm {
  contentType: BannerType;
  referenceId?: string;
  externalLinkData?: IExternalLinkData;
}

export interface IBanner extends IBannerCreateForm {
  id: string;
  position: number;
}

export enum BannerType {
  ExternalUrl = "external_url",
  Competition = "competition",
  User = "user",
  Post = "post"
}

export interface IExternalLinkData {
  link: string;
  title: string;
  description: string;
  thumbnailUrl: string;
}

export const BannerCreateDefaultForm = (): IBannerCreateForm => {
  return {
    contentType: BannerType.ExternalUrl,
    externalLinkData: {
      link: '',
      title: '',
      description: '',
      thumbnailUrl: ''
    }
  };
};
