import { INotification } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryNotification } from '../..';
import { NotificationActionType, NotificationGetterType } from '../../Store/types';
import './view.scss';
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.notification.detailLoading
    })
  }
})

export class NotificationDetail extends Vue {

  public get NotificationId(): string {
    return this.$route.params.NotificationId;

  }

  public get detail(): INotification {
    return this.$store
      .getters[NotificationGetterType.Notification](this.NotificationId);
  }

  public handleEditBtn() {
    this.$router.push({
      path: `${RouterDictionaryNotification.Notification}/update/${this.NotificationId}`
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        NotificationActionType.NotificationGetById, {
          id: this.NotificationId
        }
      );
    });
  }
}
