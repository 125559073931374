import { pick } from 'lodash';
import { parseParamUrl } from 'root/helpers';
import { IGifCategory } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IGifCategoryHttpService extends ICRUDHttpService<IGifCategory> {
  
}

export function GifCategoryHttpService(options: IHttpServiceClientOptions): IGifCategoryHttpService {

  const basePath = '/admin/gif-categories';
  const { httpService, mock } = options;

  const baseUrl = httpService.getUrl(basePath, mock);


  return {...CRUDHttpService({
    ...options,
    basePath,
  }),
  };
}


