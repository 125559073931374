import { IReportCategory } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { REPORTCATEGORY_MODULE_NAME } from './types';

export type IReportCategoryState = ICRUDState<IReportCategory>;

export const defaultState: IReportCategoryState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(REPORTCATEGORY_MODULE_NAME),
};
