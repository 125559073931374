import { ProfileLogout } from './Logout';

export enum RouterDictionaryProfile {
  ProfileLogout = '/profile/logout'
}

export const ProfileRoutes = {
  path: RouterDictionaryProfile.ProfileLogout,
  component: ProfileLogout,
  children: [
    {
      path: '',
      component: ProfileLogout
    }
  ]
};
