import { IFeed } from './Feed';

export enum ReportCategoryStatus {
  Active = 'active',
  Suspended = 'suspended',
  Deleted = 'deleted'
}

export interface IReportCategoryCreateForm {
  title: string;
  description: string;
  status: ReportCategoryStatus;
}

export interface IReportCategory extends IReportCategoryCreateForm {
  id: string;
}

export const ReportCategoryCreateDefaultForm = (): IReportCategoryCreateForm => {
  return {
    title: '',
    description: '',
    status: undefined
  };
};

export interface IReportPostCreateForm {
  comment: string;
  referenceId: string;
  post: IFeed;
}

export interface IReportPost extends IReportPostCreateForm {
  id: string;
  createAt: Date;
}

export const ReportPostCreateDefaultForm = (): IReportPostCreateForm => {
  return {
    comment: '',
    referenceId: '',
    post: undefined
  };
};
