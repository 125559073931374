export interface IStickerCategories extends StickerCategoryCreateForm {
  id: string;
}

export interface StickerCategoryCreateForm {
    name: string;
    price: number;
    urls: string[];
    avatarUrl: string;
    downloadedTotal: number;
}

export const StickerCategoryCreateFormDefault = (): StickerCategoryCreateForm => {
    return {
      name: '',
      price: null,
      urls: [],
      avatarUrl: '',
      downloadedTotal: null,
    };
};
