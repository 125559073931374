import { getLocaleString, ILanguageDictionary } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import { listFileIcon } from 'root/helpers';
import { i18n } from 'root/locales';
import { Gender, StatusCode } from 'root/models';
import { IAvatarDefault, IState } from './types';

export const getters = {
  getLocaleString(state: IState) {
    return (val: string | ILanguageDictionary): string => {
      return getLocaleString(val, state.global.currentLocale);
    };
  },
  statusOption() {
    return [{
      value: StatusCode.Active,
      label: i18n().t(StatusCode.Active).toString()
    }, {
      value: StatusCode.Deleted,
      label: i18n().t(StatusCode.Deleted).toString()
    }];
    //  temporary remove
    // {
    //   value: StatusCode.Deactivate,
    //   label: i18n().t(StatusCode.Deactivate).toString()
    // }
  },
  genderOption() {
    return [
      { value: Gender.Male, label: i18n().t(Gender.Male).toString() },
      { value: Gender.Female, label: i18n().t(Gender.Female).toString() },
    ];
  },
  actionOption() {
    return [
      'detail',
      'update',
      'delete'
    ];
  },
  avatarDefault(): IAvatarDefault {
    return {
      user: '/assets/img/avatar-placeholder-80.svg',
      image: '/assets/img/image-placeholder.svg',
      video: '/assets/img/video-placeholder.svg',
      project: '/assets/img/project-placeholder.png',
      plan: '/assets/img/plan-favourite.svg',
      message: '/assets/img/message-favourite.svg'
    };
  },
  fileTypeFilter(): string[] {
    const _listFileIcon = cloneDeep(listFileIcon);
    const data: string[] = [];
    _listFileIcon.forEach((element) => {
      data.push(`.${element}`);
    });

    return data;
  }
};
