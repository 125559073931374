import { Category } from './Container';
import { CategoryList } from './Container/List';
import { CategoryCreate } from './Container/Create';
import { CategoryUpdate } from './Container/Update';
// import { CategoryDetail } from './Container/Detail';

export enum RouterDictionaryCategory {
  Category = '/category',
  CategoryCreate= '/category/create',
  // CategoryDetail = '/category/:categoryId',
  CategoryUpdate = '/category/update/:categoryId'
}

export const CategoryRoutes = {
  path: RouterDictionaryCategory.Category,
  component: Category,
  children: [
    {
      path: '',
      component: CategoryList,
      name: 'Category List',
    },
    {
      path: RouterDictionaryCategory.CategoryCreate,
      component: CategoryCreate,
      name: 'Category Create'
    },
    // {
    //   path: RouterDictionaryCategory.CategoryDetail,
    //   component: CategoryDetail,
    //   name: 'Detail'
    // },
    {
      path: RouterDictionaryCategory.CategoryUpdate,
      component: CategoryUpdate,
      name: 'Category Update'
    }
  ]
};
