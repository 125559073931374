import { Competition } from './Container';
import { CompetitionCreate } from './Container/Create';
import { CompetitionDetail } from './Container/Detail';
import { CompetitionList } from './Container/List';
import { CompetitionUpdate } from './Container/Update';
import {  CompetitionApplicantList } from './Container/ApplicantList';
import { FormApplicant } from './Container/ApplicantCreate';

export enum RouterDictionaryCompetition {
  Competition = '/competition',
  CompetitionCreate = '/competition/create',
  CompetitionDetail = '/competition/:competitionId',
  CompetitionUpdate = '/competition/update/:competitionId',
  CompetitionApplicantList = '/competition/:competitionId/applicant',
  CreateApplicant = '/competition/:competitionId/applicant/new',
}

export const CompetitionRoutes = {
  path: RouterDictionaryCompetition.Competition,
  component: Competition,
  children: [
    {
      path: '',
      component: CompetitionList,
      name: 'Competition List',
    },
    {
      path: RouterDictionaryCompetition.CompetitionCreate,
      component: CompetitionCreate,
      name: 'Competition Create'
    },
    {
      path: RouterDictionaryCompetition.CompetitionApplicantList,
      component: CompetitionApplicantList,
      name: 'Applicants List',
    },
    {
      path: RouterDictionaryCompetition.CompetitionDetail,
      component: CompetitionDetail,
      name: 'Competition Detail'
    },
    {
      path: RouterDictionaryCompetition.CompetitionUpdate,
      component: CompetitionUpdate,
      name: 'Competition Update'
    },
    {
      path: RouterDictionaryCompetition.CreateApplicant,
      component: FormApplicant,
      name: 'Applicant Create'
    }
  ]
};
