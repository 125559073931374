import Vue from "vue";
import Component from "vue-class-component";
// import '../../Container/CreateCompetition/styles.scss';
import { ruleEmail, ruleRequired } from "root/helpers";
import { SFRCheckbox } from "root/components/SFRCheckbox";
import {
  ICompetitionCreateForm,
  ICompetitionWinner,
  ICompetitionRoundDefault,
  ICompetitionRound,
  ICompetitionWinnerDefault,
} from "root/models";
import { mapState } from 'vuex';
import { IState } from 'root/store';
import { CompetitionMutationType } from "./../../../Store/types";
import './styles.scss';

@Component({
  template: require("./view.html"),
  components: {
    "sfr-checkbox": SFRCheckbox
  },
  computed: {
    ...mapState({
      isSelectTimeline: (state: IState) => state.competition.isSelectTimeline
    })
  },
  props: {
    form: Object
  }
})
export class Step2 extends Vue {
  public form: ICompetitionCreateForm;
  public rounds: Array<any>;
  public round: ICompetitionRound = ICompetitionRoundDefault();
  public prize: ICompetitionWinner = ICompetitionWinnerDefault();
  public dialogTimeline: boolean = false;

  public extraRound() {
    let { registrationPeriod, ...extraRound } = this.round;
    return extraRound;
  }

  public change(index, time, type: string){
    const day = new Date(time).getDate();
    const date = new Date(time).setDate((day + 1));
    if(!this.form.round[index][type])
      this.form.round[index][type] = new Date(date);
  }

  public get rules() {
    return {
      required: error => {
        return ruleRequired(error);
      },
      email: ruleEmail("email.valid.signup")
    };
  }

  public submit() {
    this.dialogTimeline = false;
    this.$store.commit(CompetitionMutationType.SetSelectedTimeline)
  }

  public handleWildCards(index) {
    // this.form.round[index].isAddedWildcards = !this.form.round[index]
    //   .isAddedWildcards;
    if(index === 0) {
      this.form.round[index].isAddedWildcards = false;
      this.form.round[index].numberWildcards = 0;
    } else {
      this.form.round[index].isAddedWildcards = true;
      this.form.round[index].numberWildcards = this.form.round[index].numberWildcards;
    }
  }

  public closeDialog() {
    this.dialogTimeline = false;
  }

  public openDialog() {
    this.dialogTimeline = true;
  }

  public addMoreExtraRound() {
    this.form.round.push(this.extraRound());
  }

  public removeRow(key, index) {
    this.form[key].splice(index, 1);
  }

  public addMoreWinner() {
    const prize: ICompetitionWinner = ICompetitionWinnerDefault();
    this.form.winner.push(prize);
  }

  public handleIsWildCards(index) {
    this.form.round[index].isAddedWildcards = !this.form.round[index]
      .isAddedWildcards;
  }
  public handleIsReupload(index) {
    this.form.round[index].isReUpload = !this.form.round[index].isReUpload;
  }
  public disabledDate(time) {
    let today = new Date();
    return (time.getTime() + 1000 * 3600 *24) < today;
  }
}
