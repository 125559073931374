import { sfrHttpClient } from 'root/api/http';
import { crudActions } from 'root/store/helpers';
import { PROMOTION_MODULE_NAME } from './types';

const {
  create,
  filter,
  getById,
  update,
  deleteById
} = sfrHttpClient.promotion;

export const actions = {
  ...crudActions(PROMOTION_MODULE_NAME, {
    create,
    filter,
    getById,
    update,
    deleteById
  }),
};
