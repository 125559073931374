export interface ITemplateEmailCreateForm {
  content: string;
  footerContent: string;
  imageUrl: string;
}

export enum EmailType {
  ActivationMailer = 'activation_mailer',
  CancelDeactivateMailer = 'cancel_deactivate_mailer',
  DeactivateMailer = 'deactivate_mailer',
  NewUserSigupMailer = 'new_user_signup_mailer',
  ResetPasswordMailer = 'reset_password_mailer'
}

export interface ITemplateEmail extends ITemplateEmailCreateForm {
  id: string;
  typeEmail: EmailType;
}

export const TemplateEmailCreateDefaultForm = (): ITemplateEmailCreateForm => {
  return {
    footerContent: '',
    content: '',
    imageUrl: ''
  };
};
