import { IReportPost } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { REPORTPOST_MODULE_NAME } from './types';

export type IReportPostState = ICRUDState<IReportPost>;

export const defaultState: IReportPostState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(REPORTPOST_MODULE_NAME),
};
