import { omit } from 'lodash';
import { IReportCategory } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
export type IReportCategoryHttpService = ICRUDHttpService<IReportCategory>;

export function ReportCategoryHttpService(options: IHttpServiceClientOptions): IReportCategoryHttpService {

  const basePath = '/admin/report-categories';

  return CRUDHttpService({
    ...options,
    basePath,
    serializeUpdate
  });
}

function serializeUpdate(model: any): any {
  return omit(model, ['id']);
}
