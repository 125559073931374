import { Badword } from './Container';
import { BadwordCreate } from './Container/Create';
import { BadwordList } from './Container/List';
import { BadwordUpdate } from './Container/Update';

export enum RouterDictionaryBadword {
  Badword = '/badword',
  // BadwordCreate = '/badword/create',
  BadwordUpdate = '/badword/update/:BadwordId'
}

export const BadwordRoutes = {
  path: RouterDictionaryBadword.Badword,
  component: Badword,
  children: [
    {
      path: '',
      name: 'Badword List',
      component: BadwordList
    },
    {
      path: RouterDictionaryBadword.BadwordUpdate,
      name: 'Badword Update',
      component: BadwordUpdate
    }
  ]
};
