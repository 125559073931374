import { sfrHttpClient } from 'root/api/http';
import { crudActions } from 'root/store/helpers';
import { COMPETITION_MODULE_NAME, CompetitionActionType, CompetitionMutationType } from './types';
import { ActionContext } from "vuex";
import { ICompetitionState } from '.';
import { IApplicantState } from './mutations';
import { ActionType, IState } from 'root/store';
import { stripObject } from '@hgiasac/helper';
import { ICompetition } from 'root/models';


const {
  create,
  filter,
  getById,
  update,
  deleteById,
  updateStatusCompetition,
  deactiveCompetition,
  getCompetitionApplicants,
  changeStatus,
  createApplicant,
  getByIdAndCountry,
  payCompetitions,
  deleteCompetition,
} = sfrHttpClient.competition;

export const actions = {
  ...crudActions(COMPETITION_MODULE_NAME, {
    create,
    filter,
    getById,
    update,
    deleteById,
  }),
  async [CompetitionActionType.UpdateStatusCompetition](
    { }: ActionContext<ICompetitionState, IState>, { id, statusCompetition }): Promise<any> {
    return updateStatusCompetition(id, statusCompetition)
  },
  async [CompetitionActionType.DeactiveCompetition](
    { }: ActionContext<ICompetitionState, IState>, id): Promise<any> {
    return deactiveCompetition(id);
  },
  async [CompetitionActionType.CompetitionGetById](
    { commit, dispatch }: ActionContext<ICompetitionState, IState>, { id, onSuccess, onFailure }
  ) {
    commit(CompetitionMutationType.CompetitionLoading);
    try {
      const data = await getById(id)
      if (onSuccess) {
        onSuccess();
      }
      commit(CompetitionMutationType.SetCompetitionDetail, data);
    } catch (error) {
      if (onFailure) {
        onFailure(error);
      }
    }
    commit(CompetitionMutationType.CompetitionLoaded);
  },
  async [CompetitionActionType.UpdateCompetition](
    {dispatch}: ActionContext<ICompetitionState, IState>, { competitionId, form, onSuccess, onFailure }
  ) {
    try {
      return update(competitionId, form).then(resp => {
        if(onSuccess) {
          onSuccess(resp)
        }
      })
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  [CompetitionActionType.ToggleActive](
    { commit, dispatch }: ActionContext<ICompetitionState, IState>, { competitionId, onSuccess }
  ) {
    try {
      return deleteById(competitionId).then((resp) => {
        if (onSuccess) {
          onSuccess(resp);
        }
      });
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  //applicant
  async [CompetitionActionType.GetContestantsByCompetition](
    { commit, dispatch }: ActionContext<ICompetitionState, IState>, { competitionId, dataFilter }) {
    commit(CompetitionMutationType.SetApplicantListLoading);
    dataFilter = stripObject(dataFilter);
    try {
      const data = await getCompetitionApplicants(dataFilter, competitionId);
      commit(CompetitionMutationType.SetCompetitionApplicants, data);
      commit(CompetitionMutationType.SetApplicantListLoaded);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [CompetitionActionType.GetCompetitionDetail](
    {commit, dispatch}: ActionContext<ICompetitionState, IState>, {id, currentCountry, onSuccess, onFailure}
  ) {
    commit(CompetitionMutationType.SetDetailApplicantLoading);
    try {
      const data = currentCountry ? await getByIdAndCountry(id, currentCountry) : await getById(id)
      if (onSuccess) {
        onSuccess();
      }
      commit(CompetitionMutationType.SetCompetitionDetail, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      if (onFailure) {
        onFailure(error);
      }
    }
    commit(CompetitionMutationType.SetDetailApplicantLoaded);
  },
  async [CompetitionActionType.CreateApplicant](
    {commit, dispatch}: ActionContext<IApplicantState, IState>, {competitionId, form, onSuccess, onFailure, optionsHTTP }
  ) {
    commit(CompetitionMutationType.ApplicantLoading);
    try {
      await createApplicant(competitionId, form)
        .then((result) => {
          console.log(form);
          
          commit(CompetitionMutationType.ApplicantLoaded);
          if(onSuccess) {
            onSuccess(result);
          }
      });
    } catch (error) {
        commit(CompetitionMutationType.ApplicantLoaded);
        dispatch(ActionType.CatchException, error);
        if (onFailure) {
          onFailure(error);
        }
      }
  },
  async [CompetitionActionType.ApplicantChangeStatus](
    { commit, dispatch }: ActionContext<ICompetitionState, IState>, { competitionId, form, onSuccess, onFailure, optionsHTTP }
  ) {
    try {
      await changeStatus(competitionId, form)
        .then((result) => {
          if (onSuccess) {
            onSuccess(result);
          }
        });
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      if (onFailure) {
        onFailure(error);
      }
    }
  },
  async [CompetitionActionType.PayCompetition](
    {commit, dispatch}: ActionContext<ICompetitionState, IState>, {competitionId, onSuccess, onFailure }
  ) {
    return payCompetitions(competitionId)
      .then((result) => {
        if(onSuccess) {
          dispatch(ActionType.ReloadProfile);
          onSuccess(result);
        }
      }).catch((e) => {
        dispatch(ActionType.CatchException, e);
        if (onFailure) {
          onFailure(e);
        }
      })
  },
  async [CompetitionActionType.CompetitionDelete](
    {commit, dispatch}: ActionContext<ICompetitionState, IState>, {competitionId, onSuccess, onFailure }
  ) {
    return deleteCompetition(competitionId)
      .then((result) => {
        if(onSuccess) {
          onSuccess(result);
        }
      }).catch((e) => {
        dispatch(ActionType.CatchException, e);
        if (onFailure) {
          onFailure(e);
        }
      })
  }
};
