import { IStickerCategories } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { STICKER_CATEGORIES_MODULE_NAME } from './types';

export type IStickerCategoriesState = ICRUDState<IStickerCategories>;

export const defaultState: IStickerCategoriesState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(STICKER_CATEGORIES_MODULE_NAME),
};
