import { Form } from 'element-ui';
import { IPromotion, IPromotionCreateForm, PromotionCreateDefaultForm, PromotionType } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: require('./view.html'),
  props: {
    data: Object,
    loading: Boolean
  }
})
export class PromotionForm extends Vue {

  public $refs: {
    form: Form
  };

  public form: IPromotionCreateForm = PromotionCreateDefaultForm();
  public loading: boolean;
  public data: IPromotion;

  public promotionTypeOptions: any[] = [
    {
      value: PromotionType.Competition,
      label: PromotionType.Competition
    },
    {
      value: PromotionType.Event,
      label: PromotionType.Event
    }
  ];

    // format get and set for registration date
  public set promotionDate(newValue: any[]) {
    this.form.startDate = newValue[0];
    this.form.endDate = newValue[1];
  }

  public get promotionDate(): any[] {
    return [this.form.startDate, this.form.endDate];
  }

  public handleSubmit() {

    this.$refs.form.validate((valid) => {
      if (!valid) {
        return;
      }

      this.$emit('submit');

    });
  }

  public mounted() {
    if (!this.data) {
      return;
    }

    this.form.title = this.data.title;
    this.form.type = this.data.type;
  }

}
