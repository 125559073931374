import { Form } from 'element-ui';
import { flattenDeep, isEmpty } from 'lodash';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryUser } from '../../../User';
import { FeedActionType, FeedGetterType } from '../../Store/types';
import { RouterDictionaryFeed } from '../../index';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.user.detailLoading
    })
  }
})
export class FeedDetail extends Vue {
  public $refs: {
    form: Form
  };

  public uploading: boolean = false;

  public get feedId() {
    return this.$route.params.feedId;
  }

  public get detail() {
    return this.$store.getters[FeedGetterType.Feed](this.feedId);
  }

  public get comments() {
    return flattenDeep(this.detail.comments.map((comment) => {
      if (!isEmpty(comment.childComments)) {
        return [].concat(comment, comment.childComments);
      }

      return comment;
    }));

  }

  public getUserLink(id) {
    return `${RouterDictionaryUser.User}/${id}`;
  }

  public handleEditBtn() {
    this.$router.push({
      path: `${RouterDictionaryFeed.Feed}/update/${this.feedId}`
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(FeedActionType.FeedGetById, {
        id: this.feedId
      });
    });
  }
}
