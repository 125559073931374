export enum CompetitionPhaseType {
  PrepareWorks = 'prepare_works',
  RegisterUpload = 'register_upload',
  Vote = 'vote',
  FinalWinner = 'final_winner',
  Ranking = 'ranking'
}

export const CompetitionPhaseTypeDescription = {
  [CompetitionPhaseType.PrepareWorks] : 'Prepare works period',
  [CompetitionPhaseType.RegisterUpload] : 'Registration & Upload works',
  [CompetitionPhaseType.Vote] : 'Voting for Top 10 selection',
  [CompetitionPhaseType.FinalWinner] : 'Top 10 announced and Poll for final winner',
  [CompetitionPhaseType.Ranking] : 'Final ranking announced'
};

export interface ICompetitionPhase {
  name: string;
  startDate: Date;
  endDate: Date;
  type: CompetitionPhaseType;
  imageBanner: string;
  textBanner: string;
}

export const CompetitionPhaseForm = (type: CompetitionPhaseType): ICompetitionPhase => {
  return {
    type,
    name: CompetitionPhaseTypeDescription[type],
    startDate: undefined,
    endDate: undefined,
    imageBanner: '',
    textBanner: ''
  };
};

export const CreateCompetitionPhasesForm = (): ICompetitionPhase[] => {

  return Object.values(CompetitionPhaseType).map((state) => {
    return CompetitionPhaseForm(state);
  });

};
