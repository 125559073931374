import * as moment from 'moment';
// import { i18n } from 'root/locales';
import { IUser, IUserItem } from 'root/models';

export function fullNameUser(model: IUser | IUserItem) {
  return `${model.firstName} ${model.lastName}`;
}

export function formatTimeDuration(time: Date, locale?: string) {
  if (!time) {
    return '';
  }

  return moment(time).locale(locale || 'en').fromNow();
}

export function formatTimeString(time: Date, locale?: string) {
  if (!time) {
    return '';
  }

  return moment(time).locale(locale || 'en').format('Do MMM, YYYY h:mm:ss A');
}

export function formatDateString(time: Date, locale?: string) {
  if (!time) {
    return '';
  }

  return moment(time).locale(locale || 'en').format('Do MMM, YYYY');
}
