import { IStickerCategories } from './StickerCategories';
export interface ISticker extends StickerCreateForm {
    id: string;
}
export interface StickerCreateForm {
    name: string;
    hint: string;
    stickerCategoryId: string;
    url: string;
    category?: IStickerCategories;
}

export const StickerCreateFormDefault = (): StickerCreateForm => {
    return {
      name: '',
      hint: '',
      stickerCategoryId: '',
      url: '',
      category:  null,
    };
  };