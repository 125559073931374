import { IVersion } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryVersion } from '../..';
import { VersionForm } from '../../Component/VersionForm';
import { VersionActionType, VersionGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'version-form': VersionForm
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.version.detailLoading
    })
  }
})

export class VersionUpdate extends Vue {
  public get versionId(): string {
    return this.$route.params.versionId;
  }

  public get detail(): IVersion {
    return this.$store
      .getters[VersionGetterType.Version](this.versionId);
  }

  public handleSubmit(form) {
    this.$store.dispatch(VersionActionType.VersionUpdate, {
      form,
      id: this.versionId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Update successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'warning',
          message: `${error.message}`
        });
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        VersionActionType.VersionGetById, {
          id: this.versionId
        }
      );
    });
  }
}
