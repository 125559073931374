import Vue from 'vue';
import Component from 'vue-class-component';
import { ActionTypeWallet } from '../../Store/types';
import { mapState } from 'vuex';
import { IState } from 'root/store';
import { RouterDictionaryWallet } from '../../';
import { IWallet } from 'root/models';
import './view.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.wallet.walletPackages,
      loading: (state: IState) => state.wallet.loading,
      pagination: (state: IState) => state.wallet.pagination,
    })
  },
})
export class WalletPackages extends Vue {
  public data: IWallet[];
  public currentPage = 1;
  public mounted() {
    this.$store.dispatch(ActionTypeWallet.GetPaymentPackages, { page: 1, size: 20 });
  }
  public changePagination(event){
    this.currentPage = event;
    this.$store.dispatch(ActionTypeWallet.GetPaymentPackages, { page: this.currentPage, size: 20 })
  }
  public handleWalletPackageUpdate(id){
    this.$router.push({
      path: `${RouterDictionaryWallet.WalletPackages}/${id}`
    });
  }
}
