import { i18n } from 'root/locales';
import { formatJson } from 'root/utils';
import Vue from 'vue';
import Component from 'vue-class-component';
import * as Excel from '../../utils/vendor/Export2Excel';
import './style.scss';
@Component({
  template: require('./view.html'),
  props: {
    fields: Array,
    fetchData: Function
  }
})
export class Export2Excel extends Vue {

  public fields: string[];
  public fetchData: any;

  public downloadLoading: boolean = false;

  public dialogExportVisible: boolean = false;
  public fileExportName: string = '';

  public checkedAllFields: boolean = false;
  public checkedFields: string[] = [];
  public isIndeterminate: boolean = true;
  public autoWidth: boolean = true;

  public get headFields() {
    return this.checkedFields.map((field) => {
      return i18n().t(field).toString();
    });
  }
  public handleCheckAllFields(val) {
    this.checkedFields = val ? this.fields : [];
    this.isIndeterminate = false;
  }

  public handleCheckedFieldChange(val) {
    const count = val.length;
    this.checkedAllFields = count === this.fields.length;
    this.isIndeterminate = count > 0 && count < this.fields.length;
  }

  public handleDownload() {
    this.downloadLoading = true;
    this.fetchData(this.checkedFields.includes('emailVerified')).then((result) => {
      if (this.downloadLoading === false) { // Cancel

        return;
      }

      const data = formatJson(this.checkedFields, result);

      Excel.export_json_to_excel({
        data,
        header: this.headFields,
        filename: `${this.fileExportName}`,
        autoWidth: this.autoWidth,
        bookType: 'xlsx'
      });
      this.downloadLoading = false;
    }).catch(() => {
      this.downloadLoading = false;
      this.$message({
        type: 'error',
        message: 'Export failed, Please try again'
      });
    });
  }

  public close(done) {
    this.fileExportName = '';
    this.checkedAllFields = false;
    this.checkedFields = [];
    this.autoWidth = true;
    this.downloadLoading = false;
    this.dialogExportVisible = false;

    return typeof done === 'function' && done();
  }

}
