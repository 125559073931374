import { pick } from 'lodash';
import { parseParamUrl } from 'root/helpers';
import { IFeed } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
export interface IFeedHttpService extends ICRUDHttpService<IFeed> {
  deleteComment(feedId: string, commentId: string): Promise<any>;
  setBannerFeed(id: string): Promise<any>;
  updateFeedToCategory(form: any, categoryIds: string[]): Promise<any>;
  getCategoriesByFeed(feedId: string): Promise<any>;
}

export function FeedHttpService(options: IHttpServiceClientOptions): IFeedHttpService {

  const basePath = '/admin/feeds';
  const { httpService, mock } = options;

  const baseUrl = httpService.getUrl(basePath, mock);

  function deleteComment(feedId: string, commentId: string): Promise<any> {
    return httpService.del(`${parseParamUrl(baseUrl, null)}/${feedId}/comments/${commentId}`);
  }

  function getCategoriesByFeed(feedId: string): Promise<any> {
    return httpService.get(`${parseParamUrl(baseUrl, null)}/${feedId}/categories`);
  }

  function updateFeedToCategory(feedId: string, categoryIds: string[]): Promise<any> {
    return httpService.post(`${parseParamUrl(baseUrl, null)}/${feedId}/update_category`, categoryIds);
  }

  function setBannerFeed(id: string): Promise<any> {
    const url = options.httpService.getUrl(`${basePath}/${id}/banner`, options.mock);
    return options.httpService.post(url, {
      contentType: 'post'
    });
  }

  return {...CRUDHttpService({
    ...options,
    basePath,
    serializeUpdate
  }),
    deleteComment,
    setBannerFeed,
    updateFeedToCategory,
    getCategoriesByFeed
  };
}

function serializeUpdate(post: IFeed): any {
  const result = pick(post, ['status', 'isFeatured', 'isPinned', 'isAdvertisement', 'displayText', 'isDownload']);

  return {
    ...result
  };
}
