import { ICategory } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
import { omit, pick } from 'lodash';
import { parseParamUrl } from 'root/helpers';

export interface ICategoryHttpService extends ICRUDHttpService<ICategory> {
  updatePositionCategories(form: any): Promise<any>;
  getDetailCategory(id: string): Promise<any>;
}

export function CategoryHttpService(options: IHttpServiceClientOptions): ICategoryHttpService {

  const basePath = '/admin/categories';
  const { httpService, mock } = options;
  const baseUrl = httpService.getUrl(basePath, mock);

  function updatePositionCategories(form: any): Promise<any> {

    return httpService.post(`${parseParamUrl(baseUrl, null)}/update_positions`, form);
  }

  function getDetailCategory(id: string): Promise<any> {

    return httpService.get(`${parseParamUrl(baseUrl, null)}/${id}/detail`);
  }

  return {...CRUDHttpService({
      ...options,
      basePath,
      serializeUpdate
    }),
      updatePositionCategories,
      getDetailCategory
  };
}

function serializeUpdate(model: any): any {
  return omit(model, ['id', "countItems", "isSpecial"]);
}
