import { ActionType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
})
export class ProfileLogout extends Vue {

  public beforeCreate() {
    this.$store.dispatch(ActionType.Unauthenticated);
  }
}
