import { formatTimeString } from "root/helpers";
import { IWallet } from "root/models";
import { IState } from "root/store";
import Vue from "vue";
import Component from "vue-class-component";
import { mapState } from "vuex";
import { FilterForm } from "../../Component/FilterForm";
import { ActionTypeWallet } from "../../Store/types";
import "./view.scss";

@Component({
  template: require("./view.html"),
  computed: {
    ...mapState({
      data: (state: IState) => state.wallet.walletPaymentMethod,
      loading: (state: IState) => state.wallet.loading,
      pagination: (state: IState) => state.wallet.pagination,
    }),
  },
  components: {
    "search-form": FilterForm,
  },
})
export class WalletPayments extends Vue {
  public $refs: {
    table: any;
  };
  public data: IWallet[];
  public filterParams = {
    page: 1,
    size: 20,
    sortBy: "",
    sortByField: "",
    name: "",
  };
  public sortOrders: string[] = ['ascending', 'descending', null];
  public mounted() {
    this.$store.dispatch(ActionTypeWallet.GetPaymentMethods, this.filterParams);
  }
  public changePagination(event) {
    this.filterParams.page = event;
    this.$store.dispatch(ActionTypeWallet.GetPaymentMethods, this.filterParams);
  }
  public handleSortChange(obj) {
    this.filterParams.sortBy = obj.order ? obj.order : "";
    this.filterParams.sortByField = obj.prop;

    this.$store.dispatch(ActionTypeWallet.GetPaymentMethods, this.filterParams);
  }
  public handleSearch(search) {
    this.filterParams.page = 1;
    this.filterParams.name = search;
    this.$store.dispatch(ActionTypeWallet.GetPaymentMethods, this.filterParams);
  }
  public handleResetFilter() {
    this.$refs.table.clearSort();
    this.filterParams.page = 1;
    this.filterParams.name = "";
    this.filterParams.sortBy = "";
    this.filterParams.sortByField = "";
    this.$store.dispatch(ActionTypeWallet.GetPaymentMethods, this.filterParams);
  }
  public formatTimeString(time) {
    return formatTimeString(time);
  }
}
