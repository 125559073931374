import { IMaintenance } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryMaintenance } from '../..';
import { MaintenanceForm } from '../../Component/MaintenanceForm';
import { MaintenanceActionType, MaintenanceGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'maintenance-form': MaintenanceForm
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.notification.detailLoading
    })
  }
})

export class MaintenanceUpdate extends Vue {

  public get MaintenanceId(): string {
    return this.$route.params.MaintenanceId;
  }

  public get detail(): IMaintenance {
    return this.$store.getters[MaintenanceGetterType.Maintenance](this.MaintenanceId);
  }

  public handleSubmit(form) {
    this.$store.dispatch(MaintenanceActionType.MaintenanceUpdate, {
      form,
      id: this.MaintenanceId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Update successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public handleDelete() {
    this.$store.dispatch(MaintenanceActionType.MaintenanceDelete, {
      id: this.MaintenanceId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
        this.$router.push({
          path: RouterDictionaryMaintenance.Maintenance
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        MaintenanceActionType.MaintenanceGetById, {
          id: this.MaintenanceId
        }
      );
    });
  }
}
