import { ISticker } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { STICKER_MODULE_NAME } from './types';

export type IStickerState = ICRUDState<ISticker>;

export const defaultState: IStickerState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(STICKER_MODULE_NAME),
};
