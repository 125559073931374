import { ICategory } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryWallet } from '../..';
import { PackagesForm } from '../../Component/PackagesForm';
import { ActionTypeWallet } from '../../Store/types';
import { stripObject } from "@hgiasac/helper";

@Component({
  template: require('./view.html'),
  components: {
    'package-form': PackagesForm
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.category.detailLoading,
      data: (state: IState) => state.wallet.walletPackage,
    })
  },
})

export class WalletPackagesUpdate extends Vue {
  public get walletId(): string {
    return this.$route.params.id;
  }
  public data: IState;
  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        ActionTypeWallet.GetPaymentPackageById, this.walletId
      );
    });
  }
  public handleSubmit(form){
    this.$store.dispatch(ActionTypeWallet.PaymentPackageUpdate, { 
      form: stripObject(form),
      onSuccess: () => {
        this.$message({
          type: "success",
          message: "Update Successful"
        });
        this.$router.push('/wallet/packages');
      },
      onFailure: () => {
        this.$message({
          type: 'error',
          message: 'Update failed'
        });
      }
    });
  }

}
