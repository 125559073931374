import Vue from 'vue';
import Component from 'vue-class-component';
import { Panel } from '../Panel';
import './view.scss';

@Component({
  template: require('./view.html'),
  components: {
    panel: Panel
  }
})

export class PanelGroup extends Vue {

}
