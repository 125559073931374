import { ICompetition, CompetitionDefault, IApplicant, ApplicantDefault } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { COMPETITION_MODULE_NAME, CompetitionMutationType, CompetitionActionType } from './types';
import { IFeed } from 'root/models';
import { IPaginationState } from 'root/store';
import { findIndex, find } from 'lodash';

export interface ICompetitionState extends ICRUDState<ICompetition> {
  detail: ICompetition
}
export interface IApplicantState extends ICRUDState<IApplicant> {
  loading: boolean;
  detail: IApplicant;
}
export interface ICompetitionState extends ICRUDState<ICompetition> {
  isSubmited: boolean;
  loadingMore: boolean;
  detail: ICompetition;
  detailApplicant: IApplicant;
  detailLoading: boolean;
  userCompetitionLoading: boolean;
  userCompetitionLoadingMore: boolean;
  applicants: IFeed[];
  applicantDetailLoading: boolean;
  applicantListLoading: boolean;
  applicants_pagination: IPaginationState;
  checkLoaded: boolean;
  competitionRandom: Object;
  linkBranchIO: Object;
  fileExportingApplicant: any;
  applicantDetail: IApplicant;
  isSelectTimeline: boolean;
}

export const defaultState: ICompetitionState = {
  ...CRUDState(),
  isSubmited: false,
  loadingMore: false,
  detail: CompetitionDefault(),
  detailApplicant: ApplicantDefault(),
  detailLoading: false,
  userCompetitionLoading: false,
  userCompetitionLoadingMore: false,
  applicants: [],
  applicantDetailLoading: false,
  applicantListLoading: false,
  applicants_pagination: {},
  checkLoaded: false,
  competitionRandom: {},
  linkBranchIO: {},
  fileExportingApplicant: '',
  applicantDetail: ApplicantDefault(),
  isSelectTimeline: false,

};



export const mutations = {
  ...crudMutations(COMPETITION_MODULE_NAME),
  [CompetitionMutationType.SetSubmited](state: ICompetitionState) {
    state.isSubmited = true
  },
  [CompetitionMutationType.SetNotSubmit](state: ICompetitionState) {
    state.isSubmited = false
  },
  [CompetitionMutationType.SetSelectedTimeline](state: ICompetitionState) {
    state.isSelectTimeline = true;
  },
  [CompetitionMutationType.UnsetSelectedTimeline](state: ICompetitionState) {
    state.isSelectTimeline = false;
  },
  [CompetitionMutationType.UpdateCompetition](state: ICompetitionState, payload) {
    updateCompetitionList(state.data, payload.id, payload.statusCompetition)
  },
  [CompetitionMutationType.SetCompetitionDetail](state: ICompetitionState, payload) {
    state.detail = payload
  },
  [CompetitionMutationType.CompetitionLoading](state: ICompetitionState) {
    state.detailLoading = true
  },
  [CompetitionMutationType.CompetitionLoaded](state: ICompetitionState) {
    state.detailLoading = false
  },
  [CompetitionMutationType.SetCompetitionApplicants](state: ICompetitionState, payload) {
    state.applicants = payload.data;
    state.applicants_pagination = payload.pagination;
  },
  [CompetitionMutationType.SetApplicantListLoading](state: ICompetitionState) {
    state.applicantListLoading = true;
  },
  [CompetitionMutationType.SetApplicantListLoaded](state: ICompetitionState) {
    state.applicantListLoading = false;
  },
  [CompetitionMutationType.ApplicantLoaded](state: IApplicantState) {
    state.loading = false;
  },
  [CompetitionMutationType.SetDetailApplicantLoading](state: IApplicantState){
    state.loading = true;
  },
  [CompetitionMutationType.SetDetailApplicantLoaded](state: IApplicantState){
    state.loading = false;
  },
  [CompetitionMutationType.ApplicantLoading](state: IApplicantState) {
    state.loading = true;
  },

  [CompetitionMutationType.SetApplicantDetail](state: IApplicantState, data) {
    state.detail = data;
  },
};

export function updateCompetitionList(data: ICompetition[], id: string, payload: any): ICompetition[] {
  const index = findIndex(data, (e) => e.id === id);
  let competitionItem = find(data, (e) => e.id === id);
    competitionItem['statusCompetition'] = payload
  data[index] = competitionItem;
  return data;
}