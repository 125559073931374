import { sfrHttpClient } from 'root/api/http';
import { crudActions } from 'root/store/helpers';
import { REPORTCOMPETITION_MODULE_NAME } from './types';

const {
  create,
  filter,
  getById,
} = sfrHttpClient.reportCompetition;

export const actions = {
  ...crudActions(REPORTCOMPETITION_MODULE_NAME, {
    create,
    filter,
    getById,
  }),
};
