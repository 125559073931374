import { Promotion } from './Container';
import { PromotionCreate } from './Container/Create';
import { PromotionDetail } from './Container/Detail';
import { PromotionList } from './Container/List';

export enum RouterDictionaryPromotion {
  Promotion = '/promotion',
  PromotionCreate = '/promotion/create',
  PromotionUpdate = '/promotion/:promotionId'
}

export const PromotionRoutes = {
  path: RouterDictionaryPromotion.Promotion,
  component: Promotion,
  children: [
    {
      path: '',
      component: PromotionList,
      name: 'Promotion List',
    },
    {
      path: RouterDictionaryPromotion.PromotionCreate,
      component: PromotionCreate,
      name: 'Promotion Create',
    },
    {
      name: RouterDictionaryPromotion.PromotionUpdate,
      path: RouterDictionaryPromotion.PromotionUpdate,
      component: PromotionDetail,
    }
  ]
};
