import { INotification } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryNotification } from '../..';
import { NotificationForm } from '../../Component/NotificationForm';
import { NotificationActionType, NotificationGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'notification-form': NotificationForm
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.notification.detailLoading
    })
  }
})

export class NotificationUpdate extends Vue {

  public get NotificationId(): string {
    return this.$route.params.NotificationId;
  }

  public get detail(): INotification {
    return this.$store
      .getters[NotificationGetterType.Notification](this.NotificationId);
  }

  public handleSubmit(form) {
    this.$store.dispatch(NotificationActionType.NotificationUpdate, {
      form,
      id: this.NotificationId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Update successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public handleDelete() {
    this.$store.dispatch(NotificationActionType.NotificationDelete, {
      id: this.NotificationId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
        this.$router.push({
          path: RouterDictionaryNotification.Notification
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        NotificationActionType.NotificationGetById, {
          id: this.NotificationId
        }
      );
    });
  }
}
