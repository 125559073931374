import { ITemplateEmail } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryTemplateEmail } from '../..';
import { TemplateEmailForm } from '../../Component/TemplateEmailForm';
import { TemplateEmailActionType, TemplateEmailGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'template-email-form': TemplateEmailForm
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.notification.detailLoading
    })
  }
})

export class TemplateEmailUpdate extends Vue {

  public get TemplateEmailId(): string {
    return this.$route.params.TemplateEmailId;
  }

  public get detail(): ITemplateEmail {
    return this.$store.getters[TemplateEmailGetterType.TemplateEmail](this.TemplateEmailId);
  }

  public handleSubmit(form) {
    this.$store.dispatch(TemplateEmailActionType.TemplateEmailUpdate, {
      form,
      id: this.TemplateEmailId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Update successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public handleDelete() {
    this.$store.dispatch(TemplateEmailActionType.TemplateEmailDelete, {
      id: this.TemplateEmailId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
        this.$router.push({
          path: RouterDictionaryTemplateEmail.TemplateEmail
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        TemplateEmailActionType.TemplateEmailGetById, {
          id: this.TemplateEmailId
        }
      );
    });
  }
}
