import { IUser, IFeed, ICompetition, ICategory } from 'root/models';
export function serializeSimplyUser(model: IUser) {
  return {
    id: model.id,
    title: model.username,
    description: ` - Email: ${model.email}`,
    img: model.avatar ? model.avatar : '/assets/img/web-avatar-150.png'
  }
}

export function serializeSimplyFeed(model: IFeed) {
  return {
    id: model.id,
    title: model.id,
    description: model.title ? ` - ${text_truncate(model.title, 100)}` : '',
    img: model.attachments && model.attachments.length > 0 ?
      (
        model.attachments[0].customThumbnailUrl ?
        model.attachments[0].customThumbnailUrl :
        model.attachments[0].thumbnailUrl
      ) :
      '/assets/img/no-image-icon-6.png'
  }
}

export function serializeSimplyCompetition(model: ICompetition) {
  return {
    id: model.id,
    title: model.name,
    description: model.shortName ? ` - ${model.shortName}` : '',
    img: model.image ? model.image :
      '/assets/img/no-image-icon-6.png'
  }
}

export function serializeCategory(model: ICategory) {
  if (model.type === 'post' && !model.isSpecial) {
    return {
      id: model.id,
      title: model.title
    }
  }
  return;
}

function text_truncate(str: string, length: number, ending: string = null) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};
