import Vue from "vue";
import Component from "vue-class-component";

@Component({
  template: require("./view.html"),
})
export class FilterForm extends Vue {
  public keywords: string = "";

  public handleSearchBtn() {
    this.$emit("handleSearch", this.keywords);
  }

  public handleResetBtn() {
    this.keywords = "";
    this.$emit("reset");
  }
}
