import Vue from 'vue';
import Component from 'vue-class-component';
import { IStickerCategories, StickerCreateFormDefault, StickerCreateForm, ISticker } from 'root/models';
import { StickerCategoriesActionType, StickerCategoriesGetterType } from '../../Store/types';
import { IState } from 'root/store';
import { mapState } from 'vuex';
import { Form } from 'element-ui';
import { ruleRequired } from 'root/helpers';
import { flatten, includes } from 'lodash';
import { isArray, isUndefined } from 'util';
import { getPublicLink, uploadImageS3 } from 'root/services/upload';
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      loading: (state: IState) => state.stickerCategories.detailLoading
    })
  }
})
export class StickerCategoriesDetail extends Vue {
  public $refs: {
    form: Form;
  };
  public isOpenAddSticker : boolean = false;
  public imageUploading: boolean = false;
  public uploading: boolean = false;
  public form: StickerCreateForm = StickerCreateFormDefault();
  public dialogVisibleDetail:boolean = false;
  public stickerDetail: StickerCreateForm = StickerCreateFormDefault();
  public get stickerID(): string {
    return this.$route.params.stickerID;
  }

  public get detail(): IStickerCategories {
    return this.$store
      .getters[StickerCategoriesGetterType.StickerCategories](this.stickerID);
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        StickerCategoriesActionType.StickerCategoriesGetById, {
          id: this.stickerID
        }
      );
    });
  }

  public handleDetailSticker(item) {
    this.dialogVisibleDetail = true;
    this.stickerDetail = item;
  }

  public handleClosePopupDetail() {
    this.dialogVisibleDetail = false;
  }

  public deleteSticker(value){
    const id = value.id;
    this.$confirm('This will permanently delete this sticker. Continue?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.confirmDelete(id);
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
  }

  public handleRemove() {
    this.form.url=''
  }

  public hanldeCancelPopupCreate(){
    this.isOpenAddSticker = false;
    this.form = {
      "stickerCategoryId": '',
      "hint": '',
      "url": '',
      "name": '',
    }
  }

  public confirmDelete(id) {
    this.$store.dispatch(StickerCategoriesActionType.StickerDelete, {
      id: id,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
        this.$store.dispatch(
          StickerCategoriesActionType.StickerCategoriesGetById, {
            id: this.stickerID
          }
        );
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public get rules() {
    return {
      required: error => {
        return ruleRequired(error);
      },
      'category': [
        ruleRequired('Please input category')
      ],
    };
  }

  public handlePreviewImage(file) {
    this.imageUploading = true;
    uploadImageS3('user/avatar/', file.raw).then(async (result: any) => {
      this.imageUploading = false;
      this.form.url = getPublicLink(result.key);
      this.$message({
        type: 'success',
        message: 'Upload successful'
      });
    }).catch((error) => {
      this.imageUploading = false;
      console.log(error);
      this.$message({
        type: 'error',
        message: 'Upload failed'
      });
    });
  }

  public async isValidForm() {
    const promises = flatten(Object.values(this.$refs).map((element: any) => {
      if (isArray(element)) {
        return (<any> element).map((form) => {
          return new Promise((resolve, _reject) => {
            form.validate((valid, result) => {
              resolve({ valid, result });
            });
          });
        });
      }
      return new Promise((resolve, _reject) => {
        element.validate((valid, result) => {
          resolve({ valid, result });
        });
      });
    }));

    return Promise.all(promises).then((arrayResolve) => {
      const errors = arrayResolve.map((resolve) => {
        if (isUndefined(resolve) || (<any> resolve).valid) {
          return;
        }
        this.$message({
          type: 'error',
          message: Object.values((<any> resolve).result)[0][0].message
        });
        return false;
      });
      return !includes(errors, false);
    });
  }

  public async handleSubmit() {
    const valid = await this.isValidForm();
    console.log('chay');
    
    if (!valid) {
      return;
    } 
    const body = {
      "stickerCategoryId": this.stickerID,
      "hint": this.form.hint,
      "url": this.form.url,
      "name": this.form.name,
    }
    this.uploading = true;
    this.$store.dispatch(StickerCategoriesActionType.StickerCreate, {
      form: body,
      onSuccess: () => {
        this.uploading = false;
        this.$message({
          type: 'success',
          message: 'Create success'
        });
        this.$store.dispatch(
          StickerCategoriesActionType.StickerCategoriesGetById, {
            id: this.stickerID
          }
        );
        this.isOpenAddSticker = false;
        this.form = {
          "stickerCategoryId": '',
          "hint": '',
          "url": '',
          "name": '',
        }
      },
      onFailure: () => {
        this.$message({
          type: 'error',
          message: 'Create failed'
        });
      }
    });
  }
}
