import { omit } from 'lodash';
import { IBadword } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
export type IBadwordHttpService = ICRUDHttpService<IBadword>;

export function BadwordHttpService(options: IHttpServiceClientOptions): IBadwordHttpService {

  const basePath = '/admin/badwords';

  return CRUDHttpService({
    ...options,
    basePath,
    serializeUpdate
  });
}

const serializeUpdate = (badword: IBadword): any => {

  const newBadword = omit(badword, ['id']);
  return {
    ...newBadword
  };
};
