export enum artworkType {
  Audio = 'audio',
  Video = 'video'
}
export enum musicType {
  Alternative = 'alternative',
  Anime = 'anime',
  Blues = 'blues',
  CPop = 'C-Pop',
  ChildrenMusic = 'childrenMusic',
  Classical = 'classical',
  Commercial = 'commercial',
  Country = 'country',
  ChristianGospel = 'christianGospel',
  Dance = 'dance',
  EasyListening = 'easyListening',
  Electronic = 'electronic',
  Enka = 'enka',
  FrenchPop = 'frenchPop'
}

export interface IArtworkCreateForm {
  brandName?: string;
  bio?: string;
  email: string;
  countryCode?: string;
  mobileNumber?: string;
  birthDay: Date;
  parentEmail?: string;
  parentMobileCountryCode?: string;
  parentMobileNumber?: string;
  country: string;
  city: string;
  school?: string;
  videoUrl?: string;
  audioUrl?: string;
  artworkType?: artworkType;
  thumbnailUrl: string;
  language: string;
  genre: musicType;
  subGenre?: musicType;
  facebookLink?: string;
  youtubeLink?: string;
}

export interface IArtwork extends IArtworkCreateForm {
  id: number;
  claimAccepted: boolean;
  totalNumberVote?: number;
  votesRank?: number;
  finalRank?: number;
}

export const ArtworkCreateDefaultForm = (): IArtworkCreateForm => {
  return {
    email: '',
    birthDay: undefined,
    city: '',
    country: '',
    genre: undefined,
    language: '',
    thumbnailUrl: ''
  };
};
