import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeWallet } from './types';
import { IWallet, WalletPackage, WalletList, walletTransactions, walletPaymentMethod} from 'root/models';
import { IPaginationState } from 'root/store';

export interface IWalletState extends ICRUDState<IWallet> {
  walletPackages: WalletPackage[];
  walletList: WalletList[];
  loading: boolean;
  pagination: null;
  walletTransactions: walletTransactions[];
  walletPaymentMethod: walletPaymentMethod[];
  walletPackage: WalletPackage,
}

export const defaultState: IWalletState = {
  ...CRUDState(),
  walletList: [],
  walletPackages: [],
  walletPackage: null,
  walletTransactions: [],
  walletPaymentMethod: [],
  loading: true,
  pagination: null,
};

export const mutations = {
  [MutationTypeWallet.SetPaymentPackages](state: IWalletState, payload) {
    state.walletPackages = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeWallet.SetPaymentList](state: IWalletState, payload) {
    state.walletList = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeWallet.SetPaymentMethods](state: IWalletState, payload) {
    state.walletPaymentMethod = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeWallet.SetPaymentTransactions](state: IWalletState, payload) {
    state.walletTransactions = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeWallet.SetPaymentPackage](state: IWalletState, payload) {
    state.walletPackage = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeWallet.SetLoading](state: IWalletState, payload) {
    state.loading = true;
  },
  [MutationTypeWallet.SetLoaded](state: IWalletState, payload) {
    state.loading = false;
  },
};
