import { IStickerCategories } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { StickerCategoriesActionType, STICKER_CATEGORIES_MODULE_NAME } from '../../Store/types';
import { computedPagination } from 'root/store/helpers';
import { startCase, camelCase } from 'lodash';
import { PaginationState } from 'root/store';
import draggable from 'vuedraggable';
import './view.scss';
import { RouterDictionaryStickerCategory } from '../..';

@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, STICKER_CATEGORIES_MODULE_NAME)
  },
  components: {
    'draggable': draggable
  },
  watch: {
    data() {
      if(this.data){
        this.stickers = this.data;
      }
    }
  }
})
export class StickerCategoriesList extends Vue {
  public data: IStickerCategories[];
  public stickers: IStickerCategories[] = [];
  public loading: boolean;
  public loadingDragging: boolean = false;
  public colsName: string[] = [
    'name', 'created by name', 'avatar', 'total download', 'total sticker', 'price', 'action'
  ];

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(StickerCategoriesActionType.StickerCategoriesFilter);
    });
  }

  public convertText(text: string) {
    return startCase(camelCase(text))
  }

  public getListSticker() {
    this.$store.dispatch(StickerCategoriesActionType.StickerCategoriesFilter, {
      params: {
        pagination: PaginationState()
      }
    });
  }

  public onChangeDrag(e){
    this.loadingDragging = true;
    const form = this.stickers.map((sticker, index) => ({id: sticker.id, new_position: index + 1}) )
    this.$store.dispatch(StickerCategoriesActionType.StickerCategoriesUpdate, form).then((res) => {
      this.getListSticker()
      this.loadingDragging = false;
      this.$message({
        type: 'success',
        message: 'Change position successful!'
      });
    }).catch((error) => {
      this.loadingDragging = false;
      this.$message({
        type: 'error',
        message: `${error.message}`
      });
    });
  }

  public handleDetail(id) {
    if(!id) return;
    this.$router.push(`${RouterDictionaryStickerCategory.StickerCategoriesList}/${id}`)
  }

  public deleteSticker(id){
    this.$confirm('This will permanently delete this sticker. Continue?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.confirmDelete(id);
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
  }

  public confirmDelete(id) {
    this.$store.dispatch(StickerCategoriesActionType.StickerCategoriesDelete, {
      id: id,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public handleEditBtn(row) {
    this.$router.push({
      path: `${RouterDictionaryStickerCategory.StickerCategoriesList}/update/${row.id}`
    });
  }

}
