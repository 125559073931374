import Vue from 'vue';
import Component from 'vue-class-component';

// components

// state
import { IState } from 'root/store';
import styles from 'root/styles';
import { mapState } from 'vuex';

import { SFRHeader, SFRNavigation } from 'root/components';
import './home.scss';

@Component({
  template: require('./view.html'),
  components: {
    'sfr-navigation': SFRNavigation,
    'sfr-header': SFRHeader
  },
  computed: {
    sidebarWidth: () => styles.sidebarWidth,
    ...mapState({
      drawer: (state: IState) => state.global.drawer,
      isLoading: (state: IState) => {
        return state.global.isLoading;
      },
      containerPaddingLeft: (state: IState, getters) => {
        return state.global.drawer && !getters.isMobile ? `${styles.sidebarWidth}px` : 0;
      },
      isCollapse: (state: IState) => state.global.menuIsCollapse
    }),
  }
})

export class HomeLayout extends Vue {

}
