import Vue from 'vue';
import Component from 'vue-class-component';
import { CategoryForm } from '../../Component/CategoryForm';
import { CategoryActionType } from '../../Store/types';
import { RouterDictionaryCategory } from '../..';
@Component({
  template: require('./view.html'),
  components: {
    'category-form': CategoryForm
  }
})

export class CategoryCreate extends Vue {
  public loading: boolean = false;

  public handleSubmit(form) {
    this.loading = true;
    this.$store.dispatch(CategoryActionType.CategoryCreate, {
      form,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Create successful'
        });
        this.$router.push({
          path: RouterDictionaryCategory.Category
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'warning',
          message: `${error.message}`
        });
      }
    });
  }
}
