import { sfrHttpClient } from 'root/api/http';
import { crudActions } from 'root/store/helpers';
import { CATEGORY_MODULE_NAME, CategoryActionType } from './types';
import { ActionContext } from "vuex";
import { ICategoryState } from '.';
import { IState } from 'root/store';

const {
  create,
  filter,
  getById,
  update,
  deleteById
} = sfrHttpClient.category;

export const actions = {
  ...crudActions(CATEGORY_MODULE_NAME, {
    create,
    filter,
    getById,
    update,
    deleteById
  }),
  async [CategoryActionType.UpdatePositionCategories](
    {}: ActionContext<ICategoryState, IState> , form): Promise<any> {
    return sfrHttpClient.category.updatePositionCategories(form);
  },
  async [CategoryActionType.GetDetailCategory](
    {}: ActionContext<ICategoryState, IState> , params): Promise<any> {
      return sfrHttpClient.category.getDetailCategory(params.id);
  }
};
