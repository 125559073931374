import { camelCase, startCase } from 'lodash';
import { PaginationState } from 'root/store';
import { computedPagination } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryMaintenance } from '../..';
import { MaintenanceActionType, MAINTENANCE_MODULE_NAME } from '../../Store/types';

@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, MAINTENANCE_MODULE_NAME)
  }
})

export class MaintenanceList extends Vue {

  public handleEditBtn(row) {
    this.$router.push({
      path: `${RouterDictionaryMaintenance.Maintenance}/update/${row.id}`
    });
  }

  public convertText(text: string) {
    return startCase(camelCase(text));
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(MaintenanceActionType.MaintenanceFilter, {
        params: {
          pagination: PaginationState()
        }
      });
    });
  }
}
