export const GIF_MODULE_NAME = 'gif';

export enum GifActionType {
  GifCreate = 'gifCreate',
  GifUpdate = 'gifUpdate',
  GifGetById = 'gifGetById',
  GifDelete = 'gifDelete',
  GifFetchMany = 'gifFetchMany',
  GifPaginationChange = 'gifPaginationChange',
  GifFilter = 'gifFilter'
}

export enum GifMutationType {
  GifLoading = 'gifLoading',
  GifLoaded = 'gifLoaded',
}

export enum GifGetterType {
  Gif = 'gif',
  Gifs = 'gifs',
}
