import { ITableItemAction } from 'root/components/Base';
import { CRUDAction } from 'root/models';
import { computedPagination } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { PromotionActionType, PROMOTION_MODULE_NAME } from '../../Store/types';
import { RouterDictionaryPromotion } from '../../index';

@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, PROMOTION_MODULE_NAME),
  }
})
export class PromotionList extends Vue {

  public formatDetailLink(id: string) {
    return `${RouterDictionaryPromotion.Promotion}/${id}`;
  }

  public handleItemCommand(command: ITableItemAction) {
    switch (command.type) {
    case CRUDAction.Update:
      this.$router.push(this.formatDetailLink(command.value));
      break;
    case CRUDAction.Delete:
      this.$store.dispatch(
        PromotionActionType.PromotionDelete, {
          id: command.value,

          onSuccess: () => {
            this.$message({
              type: 'success',
              message: 'Delete successful'
            });
          },
        });
      break;
    }
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(PromotionActionType.PromotionFilter);
    });
  }
}
