import axios from 'axios';

export function createUserSendbird(params: any) {
  try {
    const config = {
      headers: { 'Api-Token': `${process.env.API_TOKEN_SENDBIRD}` }
    };
    const userUrl = `https://api-${process.env.SENDBIRD_APPLICATION_ID}.sendbird.com/v3/users`;
    axios.post(userUrl, params, config).then((res) => {
      return res;
    });
  } catch (e) {
    return e;
  }
}
