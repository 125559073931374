import Vue from "vue";
import Component from "vue-class-component";
import "./styles.scss";
import { Step1 } from "../../Component/FormApplicant/Step1"
import { Step2 } from "../../Component/FormApplicant/Step2";
import { Form } from "element-ui";
import { ruleEmail,limit40Mention, ruleRequired, parseFileType,removeSpecialCharacter } from "root/helpers";
import { ActionType, IState, MutationType } from 'root/store';
import { cloneDeep, concat, isEmpty, isObject, omit } from 'lodash';
import { Storage } from 'aws-amplify';
import { getPublicLink, resizeImage, sizeImage } from 'root/services';

import {
  CompetitionActionType,
} from "../../Store/types";
import { stripObject } from "@hgiasac/helper";

import {
  IApplicant,ICompetition,  serializeApplicantCreateFormDefault, IApplicantDetail, CompetitionDefault, ApplicantDefault, IApplicantCreateForm,FileType,IAttachmentMetaData,IProfile,
  ApplicantCreateFormDefault,IAttachment } from 'root/models';
import { mapState } from 'vuex';

@Component({
  template: require("./view.html"),
  components: {
    "step-1": Step1,
    "step-2": Step2,
  },

  computed:{
    ...mapState({
      data: (state: IState) => state.competition.applicantDetail,
      competitionDetail: (state: IState) => state.competition.detail,
      authUser: (state: IState) => state.global.authUser,
    })
  },

  watch:{
    competitionDetail(){
      this.checkValidated();
    }
  }
})
export class FormApplicant extends Vue {
  public data: IApplicantDetail;
  public form: IApplicantCreateForm = ApplicantCreateFormDefault();
  public detailLoading: boolean = false;
  public loadingSubmit: boolean = false;
  public $refs: {
    form: Form;
  };
  public get rules() {
    return {
      required: ruleRequired("should.update.correctly"),
      email: ruleEmail("email.valid.signup")
    };
  }
  public get competitionId(): string {
    return this.$route.params.competitionId;
  }

  public get applicantId(): string {
    return this.$route.params.applicantId;
  }

  public loading: boolean = false;
  public isAgree: boolean = false;
  public competitionDetail: ICompetition;
  public step: number = 1;
  public authUser: IProfile;
  public audioThumbnail: File = null;
  public uploading: boolean = false;
  public cancel: boolean = false;
  public progressing: number = 0;
  public currentLanguage: string;
  public next() {
    this.$refs.form.validate(async valid => {
      window.scrollTo(0, 0);
      if (valid) {
        this.step++;
      }
    });
  }

  public get allowSubmitForm(): boolean {
    const form = cloneDeep(this.form);

    return !!(
      (form.title && form.title.trim()) || !isEmpty(form.attachments) || !isEmpty(form.externalLinkData)
    );
  }


  public handleIsAgree(value) {
    this.isAgree = value;
  }

  public prev() {
    if (this.step >= 2) this.step--;
  }

  public mounted() {
    document.documentElement.style.overflow = 'auto'
    this.fetchData();
  }

  public checkValidated(){
    // Validate on uploaded time
    if (this.competitionDetail.statusRound !== "Application"){
      this.$router.push('/competition');
      this.$message.error('Sorry! It is not time for applying to this competition');
      return;
    }
  }

  public async fetchData(){
    //check Competition Exist
    const id = this.competitionId
    this.detailLoading = true;
    if(this.applicantId){
      await this.$store.dispatch(CompetitionActionType.GetApplicantById, {
        competitionId: id,
        applicantId: this.applicantId,
        onFailure:(error) => {
          this.$store.dispatch(ActionType.CatchException, error);
          this.$router.push('/my-competition');
        }
      })
      this.form = await serializeApplicantCreateFormDefault(this.data);
    }


    this.$store.dispatch(CompetitionActionType.GetCompetitionDetail, { id,
      onSuccess: () => {
        return;
      },
      onFailure:() => {
        this.$router.push('/my-competition');
      }
    })
    this.detailLoading = false;
  }

  public get currentAttachmentType(): string {
    const attachments = <any>cloneDeep(this.form.attachments);

    if (attachments.length === 0) {
      // text only
      return "";
    }

    return parseFileType(
      attachments[0].type ? attachments[0].type : attachments[0].fileType
    );
  }

  public async handleUploadFile() {
    const _cloneAttachments = cloneDeep(this.form.attachments);
    const cloneAttachments = _cloneAttachments.filter((e) => {
      if (e.fileType === FileType.Audio) {
        return !e.thumbnailUrl;
      }

      if (e.fileType === FileType.Video) {
        return !e.fileUrl || (!e.customThumbnailUrl && this.audioThumbnail);
      }

      return !e.fileUrl ;
    });
    const attachmentNoNeedUpload = _cloneAttachments.filter((e) => {
      if (e.fileType === FileType.Audio) {
        return e.thumbnailUrl;
      }
      if (e.fileType === FileType.Video) {
        return e.fileUrl && !this.audioThumbnail;
      }
      return e.fileUrl;
    });
    console.log("------------ 1")
    if (isEmpty(cloneAttachments)) {
      return attachmentNoNeedUpload;
    }
    console.log("------------ 2")
    let fileIndex = 1;
    const totalFileNeedUpload = cloneAttachments.length;

    const resultAttachments: IAttachment[] = [];
    const attachmentsLength: number = cloneAttachments.length;

    const handleProgressing = async (file: File, isThumbnail?: boolean, process?: boolean) => {
      if (this.cancel === true) {
        return ;
      }
      try {
        this.progressing = Math.round((fileIndex - 1) / totalFileNeedUpload * 100);
        const fileName = removeSpecialCharacter(file.name);
        const result = <any> await Storage.put(
          `users/feed_${this.authUser.id}_${Date.now()}${isThumbnail ? '_thumbnail' : ''}_${fileName}`,
          file,
          {
            level: 'public',
            contentType: file.type,
            progressCallback: (progress: any) => {
              if (process) {
                let _progress = this.progressing
                + Math.round((progress.loaded / progress.total * 100) / totalFileNeedUpload);

                if (_progress > Math.round(fileIndex / totalFileNeedUpload * 100)) {
                  _progress = Math.round(fileIndex / totalFileNeedUpload * 100);
                }
                this.progressing = _progress;
              }

              return;
            }
          }
        );
        if (process) {
          fileIndex++;
        }
        return getPublicLink(result.key);
      } catch (error) {
        this.$store.dispatch(ActionType.CatchException, error);
      }
    };

    const handleUploadAttachments = (index: number, resolveProcess?: any) => {
      return new Promise(async (resolve, _reject) => {
        if (index < attachmentsLength) {
          const currentAtt = <any> cloneAttachments[index];
          let thumbnailUrl: any = '';
          let fileUrl: any = '';
          let metadata: IAttachmentMetaData = {
            width: 0,
            height: 0
          };
          let customThumbnailUrl: string = '';

          if (this.currentAttachmentType === FileType.Audio) {
            const _audioThumbnail = await resizeImage(this.audioThumbnail, {
              ...sizeImage.feed.thumbnail,
            });
            fileUrl = currentAtt.fileUrl ? currentAtt.fileUrl : await handleProgressing(currentAtt, false, true);
            thumbnailUrl = await handleProgressing(_audioThumbnail, true, false);
            metadata = _audioThumbnail.metadata;
          } else if (this.currentAttachmentType === FileType.Video) {
            if (currentAtt.thumbnail) {
              const _fileThumnail = await resizeImage(currentAtt.thumbnail, {
                ...sizeImage.feed.thumbnail,
              });
              thumbnailUrl = await handleProgressing(
                _fileThumnail,
                true,
                false
              );
              metadata = _fileThumnail.metadata;
            } else {
              thumbnailUrl = currentAtt.thumbnailUrl;
              metadata = currentAtt.metadata;
            }
            if (this.audioThumbnail) {
              const _customThumbnail = await resizeImage(this.audioThumbnail, {
                ...sizeImage.feed.thumbnail,
              });
              customThumbnailUrl = await handleProgressing(
                _customThumbnail,
                true,
                false
              );
            }
            fileUrl = currentAtt.fileUrl
              ? currentAtt.fileUrl
              : await handleProgressing(currentAtt, false, true);
          } else {
            const [fileOriginal, fileThumbnail] = await resizeImage(
              currentAtt,
              [
                { maxHeight: 2048, maxWidth: 2048 },
                { maxHeight: 680, maxWidth: 680 }
              ]
            );
            thumbnailUrl = await handleProgressing(fileThumbnail, true, false);
            fileUrl = await handleProgressing(fileOriginal, false, true);
            metadata = fileThumbnail.metadata;
          }
          console.log(metadata);
          

          resultAttachments.push({
            fileUrl,
            thumbnailUrl,
            metadata,
            customThumbnailUrl,
            fileName: currentAtt.name || currentAtt.fileName,
            fileSize: currentAtt.size || currentAtt.fileSize,
            fileType: currentAtt.fileType ? currentAtt.fileType : parseFileType(currentAtt.type)
          });

          return handleUploadAttachments(++index, resolveProcess || resolve);
        }

        return resolveProcess(resultAttachments);
      });
    };
    this.uploading = true;
    await handleUploadAttachments(0);

    return !this.cancel ? concat(attachmentNoNeedUpload, resultAttachments) : [];
  }

  public handleCancelUpload() {
    this.loading = false;
    this.progressing = 0;
    this.cancel = true;
    this.$refs.form.clearValidate();
  }

  public submit() {
    this.loadingSubmit = true;
    this.$refs.form.validate(async valid => {
      if (valid) {
        if (this.allowSubmitForm) {
          if (this.currentAttachmentType === FileType.Audio
            && (!this.audioThumbnail && !this.form.attachments[0].thumbnailUrl)) {
            const errorText = 'add a cover image to your post';
            this.$message.error(errorText);

            return;
          }
          const form = cloneDeep(this.form);
          if (limit40Mention(form.caption)) {
            this.$message.error(`You’ve reached the limit of 40 mentions per post`);
            return;
          }
          this.loading = true;
          // this.uploading = true;
          if (this.cancel) {
            this.cancel = false;
          }
          let attachments = await this.handleUploadFile();
          if (this.cancel === true) {
            attachments = [];

            return;
          }
          attachments = <any> attachments.map((e) => {
            return omit(e, ['uid', 'status', 'views']);
          });
          this.form.attachments = attachments;
          if (attachments.length > 0) {
            // post have attactment delete preview link
            form.externalLinkData = {};
          }
          try {
            this.applicantId ? this.updateApplicant() : this.createApplicant()
          } catch (error) {
            this.$store.dispatch(ActionType.CatchException, error);
          }
        }
      }
    });
    return;
  }

  public createApplicant(){
    this.$store.dispatch(CompetitionActionType.CreateApplicant, { competitionId: this.competitionId,
      form: stripObject(this.form),
      onSuccess: () => {
        this.progressing = 0;
        this.uploading = false;
        this.loading = false;
        this.$message({
          type: "success",
          message: "create successful"
        });
        this.$router.push('/competition');
      },
      onFailure: (error) => {
        this.$refs.form.clearValidate();
        this.loading = false;
        this.uploading = false;
        this.$store.dispatch(ActionType.CatchException, error);
      }
    });
  }

  public updateApplicant(){
    this.$store.dispatch(CompetitionActionType.UpdateApplicant, { competitionId: this.competitionId,
      feedId: this.data.id,
      form: stripObject(this.form),
      onSuccess: () => {
        this.progressing = 0;
        this.uploading = false;
        this.loading = false;
        this.$message({
          type: "success",
          message: this.$t("update.successful").toString()
        });
        this.step++;
      },
      onFailure: (error) => {
        this.$refs.form.clearValidate();
        this.loading = false;
        this.uploading = false;
        this.$store.dispatch(ActionType.CatchException, error);
      }
    });
  }

  public handleAddThumbnail(thumbnail) {
    this.audioThumbnail = thumbnail;
  }
}
