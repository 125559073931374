import Vue from 'vue';
import Component from 'vue-class-component';
import * as _ from 'lodash';
import { GifForm } from '../../Component/GifForm';
import { mapState } from 'vuex';
import { IState } from 'root/store';
import { GifCategoryActionType } from '../../Store/types';
import { IGifCategory } from 'root/models';
import { GifCategoryGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    GifForm
  },
  computed: {
    ...mapState({
      loading: (state: IState) => state.gifCategory.detailLoading,
    })
  },
})
export class GifCategoryUpdate extends Vue {
  public loading: boolean;
  public get gifID(): string {
    return this.$route.params.gifID;
  }

  public get detail(): IGifCategory {
    return this.$store.getters[GifCategoryGetterType.GifCategory](this.gifID);
  }
  mounted() {    
    this.$nextTick(() => { 
      this.$store.dispatch(GifCategoryActionType.GifCategoryGetById, {
        id: this.$route.params.gifID
      });
    });
  }
}
