export const REPORTCATEGORY_MODULE_NAME = 'reportCategory';

export enum ReportCategoryActionType {
  ReportCategoryCreate = 'reportCategoryCreate',
  ReportCategoryUpdate = 'reportCategoryUpdate',
  ReportCategoryFilter = 'reportCategoryFilter',
  ReportCategoryGetById = 'reportCategoryGetById',
  ReportCategoryDelete = 'reportCategoryDelete',
}

export enum ReportCategoryMutationType {
  ReportCategoryLoading = 'reportCategoryLoading',
  ReportCategoryLoaded = 'reportCategoryLoaded',
  ReportCategoryRefreshModels = 'reportCategoryRefreshModels',
  ReportCategoryUpdateModels = 'reportCategoryUpdateModels',
  ReportCategoryFilterModels = 'reportCategoryFilterModels',
}

export enum ReportCategoryGetterType {
  ReportCategory = 'reportCategory',
  ReportCategorys = 'reportCategorys',
  ReportCategoryFilterResults = 'reportCategoryFilterResults'
}
