import { IReportPost } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
export type IReportComprtitionHttpService = ICRUDHttpService<IReportPost>;

export function ReportCompetitionHttpService(options: IHttpServiceClientOptions): IReportComprtitionHttpService {

  const basePath = '/admin/report-competitions';

  return CRUDHttpService({
    ...options,
    basePath
  });
}
