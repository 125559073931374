import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryFeed } from '..';
import { PanelGroup } from './Component/PanelGroup';
import './view.scss';

@Component({
  template: require('./view.html'),
  components: {
    'panel-group': PanelGroup
  }
})

export class DashboardContainer extends Vue {

  public page: string = 'dashboard';

  public beforeCreate() {
    this.$router.replace(RouterDictionaryFeed.Feed);
  }

}
