export const REPORTCOMPETITION_MODULE_NAME = 'reportCompetition';

export enum ReportCompetitionActionType {
  ReportCompetitionFilter = 'reportCompetitionFilter',
  ReportCompetitionGetById = 'reportCompetitionGetById',
  ReportCompetitionPaginationChange = 'reportCompetitionPaginationChange'
}

export enum ReportCompetitionMutationType {
  ReportCompetitionLoading = 'reportCompetitionLoading',
  ReportCompetitionLoaded = 'reportCompetitionLoaded',
  ReportCompetitionRefreshModels = 'reportCompetitionRefreshModels',
  ReportCompetitionFilterModels = 'reportCompetitionFilterModels',
}

export enum ReportCompetitionGetterType {
  ReportCompetition = 'reportCompetition',
  ReportCompetitions = 'reportCompetitions',
  ReportCompetitionFilterResults = 'reportCompetitionFilterResults'
}
