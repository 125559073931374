import { IBadword } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { BADWORD_MODULE_NAME } from './types';

export type IBadwordState = ICRUDState<IBadword>;

export const defaultState: IBadwordState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(BADWORD_MODULE_NAME),
};
