import { IPromotion } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export type IPromotionHttpService = ICRUDHttpService<IPromotion>;

export function PromotionHttpService(options: IHttpServiceClientOptions): IPromotionHttpService {

  const basePath = '/admin/global-promotions';

  return CRUDHttpService({
    ...options,
    basePath,
  });
}
