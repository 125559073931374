export const WALLET_LIST_MODULE = "walletList";

export enum ActionTypeWallet {
  GetPaymentPackages = 'getPaymentPackages',
  GetPaymentList = 'getPaymentList',
  GetPaymentTransactions = 'getPaymentTransactions',
  GetPaymentPayments = 'getPaymentPayments',
  GetPaymentMethods = 'getPaymentMethods',
  GetPaymentPackageById ='getPaymentPackageById',
  PaymentPackageCreate = 'paymentPackageCreate',
  PaymentPackageUpdate = 'paymentPackageUpdate',
  TransactionNoteUpdate = 'transactionNoteUpdate',
}

export enum MutationTypeWallet {
  SetPaymentPackages = "setPaymentPackages",
  SetPaymentList = "setPaymentList",
  SetPaymentTransactions = "setPaymentTransactions",
  SetPaymentMethods = "setPaymentMethods",
  SetLoading = "setLoading",
  SetLoaded = "setLoaded",
  SetPaymentPackage = "setPaymentPackage",
}
