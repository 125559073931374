import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './view.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      loading: (state: IState) => state.profile.loading
    })
  }
})
export class MyProfile extends Vue {
  public get detail() {
    return this.$store.state.global.authUser;
  }

  public handleLogout() {
    console.log('=====handleLogout');
    this.$store.dispatch(ActionType.Unauthenticated);
  }

  public mounted() {
    this.$nextTick(() => {

      return;
    });
  }
}
