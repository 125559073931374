export interface IWallet {
  data: any;
}
export interface WalletPackage extends WalletPackageForm {
  id: string,
  txnNote?: string, 
  updatedAt: Date,
}

export const WalletPackageFormDefault = (): WalletPackageForm => {
  return {
    packageType: '',
    amount: 0,
    chargeAmount: 0,
    status: walletStatusCode.Deleted,
  }
}
export const TransactionNoteFormDefault = (): TransactionNoteForm => {
  return {
    txnNote: '',
  }
}

export interface WalletPackageForm {
  packageType: string,
  amount: number,
  chargeAmount: number,
  status: walletStatusCode,
}
export interface TransactionNoteForm {
  txnNote: string,
}

export enum walletStatusCode {
  Active = "active",
  Deleted = "deleted"
}

export interface WalletList {
  id: string,
  status: string,
  balance: number,
  txnNote: string,
  chargeAmount: number
  createdAt: Date,
  updatedAt: Date,
  owner: {
    _id: string,
    username: string,
    email: string,
    avatar: string,
    firstName: string,
    lastName: string,
  }
}

export interface walletTransactions {
  id: string,
  description: string,
  subDescription: string,
  amount: Number,
  txnType: string,
  txnStatus: string,
  chargeAmount: Number,
  owner: {
    avatar: string,
    email: string,
    username: string,
    _id: string,
  }
}


export interface walletPaymentMethod {
  id: string,
  creditCard: {
    stripeCustomerId: string,
    paymentMethodId: string,
    creditCardHolderName: string,
    creditCardExpiryMonth: Number,
    creditCardExpiryYear: Number,
    creditCardFirst6: string,
    creditCardLast4: string,
    cardType: string,
    metadata: {
      id: string,
      object: string,
      cancellation_reason: Object,
      client_secret: string,
      created: Number,
      description: Object,
      last_setup_error: Object,
      livemode: Boolean,
      next_action: Object,
        payment_method: string,
        payment_method_types: string[],
        status: string,
        usage: string
          }
        },
        paymentType: string,
        owner: {
        _id: string,
        username: string,
        email: string,
        avatar: string,
        firstName: string,
        lastName: string
      },
    createdAt: Date,
    updatedAt: Date
}





