import { Form } from 'element-ui';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { UserForm } from '../../Component/UserForm';
import { UserActionType, UserGetterType } from '../../Store/types';
import { RouterDictionaryUser } from '../../index';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.user.detailLoading
    })
  },
  components: {
    'user-form': UserForm
  }
})
export class UserUpdate extends Vue {
  public $refs: {
    form: Form
  };

  public uploading: boolean = false;

  public get userId() {
    return this.$route.params.userId;
  }

  public get detail() {
    return this.$store.getters[UserGetterType.User](this.userId);
  }

  public handleSubmit(form) {
    console.log('handleSubmit');
    this.uploading = true;
    this.$store.dispatch(UserActionType.UserUpdate, {
      form,
      id: this.userId,
      onSuccess: () => {
        this.uploading = false;
        this.$message({
          type: 'success',
          message: 'Update success'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: error.message
        });
      }
    });
  }

  public handleDelete() {
    this.$store.dispatch(UserActionType.UserDelete, {
      id: this.userId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
        this.$router.push({
          path: RouterDictionaryUser.User
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(UserActionType.UserGetById, {
        id: this.userId
      });
    });
  }
}
