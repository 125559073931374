import { IPromotion } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { PROMOTION_MODULE_NAME } from './types';

export type IPromotionState = ICRUDState<IPromotion>;

export const defaultState: IPromotionState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(PROMOTION_MODULE_NAME),
};
