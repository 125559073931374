export const TEMPLATEEMAIL_MODULE_NAME = 'templateEmail';

export enum TemplateEmailActionType {
  TemplateEmailCreate = 'templateEmailCreate',
  TemplateEmailUpdate = 'templateEmailUpdate',
  TemplateEmailFilter = 'templateEmailFilter',
  TemplateEmailGetById = 'templateEmailGetById',
  TemplateEmailDelete = 'templateEmailDelete',
  TemplateEmailPaginationChange = 'templateEmailPaginationChange'
}

export enum TemplateEmailMutationType {
  TemplateEmailLoading = 'templateEmailLoading',
  TemplateEmailLoaded = 'templateEmailLoaded',
  TemplateEmailRefreshModels = 'templateEmailRefreshModels',
  TemplateEmailUpdateModels = 'templateEmailUpdateModels',
  TemplateEmailFilterModels = 'templateEmailFilterModels',
}

export enum TemplateEmailGetterType {
  TemplateEmail = 'templateEmail',
  TemplateEmails = 'templateEmails',
  TemplateEmailFilterResults = 'templateEmailFilterResults'
}
