import { isEmpty, includes } from 'lodash';
import { formatTimeDuration } from 'root/helpers/formatter';
import { IFeed, IBanner } from 'root/models';
import { RouterDictionaryUser } from 'root/pages';
import { IState } from 'root/store';
import { computedPagination } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { FeedSearchForm } from '../../Component/SearchForm';
import { FeedActionType, FEED_MODULE_NAME } from '../../Store/types';
import { RouterDictionaryFeed } from '../../index';
import './view.scss';
import { sfrHttpClient } from 'root/api/http';
import { BannerActionType } from 'root/pages/Banner/Store/types';
import { mapState } from 'vuex';

@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, FEED_MODULE_NAME),
    ...mapState({
      banners: (state: IState) => state.banner.data
    })
  },
  components: {
    'feed-search-form': FeedSearchForm
  },
  watch: {
    banners() {
      if(this.banners && this.banners.length > 0) {
        this.bannerReferenceIds = this.banners.filter((banner: IBanner) => banner.contentType === 'post').map((banner: IBanner) => banner.referenceId);
      }
    }
  }
})
export class FeedList extends Vue {

  public banners: IBanner[];
  public bannerReferenceIds: string[] = [];
  public loadingSetBanner: boolean = false;

  public formatTimeDuration(time) {
    return formatTimeDuration(time);
  }

  public getType(row: IFeed) {
    if (isEmpty(row.attachments)) {
      return '';
    }

    return row.attachments[0].fileType;
  }

  public getUserLink(id) {
    return `${RouterDictionaryUser.User}/update/${id}`;
  }

  public handleDetail(row) {
    console.log(row);
    this.$router.push({
      path: `${RouterDictionaryFeed.Feed}/${row.id}`
    });
  }

  public handleUpdate(row) {
    console.log(row);
    this.$router.push({
      path: `${RouterDictionaryFeed.Feed}/update/${row.id}`
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(FeedActionType.FeedFilter);
      this.$store.dispatch(BannerActionType.BannerFilter);
    });
  }

  public visibleSetBanner(id: string) {
    return includes(this.bannerReferenceIds, id)
  }

  public async handleSetBannerFeed(id: string){
    this.loadingSetBanner = true;
    await sfrHttpClient.feed.setBannerFeed(id).then((res) => {
      this.bannerReferenceIds = [...this.bannerReferenceIds, id]
      this.loadingSetBanner = false;
      this.$message({
        type: 'success',
        message: 'Set banner success!'
      });
    }).catch( (error) => {
      this.loadingSetBanner = false;
      this.$message({
        type: 'error',
        message: error.message
      });
    });
  }
}
