import { Form } from 'element-ui';
import { IFeed } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { FeedForm } from '../../Component/FeedForm';
import { FeedActionType, FeedGetterType } from '../../Store/types';
import * as _ from 'lodash';

@Component({
  template: require('./view.html'),
  props: {
    referenceId: String
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.feed.detailLoading
    })
  },
  components: {
    'feed-form': FeedForm
  },
  beforeRouteUpdate(_to, _form, next) {
    this.detailRefeshed = false;
    next();
  },
})
export class FeedUpdate extends Vue {
  public $refs: {
    form: Form
  };

  public uploading: boolean = false;
  public referenceId: string;

  private detailRefeshed: boolean = false;

  public get feedId() {
    return this.referenceId || this.$route.params.feedId;
  }

  public get detail(): IFeed {
    return this.$store.getters[FeedGetterType.Feed](this.feedId);
  }

  public handleSubmit(form) {
    this.uploading = true;
    let _form = _(form).omitBy(_.isUndefined).omitBy(_.isNull).value();
    this.$store.dispatch(FeedActionType.FeedUpdate, {
      form: _form,
      id: this.feedId,
      onSuccess: () => {
        this.uploading = false;
        this.$message({
          type: 'success',
          message: 'Update success'
        });
        this.$emit('update');
      },
      onFailure: () => {
        this.$message({
          type: 'error',
          message: 'Update failed'
        });
      }
    });
  }

  public handleDelete() {
    this.uploading = true;
    this.$store.dispatch(FeedActionType.FeedDelete, {
      id: this.feedId,
      onSuccess: () => {
        this.uploading = false;
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
        this.$emit('update');
      },
      onFailure: (error) => {
        this.uploading = false;
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public handleDeleteComment(commentId) {
    this.uploading = true;
    this.$store.dispatch(FeedActionType.FeedDeleteComment, {commentId, feedId: this.feedId}).then(() => {
      this.uploading = false;
      this.$message({
        type: 'success',
        message: 'Delete successful'
      });
      this.$store.dispatch(FeedActionType.FeedGetById, {
        id: this.feedId
      });
    }).catch((error) => {
      this.uploading = false;
      this.$message({
        type: 'error',
        message: `${error.message}`
      });
    });
  }

  public mounted() {
    this.detailRefeshed = true;
    this.$nextTick(() => {
      this.$store.dispatch(FeedActionType.FeedGetById, {
        id: this.feedId
      });
    });
  }

  public updated() {
    if (this.detailRefeshed) {
      return;
    }
    this.detailRefeshed = true;
    this.$store.dispatch(FeedActionType.FeedGetById, {
      id: this.feedId
    });
  }

}
