export interface IEventCreateForm {
  title: string;
  location?: string;
  ticketingSite?: string;
  body: string;
  dateStart: Date;
  endDate: Date;
}

export interface IEvent extends IEventCreateForm {
  id: number;
}
export const EventCreateDefaultForm = (): IEventCreateForm => {
  return {
    title: '',
    body: '',
    dateStart: undefined,
    endDate: undefined
  };
};
