import { isEmpty } from "lodash";
import { PaginationState } from "root/store";
import Vue from "vue";
import Component from "vue-class-component";
import {
  CompetitionActionType,
  COMPETITION_MODULE_NAME,
} from "../../Store/types";

@Component({
  props: {
    sorting: {
      type: String,
      default: "",
    },
  },
  template: require("./view.html"),
})
export class FilterForm extends Vue {
  public selectedStatus: string = "";
  public keywords: string = "";
  public type: object[] = [
    { value: "draft", label: "Draft" },
    { value: "pending", label: "Pending" },
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
    { value: "rejected", label: "Rejected" },
    { value: "finished", label: "Finished" },
  ];
  public size: number = 20;

  public get filterParams() {
    const statusParams: string = !isEmpty(this.selectedStatus)
      ? this.selectedStatus
      : "";
    return {
      type: statusParams,
    };
  }

  public handleSearchBtn() {
    const dataFilter = {
      type: this.filterParams.type,
      keywords: this.keywords,
    };
    this.$store.dispatch(CompetitionActionType.CompetitionFilter, {
      params: {
        filterParams: dataFilter,
        pagination: PaginationState({
          size: this.size,
        }),
      },
    });
  }

  public handleResetBtn() {
    this.selectedStatus = "";
    this.keywords = "";
    const dataFilter = {
      type: this.filterParams.type,
      keywords: this.keywords,
    };
    this.$store.commit(`${COMPETITION_MODULE_NAME}FilterChange`, dataFilter);
    this.$emit("reset");
  }
}
