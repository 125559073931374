import Vue from 'vue';
import Component from 'vue-class-component';
import { FeedForm } from '../../Component/FeedForm';
import { FeedActionType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'feed-form': FeedForm
  }
})
export class FeedCreate extends Vue {

  public uploading: boolean = false;

  public handleSubmit(form) {
    this.uploading = true;
    this.$store.dispatch(FeedActionType.FeedCreate, {
      form,
      onSuccess: () => {
        this.uploading = false;
        this.$message({
          type: 'success',
          message: 'Create success'
        });
      },
      onFailure: () => {
        this.$message({
          type: 'error',
          message: 'Create failed'
        });
      }
    });
  }

}
