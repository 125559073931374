import { INotification } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { NOTIFICATION_MODULE_NAME } from './types';

export type INotificationState = ICRUDState<INotification>;

export const defaultState: INotificationState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(NOTIFICATION_MODULE_NAME),
};
