export const PROMOTION_MODULE_NAME = 'promotion';

export enum PromotionActionType {
  PromotionCreate = 'promotionCreate',
  PromotionUpdate = 'promotionUpdate',
  PromotionFilter = 'promotionFilter',
  PromotionGetById = 'promotionGetById',
  PromotionDelete = 'promotionDelete',
}

export enum PromotionMutationType {
  PromotionLoading = 'promotionLoading',
  PromotionLoaded = 'promotionLoaded',
  PromotionRefreshModels = 'promotionRefreshModels',
  PromotionUpdateModels = 'promotionUpdateModels',
  PromotionFilterModels = 'promotionFilterModels',
}

export enum PromotionGetterType {
  Promotion = 'promotion',
  Promotions = 'promotions',
  PromotionFilterResults = 'promotionFilterResults'
}
