import moment from 'moment';
import { ICompetitionCreateForm } from 'root/models';

export function generateTimeLineCompetition(competition: ICompetitionCreateForm): string[] {
  const dataTimeline = []
  if (competition.round.length > 0) {
    competition.round.map((data, index) => {
      if (index == 0) {
        dataTimeline.push(`Registration: ${formatDateTimeline(data.registrationPeriod)} - ${formatDateTimeline(data.endRegistrationPeriod)}`)
        dataTimeline.push(`Voting: ${formatDateTimeline(data.votingPeriod)} - ${formatDateTimeline(data.endVotingPeriod)}`)
        if (competition.round.length > 1) {
          if (competition.round[1].isReUpload) {
            dataTimeline.push(`${competition.round[1].roundName} Announcement: ${formatDateTimeline(data.announcement)} - ${formatDateTimeline(competition.round[1].reUploadDate)}`)
          } else {
            dataTimeline.push(`${competition.round[1].roundName} Announcement: ${formatDateTimeline(data.announcement)} - ${formatDateTimeline(competition.round[1].votingPeriod)}`)
          }
        } else {
          dataTimeline.push(`Result Announcement: ${formatDateTimeline(data.announcement)} - ${formatDateTimeline(data.announcement)}`)
        }
      } else {
        if (data.isReUpload) {
          dataTimeline.push(`${competition.round[1].roundName} Reupload: ${formatDateTimeline(data.reUploadDate)} - ${formatDateTimeline(data.votingPeriod)}`)
          dataTimeline.push(`${competition.round[1].roundName} Voting: ${formatDateTimeline(data.votingPeriod)} - ${formatDateTimeline(data.endVotingPeriod)}`)
        } else {
          dataTimeline.push(`${competition.round[1].roundName} Voting: ${formatDateTimeline(data.votingPeriod)} - ${formatDateTimeline(data.endVotingPeriod)}`)
        }
        if (index == competition.round.length - 1) {
          dataTimeline.push(`Result Announcement: ${formatDateTimeline(data.announcement)} - ${formatDateTimeline(data.announcement)}`)
        } else {
          if (competition.round[index + 1].isReUpload) {
            dataTimeline.push(`${competition.round[index + 1].roundName} Announcement: ${formatDateTimeline(data.announcement)} - ${formatDateTimeline(competition.round[index + 1].reUploadDate)}`)
          } else {
            dataTimeline.push(`${competition.round[index + 1].roundName} Announcement: ${formatDateTimeline(data.announcement)} - ${formatDateTimeline(competition.round[index + 1].votingPeriod)}`)
          }
        }

      }
    })
  }
  return dataTimeline
}

function formatDateTimeline(date){
  return moment(date).format("L HH:mm");
};
