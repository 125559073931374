export const MAINTENANCE_MODULE_NAME = 'maintenance';

export enum MaintenanceActionType {
  MaintenanceCreate = 'maintenanceCreate',
  MaintenanceUpdate = 'maintenanceUpdate',
  MaintenanceFilter = 'maintenanceFilter',
  MaintenanceGetById = 'maintenanceGetById',
  MaintenanceDelete = 'maintenanceDelete',
  MaintenancePaginationChange = 'maintenancePaginationChange'
}

export enum MaintenanceMutationType {
  MaintenanceLoading = 'maintenanceLoading',
  MaintenanceLoaded = 'maintenanceLoaded',
  MaintenanceRefreshModels = 'maintenanceRefreshModels',
  MaintenanceUpdateModels = 'maintenanceUpdateModels',
  MaintenanceFilterModels = 'maintenanceFilterModels',
}

export enum MaintenanceGetterType {
  Maintenance = 'maintenance',
  Maintenances = 'maintenances',
  MaintenanceFilterResults = 'maintenanceFilterResults'
}
