import { Form, Input } from 'element-ui';
import { camelCase, cloneDeep, flatten, includes, startCase } from 'lodash';
import { IBanner, IBannerCreateForm, BannerCreateDefaultForm, IExternalLinkData } from 'root/models';
import { isArray, isUndefined } from 'util';
import { getUrlInText, verifiedImage, ruleRequired } from 'root/helpers';
import { sfrHttpClient } from 'root/api/http';
import { LinkPreviewCard } from 'root/components/LinkPreviewCard';
import { getPublicLink, uploadImageS3 } from 'root/services/upload';
import Vue from 'vue';
import Component from 'vue-class-component';
import './view.scss';

@Component({
  template: require('./view.html'),
  components: {
    LinkPreviewCard
  },
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean
  },
  watch: {
    data() {
      this.dataChange();
    }
  },
})
export class BannerForm extends Vue {
  public $refs: {
    form: Form;
  };
  public form: IBannerCreateForm = BannerCreateDefaultForm();
  public data: IBanner;
  public loading: boolean;
  public loadingURLPreview: boolean = false;
  public urlNeedPreview: string = '';
  public imageUploading: boolean = false;
  public get rules() {
    return {
      ['externalLinkData.link']: [
        ruleRequired('Please input link')
      ]
    };
  }

  public convertText(text: string) {
    return startCase(camelCase(text))
  }

  public async isValidForm() {
    const promises = flatten(Object.values(this.$refs).map((element: any) => {
      if (isArray(element)) {
        return (<any> element).map((form) => {
          return new Promise((resolve, _reject) => {
            form.validate((valid, result) => {
              resolve({ valid, result });
            });
          });
        });
      }

      return new Promise((resolve, _reject) => {
        element.validate((valid, result) => {
          resolve({ valid, result });
        });
      });
    }));

    return Promise.all(promises).then((arrayResolve) => {
      const errors = arrayResolve.map((resolve) => {
        if (isUndefined(resolve) || (<any> resolve).valid) {
          return;
        }
        this.$message({
          type: 'error',
          message: Object.values((<any> resolve).result)[0][0].message
        });

        return false;
      });

      return !includes(errors, false);
    });
  }

  public async handleSubmit() {
    const valid = await this.isValidForm();
    if (!valid) {
      return;
    }
    if (!this.form.externalLinkData.thumbnailUrl) {
      this.$message({
        type: 'error',
        message: 'Please input correct link and press generate link!'
      });
      return;
    }
    this.$emit('submit', this.form);

  }

  public mounted() {
    this.dataChange();
  }

  public dataChange() {
    if (!this.data) {
      return;
    }
    this.urlNeedPreview = this.data.externalLinkData.link;
    this.form = { ...this.data };
  }

  public handlePaste(e: any) {
    setTimeout(async () => {
      await this.handleChangeTitle();
    }, 0);
  }

  public async handleChangeTitle() {
    const value = this.urlNeedPreview;
    if (value && value.trim() && getUrlInText(value)) {
      this.loadingURLPreview = true;
      this.urlNeedPreview = getUrlInText(value);
      await sfrHttpClient.global.getLinkContent(this.urlNeedPreview).then((res) => {
        setTimeout(async () => {
          this.form.externalLinkData = await this.setExternalLinkData(res);
        },0);
        this.loadingURLPreview = false;
      }).catch((error) => {
        this.loadingURLPreview = false;
        this.$message({
          type: 'error',
          message: 'Please input correct link!'
        });
        console.log("error: ", error)
      });
    } else if (value === ''){
      this.form.externalLinkData.thumbnailUrl = ''
    } else {
      this.$message({
        type: 'error',
        message: 'Please input correct link!'
      });
    }

    return;
  }

  public async setExternalLinkData(form: IExternalLinkData) {
    const _form = cloneDeep(form),
      {thumbnailUrl} = _form;

    try {
      const verifiedThumbnail = await verifiedImage(thumbnailUrl);

      if (verifiedThumbnail) {
        _form.thumbnailUrl = thumbnailUrl;
      }

      return _form;
    } catch (error) {
      _form.thumbnailUrl = '';

      return _form;
    }
  }

  public handlePreviewImage(file) {
    this.imageUploading = true;
    uploadImageS3('banner/', file.raw).then(async (result: any) => {
      this.form.externalLinkData.thumbnailUrl = getPublicLink(result.key);
      this.imageUploading = false;
      this.$message({
        type: 'success',
        message: 'Upload successful'
      });
      this.$emit('submit', this.form);
    }).catch((error) => {
      this.imageUploading = false;
      console.log(error);
      this.$message({
        type: 'error',
        message: 'Upload failed'
      });
    });
  }

}
