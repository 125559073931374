import { StickerCategories } from './Container';
import { StickerCategoriesCreate } from './Container/Create';
import { StickerCategoriesDetail } from './Container/Detail';
import { StickerCategoriesList } from './Container/List';
import { StickerCategoriesUpdate } from './Container/Update';

export enum RouterDictionaryStickerCategory {
  StickerCategories = '/sticker-categories',
  StickerCategoriesList = '/sticker-categories',
  StickerCategoriesCreate = '/sticker-categories/create',
  StickerCategoriesDetail = '/sticker-categories/:stickerID',
  StickerCategoriesUpdate = '/sticker-categories/update/:stickerID',
}

export const StickerCategoryRoutes = {
  path: RouterDictionaryStickerCategory.StickerCategoriesList,
  component: StickerCategories,
  children: [
    {
      path: '',
      component: StickerCategoriesList,
      name: 'Sticker Categories List',
    },
    {
      path: RouterDictionaryStickerCategory.StickerCategoriesCreate,
      component: StickerCategoriesCreate,
      name: 'Sticker Categories Create'
    },
    {
      path: RouterDictionaryStickerCategory.StickerCategoriesDetail,
      component: StickerCategoriesDetail,
      name: 'Sticker Categories Detail'
    },
    {
      path: RouterDictionaryStickerCategory.StickerCategoriesUpdate,
      component: StickerCategoriesUpdate,
      name: 'Sticker Categories Update'
    }
  ]
};
