export function getUrlInText(text: string): string {
  // tslint:disable
  const pattern = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  // tslint:enable

  if (!text.match(pattern)) {
    return '';
  }

  let link = text.match(pattern)[0];
  if (!link.match('^https?:\/\/')) {
    link = 'https://' + link;
  }

  return link;
}

export function verifiedImage(url: string, timeoutT: number = 5000) {
  return new Promise((resolve, reject) => {
    let timer: any = null;
    const img = new Image();
    img.onerror = img.onabort = () => {
      clearTimeout(timer);
      reject(false);
    };
    img.onload = () => {
      clearTimeout(timer);
      resolve(true);
    };
    timer = setTimeout(() => {
      // reset .src to invalid URL so it stops previous
      // loading, but doens't trigger new load
      // img.src = '//!!!!/noexist.jpg';
      reject(false);
    }, timeoutT);
    img.src = url;
  });
}

export function extractHostname(url: string) {
  let hostname: string;
  // find & remove protocol (http, ftp, etc.) and get hostname

  hostname = url.indexOf('//') > -1 ? url.split('/')[2] : url.split('/')[0];

  // find & remove port number
  hostname = hostname.split(':')[0];
  // find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}

export function extractRootDomain(url: string) {
  let domain = extractHostname(url);

  const splitArr = domain.split('.'),
    arrLen = splitArr.length;

  // extracting the root domain here
  // if there is a subdomain
  if (arrLen > 2) {
    domain = `${splitArr[arrLen - 2]}.${splitArr[arrLen - 1]}`;
      // check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (splitArr[arrLen - 2].length === 2 && splitArr[arrLen - 1].length === 2) {
          // this is using a ccTLD
      domain = `${splitArr[arrLen - 3]}.${domain}`;
    }
  }

  return domain;
}
