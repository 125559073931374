import { Banner } from './Container';
import { BannerCreate } from './Container/Create';
import { BannerList } from './Container/List';
import { BannerUpdate } from './Container/Update';

export enum RouterDictionaryBanner {
  Banner = '/banner',
  BannerCreate = '/banner/create',
  BannerUpdate = '/banner/update/:bannerId'
}

export const BannerRoutes = {
  path: RouterDictionaryBanner.Banner,
  component: Banner,
  children: [
    {
      path: '',
      component: BannerList,
      name: 'Banner List',
    },
    {
      path: RouterDictionaryBanner.BannerCreate,
      component: BannerCreate,
      name: 'Banner Create'
    },
    {
      path: RouterDictionaryBanner.BannerUpdate,
      component: BannerUpdate,
      name: 'Banner Update'
    }
  ]
};
