import { omit, includes } from 'lodash';
import { sfrHttpClient } from 'root/api/http';
import { Export2Excel } from 'root/components/Export2Excel';
import { formatTimeString } from 'root/helpers';
import { IUserCreateForm, IBanner } from 'root/models';
import { IState, PaginationState } from 'root/store';
import { computedPagination } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { SearchForm } from '../../Component/SearchForm';
import { UserActionType, USER_MODULE_NAME } from '../../Store/types';
import { BannerActionType } from 'root/pages/Banner/Store/types';
import { RouterDictionaryUser } from '../../index';
import './view.scss';
@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, USER_MODULE_NAME),
    ...mapState({
      filterParams: (state: IState) => state.user.filterParams,
      banners: (state: IState) => state.banner.data
    })
  },
  components: {
    'search-form': SearchForm,
    'export-excel': Export2Excel
  },
  watch: {
    banners() {
      if(this.banners && this.banners.length > 0) {
        this.bannerReferenceIds = this.banners.filter((banner: IBanner) => banner.contentType === 'user').map((banner: IBanner) => banner.referenceId);
      }
    }
  }
})
export class UserList extends Vue {
  public $refs: {
    search: any,
    table: any
  };

  public banners: IBanner[];
  public bannerReferenceIds: string[] = [];
  public loadingSetBanner: boolean = false;

  public downloadLoading: boolean = false;
  public dialogExportVisible: boolean = false;
  public fileExportName: string = '';
  public data: any;
  public pagination: any;

  public filterParams: any;
  public sortOrders: string[] = ['ascending', 'descending', null];
  public sorting: string = '';

  public get fields(): string[] {
    const userForm = Object.keys(omit(IUserCreateForm(), ['password']));

    return ['id', ...userForm,
      'numPosts', 'numFollowers', 'numFollowing',
      'provider', 'createdAt'];
  }

  public formatTimeString(time: any) {
    return formatTimeString(time);
  }

  public handleEdit(row) {
    this.$router.push({
      path: `${RouterDictionaryUser.User}/update/${row.id}`
    });
  }

  public handleSortChange(obj) {
    this.sorting = obj.order ? `${obj.order === 'ascending' ? '' : '-'}${obj.prop}` : '';

    this.$store.dispatch(UserActionType.UserFilter, {
      params: {
        pagination: PaginationState(),
        filterParams: {
          ...this.filterParams,
          sort: this.sorting
        }
      }
    });
  }

  public async fetchData(emailVerified) {
    try {
      const dataFirst = await sfrHttpClient.user.filter(
        this.$refs.search.filterParams, <any> {
          page: 1,
          size: 300,
          export: true,
          emailVerified: emailVerified
        }
      );
      const remainData = [];
      let result: any[] = [];
      if (dataFirst.pagination.totalPages > 1) {
        for (let i = 2; i <= dataFirst.pagination.totalPages; i++) {
          remainData.push(sfrHttpClient.user.filter(
            this.$refs.search.filterParams, <any> {
              page: i,
              size: 300,
              export: true,
              emailVerified: emailVerified
            }
          ));
        }
        result = [dataFirst].concat(await Promise.all(remainData));
      } else {
        result = [dataFirst];
      }

      return result.reduce((pre, cre) => {
        return pre.concat(cre.data);
      }, []);
    } catch (error) {
      return Promise.reject(error);
    }

  }

  public handleResetList() {
    this.$refs.table.clearSort();
    this.sorting = '';

    this.$nextTick(() => {
      this.$store.dispatch(UserActionType.UserFilter, {
        params: {
          filterParams: {
            sort: this.sorting
          },
          pagination: PaginationState({
            page: 1
          })
        }
      });
      this.$store.dispatch(BannerActionType.BannerFilter, {
        params: {
          pagination: PaginationState({
            page: 1
          })
        }
      });
    });
  }

  public visibleSetBanner(id: string) {
    return includes(this.bannerReferenceIds, id)
  }

  public mounted() {
    this.handleResetList();
  }

  public async handleSetBannerUser(id: string){
    this.loadingSetBanner = true;
    await sfrHttpClient.user.setBannerUser(id).then((res) => {
      this.bannerReferenceIds = [...this.bannerReferenceIds, id]
      this.loadingSetBanner = false;
      this.$message({
        type: 'success',
        message: 'Set banner success!'
      });
    }).catch( (error) => {
      this.loadingSetBanner = false;
      this.$message({
        type: 'error',
        message: error.message
      });
    });
  }
}
