import { IFeed } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { FEED_MODULE_NAME } from './types';

export type IFeedState = ICRUDState<IFeed>;

export const defaultState: IFeedState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(FEED_MODULE_NAME),
};
