export const REPORTPOST_MODULE_NAME = 'reportPost';

export enum ReportPostActionType {
  ReportPostCreate = 'reportPostCreate',
  ReportPostUpdate = 'reportPostUpdate',
  ReportPostFilter = 'reportPostFilter',
  ReportPostGetById = 'reportPostGetById',
  ReportPostDelete = 'reportPostDelete',
  ReportPostPaginationChange = 'reportPostPaginationChange'
}

export enum ReportPostMutationType {
  ReportPostLoading = 'reportPostLoading',
  ReportPostLoaded = 'reportPostLoaded',
  ReportPostRefreshModels = 'reportPostRefreshModels',
  ReportPostUpdateModels = 'reportPostUpdateModels',
  ReportPostFilterModels = 'reportPostFilterModels',
}

export enum ReportPostGetterType {
  ReportPost = 'reportPost',
  ReportPosts = 'reportPosts',
  ReportPostFilterResults = 'reportPostFilterResults'
}
