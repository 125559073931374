import { computedPagination } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryBanner } from '../..';
import { BannerActionType, BANNER_MODULE_NAME } from '../../Store/types';
import { PaginationState } from 'root/store';
import './view.scss';
import { startCase, camelCase } from 'lodash';
import { IBanner } from 'root/models';
import draggable from 'vuedraggable';

@Component({
  template: require('./view.html'),
  components: {
    'draggable': draggable
  },
  computed: {
    ...computedPagination.call(this, BANNER_MODULE_NAME)
  },
  watch: {
    data() {
      if(this.data){
        this.banners = this.data;
      }
    }
  }
})

export class BannerList extends Vue {
  public data: IBanner[];
  public banners: IBanner[] = [];
  public loading: boolean;
  public loadingDragging: boolean = false;
  public colsName: string[] = [
    'contentType', 'image', 'description', 'link', 'position', 'action'
  ];

  public handleEditBtn(row) {
    this.$router.push({
      path: `${RouterDictionaryBanner.Banner}/update/${row.id}`
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.getListBanners();
    });
  }

  public getListBanners() {
    this.$store.dispatch(BannerActionType.BannerFilter, {
      params: {
        pagination: PaginationState()
      }
    });
  }

  public confirmDelete(id) {
    this.$confirm('This will permanently delete this banner. Continue?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.handleDelete(id);
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
  }

  public handleDelete(id) {
    this.$store.dispatch(BannerActionType.BannerDelete, {
      id: id,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public convertText(text: string) {
    return startCase(camelCase(text))
  }

  public onChangeDrag(e){
    this.loadingDragging = true;
    const form = this.banners.map((banner, index) => ({id: banner.id, new_position: index + 1}) )
    this.$store.dispatch(BannerActionType.UpdatePositionBanners, form).then((res) => {
      this.getListBanners()
      this.loadingDragging = false;
      this.$message({
        type: 'success',
        message: 'Change position successful!'
      });
    }).catch((error) => {
      this.loadingDragging = false;
      this.$message({
        type: 'error',
        message: `${error.message}`
      });
    });
  }
}
