export function confirm(self, { id, actionType, onSuccess }) {
  self.$msgbox({
    title: self.$t('delete_confirm').toString(),
    message: self.$t('delete_confirm_message').toString(),
    showCancelButton: true,
    confirmButtonText: self.$t('confirm').toString(),
    cancelButtonText: self.$t('cancel').toString(),
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        instance.confirmButtonLoading = true;
        instance.confirmButtonText = 'Loading...';
        self.$store.dispatch(actionType, {
          id,
          onSuccess: () => {
            instance.confirmButtonLoading = false;
            done();
            onSuccess();
          },
          onFailure: () => {
            done();
            instance.confirmButtonText = self.$t('confirm').toString();
            instance.confirmButtonLoading = false;
          }
        });
      } else {
        done();
      }

      return;
    }
  }).then(() => {
    self.$message({
      type: 'info',
      message: this.$t('delete_successfully').toString()
    });
  }).catch(() => {
    // no handle
  });
}

export function confirmAction(self, {title, message, handleFunction}) {
  self.$confirm(
    message,
    title,
    {
      confirmButtonText: self.$t('confirm').toString(),
      cancelButtonText: 'Cancel',
      type: 'info'
    })
    .then(() => {
      handleFunction();
    })
    .catch(() => {
      //
    });
}
