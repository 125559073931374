import Vue from 'vue';
import moment from 'moment';
import Component from 'vue-class-component';
import { RouterDictionaryCompetition } from '../..';
import { CompetitionActionType, COMPETITION_MODULE_NAME } from '../../Store/types';
import { FilterForm } from '../../Component/FilterForm';
import './view.scss';
import { IState } from 'root/store';
import { mapState } from 'vuex';
import { IPaginationState, ActionType } from 'root/store';
import { IFeed, ICompetition } from 'root/models';
import { debounce } from "lodash";

@Component({
  template: require('./view.html'),
  components: {
    'filter-form': FilterForm,
  },
  computed: {
    ...mapState({
      isLoading: (state: IState) => state.competition.applicantListLoading,
      data: (state: IState) => {
        const { applicants } =  state.competition;
        return applicants.map((item: any) => {
          item.applicant.phone = `(${item.applicant.countryCode || ''}) ${item.applicant.phoneNumber || ''}`;
          item.applicant.under18 = `${!item.applicant.above18.toString()}`;
          item.applicant.createdAt = `${moment(item.applicant.createdAt).format("DD/MM/YY HH:MM")}`
          return item;
        })
      },
      pagination: (state: IState) => state.competition.applicants_pagination,
    })
  },
  watch: {
    searchParams: {
      handler(){
        this.handlerSearch(this);
      },
      deep: true
    },
  }
})
export class CompetitionApplicantList extends Vue {
  public data: IFeed[];
  public pagination: IPaginationState;
  public competition: ICompetition;
  public searchParams = {
  }
  public pageIndex = 1;

  public old = [
    {
      label: "true",
      value: "true"
    },
    {
      label: "fault",
      value: "fault"
    }
  ];
  public status = [
    {
      label: "active",
      value: "active"
    },
    {
      label: "deactive",
      value: "deactive"
    }
  ];
  public multipleSelection = [];
  public enableSearch = false;
  public isSelectTable = false;

  public reload() {
    this.$store.dispatch(CompetitionActionType.GetContestantsByCompetition, {
      competitionId:  this.competitionId,
      dataFilter: { page: this.pagination.page, size: 5 }
    })
  }

  private handlerSearch = debounce( _this => {
    const searchParams =  _this.parseParamsSearch(_this.searchParams)
    _this.$store.dispatch(CompetitionActionType.GetContestantsByCompetition, {
      competitionId:  _this.competitionId,
      dataFilter: { page: _this.pagination.page, size: 5, ...searchParams }
    })
  }, 300)

  private  parseParamsSearch(data){
    let result = {};
    for(let key in data){
      const value = data[key];
      if(value && value !== '')
        result[key] = value
    }
    return result;
  }

  public sort(field, type) {
    this.$store.dispatch(CompetitionActionType.GetContestantsByCompetition, {
      competitionId:  this.competitionId,
      dataFilter: { page: this.pagination.page, size: 5, sortByField: field, sortBy: type }
    })
  }

  public get competitionId(): string {
    return this.$route.params.competitionId;
  }

  public mounted() {    
    this.$store.dispatch(CompetitionActionType.GetContestantsByCompetition, {
      competitionId:  this.competitionId,
      dataFilter: { page: 1, size: 5 }
    })
  }

  public handleSelectionChange(val) {
    this.multipleSelection = val;
    if (val.length == 0) {
      this.isSelectTable = false;
    } else {
      this.isSelectTable = true;
    }
  }

  public handleSearch() {
    this.enableSearch = !this.enableSearch;
  }

  public changeStatus(status){
    const applicantIds = this.multipleSelection.map(post => post.applicant._id);
    const form = { "ids": applicantIds, "status": status }
    this.$store.dispatch(CompetitionActionType.ApplicantChangeStatus, {
      competitionId: this.competitionId,
      form: form,

      onSuccess: () => {
        this.$message({
          type: "success",
          message: this.$t("update.successful").toString()
        });
        this.reload();
      },
      onFailure: (error) => {
        this.$store.dispatch(ActionType.CatchException, error);
      }
    });
  }
  public handleCurrentChange(val) {
    this.$router.push(`/competition/${this.competitionId}/applicant`);
  } 
  public changePagination(event) {
    this.pageIndex = event;
    this.$store.dispatch(CompetitionActionType.GetContestantsByCompetition, {
      competitionId:  this.competitionId,
      dataFilter: { page: this.pageIndex, size: 5 }
    })
  }
}
