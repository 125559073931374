import { IUser } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IUserHttpService extends ICRUDHttpService<IUser> {

  setBannerUser(id: string): Promise<any>;
};

export function UserHttpService(options: IHttpServiceClientOptions): IUserHttpService {

  const basePath = '/admin/users';

  function setBannerUser(id: string): Promise<any> {
    const url = options.httpService.getUrl(`${basePath}/${id}/banner`, options.mock);
    return options.httpService.post(url, {
      contentType: 'user'
    });
  }

  return {
    ...CRUDHttpService({
      ...options,
      basePath,
      serializeCreate,
      serializeUpdate,
    }),
    setBannerUser
  }
}

const serializeCreate = (model: IUser): any => {
  return {
    username: model.username.toLowerCase(),
    password: model.password,
    firstName: model.firstName,
    lastName: model.lastName,
    displayName: model.displayName,
    bio: model.bio,
    avatar: model.avatar,
    gender: model.gender,
    birthday: model.birthday,
    country: model.country,
    role: model.role,
    profession: model.profession,
    email: model.email,
    webpage: model.webpage,
    permissions: model.permissions,
    isVerified: model.isVerified,
    emailVerified: model.emailVerified
  };
};

const serializeUpdate = (model: IUser): any => {
  return {
    username: model.username.toLowerCase(),
    password: model.password,
    firstName: model.firstName,
    lastName: model.lastName,
    displayName: model.displayName,
    bio: model.bio,
    avatar: model.avatar,
    gender: model.gender,
    birthday: model.birthday,
    country: model.country,
    role: model.role,
    profession: model.profession,
    webpage: model.webpage,
    permissions: model.permissions,
    isVerified: model.isVerified,
    emailVerified: model.emailVerified
  };
};
