export const SearchUser = [
  'firstName',
  'lastName',
  'gender',
  'username',
  'displayName',
  'bio',
  'birthday',
  'country',
  'role',
  'profession',
  'email',
  'webPage',
  'countryCode',
  'mobileNumber',
  'password',
  'permissions',
  'id',
  'numPosts',
  'numFollowers',
  'numFollowing',
];
