import { TemplateEmail } from './Container';
// import { TemplateEmailCreate } from './Container/Create';
// import { TemplateEmailDetail } from './Container/Detail';
import { TemplateEmailList } from './Container/List';
import { TemplateEmailUpdate } from './Container/Update';

export enum RouterDictionaryTemplateEmail {
  TemplateEmail = '/template_email',
  // TemplateEmailCreate = '/template_email/create',
  // TemplateEmailDetail = '/template_email/:TemplateEmailId',
  TemplateEmailUpdate = '/template_email/update/:TemplateEmailId'
}

export const TemplateEmailRoutes = {
  path: RouterDictionaryTemplateEmail.TemplateEmail,
  component: TemplateEmail,
  children: [
    {
      path: '',
      component: TemplateEmailList,
      name: 'Template Email List',
    },
    {
      path: RouterDictionaryTemplateEmail.TemplateEmailUpdate,
      component: TemplateEmailUpdate,
      name: 'Template Email Update'
    }
  ]
};
