import { Version } from './Container';
import { VersionList } from './Container/List';
import { VersionUpdate } from './Container/Update';

export enum RouterDictionaryVersion {
  Version = '/version',
  VersionUpdate = '/version/update/:versionId'
}

export const VersionRoutes = {
  path: RouterDictionaryVersion.Version,
  component: Version,
  children: [
    {
      path: '',
      component: VersionList,
      name: 'Version List',
    },
    {
      path: RouterDictionaryVersion.VersionUpdate,
      component: VersionUpdate,
      name: 'Version Update'
    }
  ]
};
