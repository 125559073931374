export const BADWORD_MODULE_NAME = 'badword';

export enum BadwordActionType {
  BadwordCreate = 'badwordCreate',
  BadwordUpdate = 'badwordUpdate',
  BadwordFilter = 'badwordFilter',
  BadwordGetById = 'badwordGetById',
  BadwordDelete = 'badwordDelete',
  BadwordPaginationChange = 'badwordPaginationChange'
}

export enum BadwordMutationType {
  BadwordLoading = 'badwordLoading',
  BadwordLoaded = 'badwordLoaded',
  BadwordRefreshModels = 'badwordRefreshModels',
  BadwordUpdateModels = 'badwordUpdateModels',
  BadwordFilterModels = 'badwordFilterModels',
}

export enum BadwordGetterType {
  Badword = 'badword',
  Badwords = 'badwords',
  BadwordFilterResults = 'badwordFilterResults'
}
