import { ICategory } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryCategory } from '../..';
import { CategoryForm } from '../../Component/CategoryForm';
import { CategoryActionType, CategoryGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'category-form': CategoryForm
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.category.detailLoading
    })
  },
  watch: {
    detail() {
      if(this.detail && this.detail.isSpecial){
        this.$router.push({
          path: RouterDictionaryCategory.Category
        });
      }
    }
  }
})

export class CategoryUpdate extends Vue {
  public get categoryId(): string {
    return this.$route.params.categoryId;
  }

  public get detail(): ICategory {
    return this.$store
      .getters[CategoryGetterType.Category](this.categoryId);
  }

  public handleSubmit(form) {
    this.$store.dispatch(CategoryActionType.CategoryUpdate, {
      form,
      id: this.categoryId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Update successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'warning',
          message: `${error.message}`
        });
      }
    });
  }

  public handleDelete() {
    this.$store.dispatch(CategoryActionType.CategoryDelete, {
      id: this.categoryId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
        this.$router.push({
          path: RouterDictionaryCategory.Category
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        CategoryActionType.CategoryGetById, {
          id: this.categoryId
        }
      );
    });
  }
}
