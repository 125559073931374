import { IProfile, IUserForm, IUserNotificationSettingForm } from 'root/models';
import { IHttpServiceClientOptions } from '../Core';
export interface IProfileHttpService {
  getProfile(): Promise<IProfile>;
  updateProfile(form: IUserForm): Promise<IProfile>;
  updateUserNotificationSetting(form: IUserNotificationSettingForm): Promise<any>;
}

export function ProfileHttpService(options: IHttpServiceClientOptions): IProfileHttpService {

  const baseUrl = '/admin',
    url = options.httpService.getUrl(`${baseUrl}`, options.mock),
    urlGet = options.httpService.getUrl(`${baseUrl}/me`, options.mock),
    urlUserNotificationSetting = options.httpService.getUrl(`${baseUrl}/notification`, options.mock);

  function getProfile(): Promise<IProfile> {
    return options.httpService.get(urlGet);
  }

  function updateProfile(form: IUserForm): Promise<IProfile> {
    return options.httpService.put(url, form);
  }

  function updateUserNotificationSetting(form: IUserNotificationSettingForm): Promise<any> {
    return options.httpService.put(urlUserNotificationSetting, form);
  }

  return {
    getProfile,
    updateProfile,
    updateUserNotificationSetting
  };
}
