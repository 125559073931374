import { IGifCategory } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { GIF_CATEGORY_MODULE_NAME } from './types';

export type IGifCategoryState = ICRUDState<IGifCategory>;

export const defaultState: IGifCategoryState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(GIF_CATEGORY_MODULE_NAME),
};
