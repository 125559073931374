import { FileType, IFile } from 'root/models';
import { pathUpload, resizeImage, sizeImage } from 'root/services';
import { getFirebaseApp } from 'root/services/firebase';
import { Logger } from 'root/services';

export function parseFileType(extension: string): FileType {
  let type = extension.replace(/\/.*/g, '');
  if (type === 'image') { type = 'photo'; }
  switch (type) {
  case FileType.Photo:
  case FileType.Video:
  case FileType.Audio:
    break;
  default:
    type = 'file';
    break;
  }

  return <any> type;
}

export function checkFileTypeVideo(file: IFile) {
  return file.fileType === FileType.Video;
}

export function checkFileTypeImage(file: any) {
  if (file.raw) {
    return parseFileType(file.raw.type) === FileType.Photo;
  }

  return file.fileType === FileType.Photo;
}

export async function checkSizeImageBeforeUpdate(file: File, maxArea: any) {
  return new Promise(async (resolve, reject) => {
    const { maxHeight, maxWidth } = maxArea;    
    const img = new Image();
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onerror = (e) => {
      console.log('reader error', e);
    };
    reader.onload = (e: any) => {
      const fileHandle = e.target.result;
      img.src = fileHandle;
    };
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const ratio = maxWidth / maxHeight;
      if (height*ratio === width && height <= maxHeight && width <= maxWidth) resolve();
      else reject();
    };

  });
}

export function getFileExtension(filename) {
  const ext = /^.+\.([^.]+)$/.exec(filename);

  return ext === null ? '' : ext[1];
}

export const listFileIcon = [
  'png', 'jpg', 'jpeg', 'gif',
  'doc', 'docx',
  'xls', 'xlsx',
  'ppt', 'pdf', 'pptx',
  'CAD', 'pages', 'numbers',
  'key', 'zip', 'rar', 'mp4'
];

export enum iconFileName {
  pdf = 'file-pdf',
  doc = 'file-word',
  docx = 'file-word',
  xls = 'file-excel',
  xlsx = 'file-excel',
  ppt = 'file-powerpoint',
  pptx = 'file-powerpoint',
  zip = 'file-archive',
  rar = 'file-archive'
}

export function parseIconFileWithType(file: any) {
  const basePath = '/assets/img/';
  let iconFile;
  const _fileType: FileType = file.raw ? parseFileType(file.raw.type) : file.fileType;
  const fileName = file.name || file.fileName;
  switch (_fileType) {
  case FileType.Photo:
    iconFile = 'file-image';
    break;
  case FileType.File:
    iconFile = listFileIcon.indexOf(getFileExtension(fileName)) >= 0 && iconFileName[getFileExtension(fileName)]
        ? iconFileName[getFileExtension(fileName)] : 'file';
    break;
  default:
    iconFile = 'file';
    break;
  }

  return `${basePath}${iconFile}.svg`;
}

export async function uploadFileForMessage(file, pathUploadCurrent: pathUpload) {
  let thumbnail: any = '';
  let original: any;

  const storageRef = getFirebaseApp().storage().ref();

  if (checkFileTypeImage(file)) {
    const [fileThumbnail, fileOriginal] = await resizeImage(
      file.raw,
      [sizeImage.message.thumbnail, sizeImage.message.original]
    );
    const currentRefThumbnail = storageRef.child(pathUploadCurrent + fileThumbnail.name);
    thumbnail = currentRefThumbnail.put(fileThumbnail);
    const currentRefOriginal = storageRef.child(pathUploadCurrent + fileOriginal.name);
    original = currentRefOriginal.put(fileOriginal);
  } else {
    const currentRef = storageRef.child(pathUploadCurrent + file.raw.name);
    original = currentRef.put(file.raw);
  }

  return { thumbnail, original };

}

export async function resetOrientation(file: File, srcOrientation: number) {
  return new Promise(async (resolve) => {
    const img = new Image();
    const reader = new FileReader();
    const orient = await getOrientation(file);

    reader.readAsDataURL(file);

    reader.onerror = () => {
      // console.log('reader error', e);
    };
    reader.onload = (e: any) => {
      const fileHandle = e.target.result;
      // orient = getOrientationClone(_base64ToArrayBuffer(fileHandle));
      img.src = fileHandle;
    };

    img.onload = () => {
      const width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (orient) {
      case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
      case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
      case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
      case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
      case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
      case 7: ctx.transform(0, -1, -1, 0, height , width); break;
      case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
      default: break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      canvas.toBlob((imgBlob: any) => {
        imgBlob.name = file.name;
        resolve(imgBlob);
      }, 'image/jpeg');
    };

  });

}

export async function getOrientation(file: File): Promise<any> {

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const view = new DataView(event.target.result);

      if (view.getUint16(0, false) !== 0xFFD8) {
        // return callback(-2);
        resolve(-2);
      }

      const length = view.byteLength;
      let offset = 2;

      while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;

        if (marker === 0xFFE1) {
          if (view.getUint32(offset += 2, false) !== 0x45786966) {
            // return callback(-1);
            resolve(-1);
          }
          const little = view.getUint16(offset += 6, false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;

          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + (i * 12), little) === 0x0112) {
              resolve(view.getUint16(offset + (i * 12) + 8, little));
            }
          }
        } else if ((marker && 0xFF00) !== 0xFF00) {
          // return;
        }
        offset += view.getUint16(offset, false);
      }

      resolve(-1);
    };
    reader.onerror = () => {
      // console.log('getOrientation reader error', e);
    };
    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  });

}

export async function captureVideoThumbnail(file: any, callback: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    let isSuccessToCapture: boolean = false,
      timeoutEvent = null;

    fileReader.onprogress = (e) => {
      callback(Math.round(e.loaded / file.size * 100));
      // tslint:disable-next-line:early-exit
      if (e.loaded === file.size) {
        timeoutEvent = setTimeout(() => {
          if (!isSuccessToCapture) {
            reject('request time out');
          }
        }, 20000);
      }

      // return;
    };
    fileReader.onload = () => {
      const blob = new Blob([fileReader.result], {type: file.type});
      const url = URL.createObjectURL(blob);
      const video = <any> document.createElement('video');
      let frameTime = 0;

      video.addEventListener('error', () => {
        clearTimeout(timeoutEvent);
        reject('can not load this video');
      });
      // video.addEventListener('loadedmetadata', () => {
      //   console.log('loaded metadata');
      //   const duration = video.duration;
      //   if (duration > 20) {
      //     video.currentTime = 20;
      //   } else if (duration > 1) {
      //     video.currentTime = 1;
      //   }
      // });
      const timeupdate = () => {
        const image = snapImage();
        if (image) {
          resolve(image);
          video.removeEventListener('timeupdate', timeupdate);
          video.pause();
        }

        return;
      };
      video.addEventListener('loadedmetadata', () => {
        const duration = video.duration;
        if (duration > 20) {
          video.currentTime = 20;
          frameTime = 20;
        } else if (duration > 1) {
          video.currentTime = 1;
          frameTime = 1;
        }
      });
      video.addEventListener('loadeddata', () => {
        clearTimeout(timeoutEvent);
        isSuccessToCapture = true;
        const image = snapImage();
        if (image) {
          resolve(image);
          video.removeEventListener('timeupdate', timeupdate);
        }
      });
      const snapImage = () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL();

        if (frameTime > 0) {
          if (frameTime <= video.currentTime) {
            return dataURLtoBlob(image);
          }

          return false;
        }

        return dataURLtoBlob(image);

        // const success = frameTime <= video.currentTime;
        // const success = image.length > 100000;
        // if (success) {
        //   return dataURLtoBlob(image);
        // }

        // return false;
      };
      video.addEventListener('timeupdate', timeupdate);
      video.preload = 'metadata';
      video.src = url;
      // Load video in Safari / IE11
      video.muted = true;
      video.playsInline = true;
      video.play();
      // video.onseeked = video.onload = () => {
      //   clearTimeout(timeoutEvent);
      //   isSuccessToCapture = true;
      //   const canvas = document.createElement('canvas');
      //   canvas.width = video.videoWidth;
      //   canvas.height = video.videoHeight;
      //   canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      //   // canvas.toBlob((imageBlob: any) => {
      //   //   imageBlob.name = `thumbnail-${Date.now()}.jpeg`;

      //   //   resolve(imageBlob);
      //   // }, 'image/jpeg');
      //   const image = canvas.toDataURL('image/jpeg');
      //   console.log('image', image);

      //   resolve(dataURLtoBlob(image));
      // };

      // video.preload = 'metadata';
      // video.muted = true;
      // video.src = url;

      // video.playsInline = true;
      // video.play();

    };
    fileReader.onerror = (error) => {
      Logger.debug('Error is resizing image', error);

      return reject(error);
    };

    fileReader.readAsArrayBuffer(file);
  });

}
export function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], {type: mime});
}