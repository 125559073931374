import { sfrHttpClient } from 'root/api/http';
import { crudActions } from 'root/store/helpers';
import { VERSION_MODULE_NAME } from './types';

const {
  create,
  filter,
  update,
  getById,
  deleteById
} = sfrHttpClient.version;

export const actions = {
  ...crudActions(VERSION_MODULE_NAME, {
    create,
    filter,
    update,
    getById,
    deleteById
  })
};
