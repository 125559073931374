import { IReportPost } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
export type IReportPostHttpService = ICRUDHttpService<IReportPost>;

export function ReportPostHttpService(options: IHttpServiceClientOptions): IReportPostHttpService {

  const basePath = '/admin/report-posts';

  return CRUDHttpService({
    ...options,
    basePath
  });
}
