import Vue from 'vue';
import Component from 'vue-class-component';
import { FormCompetition } from '../../Component/FormCompetition';
import { Form } from 'element-ui';
import { CompetitionActionType, CompetitionMutationType } from 'root/pages/Competition/Store/types';
import { stripObject } from '@hgiasac/helper';
import { ActionType } from 'root/store';
import { removeEmptyField } from 'root/helpers';
import {
  ICompetitionCreateForm,
  CompetitionRoundType, ICompetitionRound, ICompetitionRoundDefault,
  CompetitionItemDefault
} from 'root/models';
import { uploadToAWS } from 'root/services';

@Component({
  template: require('./view.html'),
  components: {
    'competition-form': FormCompetition
  },
})
export class CompetitionCreate extends Vue {
  public form: ICompetitionCreateForm = CompetitionItemDefault();
  public loading: boolean = false;
  public standardRound: ICompetitionRound = ICompetitionRoundDefault();
  public createSuccess: boolean = false;

  public async submit(call){
    try {
      if (!!this.form.image && typeof this.form.image != 'string') {
        this.form.image = await uploadToAWS('competition', this.form.image,
          {maxHeight: 1000, maxWidth: 1000, quality: 0.5});
      }

      if (!!this.form.logo && typeof this.form.logo != 'string') {
        this.form.logo = await uploadToAWS('competition', this.form.logo,
          {maxHeight: 1000, maxWidth: 1000, quality: 0.5});
      }
      
      this.form.round = this.form.round.map(removeEmptyField);
      await this.$store.dispatch(CompetitionActionType.CompetitionCreate, {
        form: stripObject(this.form),
        onSuccess: (result) => {
          this.$message({
            type: 'success',
            message: 'Your post has been created.'
          });
          call(result);
          this.$router.push("/competition")
          this.$store.commit(CompetitionMutationType.SetNotSubmit)
        },
        onFailure: (error) => {
          this.$store.commit(CompetitionMutationType.SetNotSubmit)
          this.loading = false;
          // this.$store.dispatch(ActionType.CatchException, error);
        }
      });
    } catch (error) {
      this.$store.commit(CompetitionMutationType.SetNotSubmit)
      this.loading = false;
      this.$store.dispatch(ActionType.CatchException, error);
    }
  }

  public mounted() {
    this.$nextTick(() => {
      const { isAddedWildcards, isReUpload, reUploadDate, ...standardRound } = this.standardRound;
      this.form.round.push({
        ...standardRound,
        type: CompetitionRoundType.Standard
      });
      return;
    });
  }
}
