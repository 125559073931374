import { omit } from 'lodash';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
import { parseParamUrl } from 'root/helpers';
import { ICompetitionFilter, CompetitionFilterType, ICompetition, IApplicantCreateForm } from 'root/models';

export interface ICompetitionHttpService extends ICRUDHttpService<ICompetition> {
  updateStatusCompetition(id: string, statusCompetition: string): Promise<any>;
  deactiveCompetition(id: string): Promise<any>;
  getApplicantionByCompetition(id: string): Promise<any>;
  getCompetitionApplicants(filter: ICompetitionFilter, competitionId: string): Promise<any>;
  changeStatus(competitionId: string, form: Object): Promise<any>;
  createApplicant(competitionId: string, form: IApplicantCreateForm): Promise<any>;
  getByIdAndCountry(competitionId: string, currentCountry: string): Promise<any>;
  payCompetitions(competitionId: string): Promise<any>;
  deleteCompetition(competitionId: string): Promise<any>;
};

export function CompetitionHttpService(options: IHttpServiceClientOptions): ICompetitionHttpService {

  const basePath = '/admin/competitions';

  const { httpService, mock } = options;
  const baseUrl = httpService.getUrl(basePath, mock);

  function updateStatusCompetition(id: string, statusCompetition: string): Promise<any> {
    return httpService.put(`${parseParamUrl(baseUrl, null)}/${id}/status-competition`, { statusCompetition });
  }

  function getByIdAndCountry(competitionId, currentCountry) {
    const path = options.httpService.getUrl(`${basePath}/${competitionId}?country=${currentCountry}`)
    return options.httpService.get(path)
  }

  function getCompetitionApplicants(filter: ICompetitionFilter = {
    type: CompetitionFilterType.All
  }, competitionId: string) {
    const path = options.httpService.getUrl(`${basePath}/${competitionId}/applicants`)
    return options.httpService.get(path, {
      params: filter
    })
  }

  function deactiveCompetition(id: string): Promise<any> {
    return httpService.get(`${parseParamUrl(baseUrl, null)}/${id}/toggleActive`);
  }

  function getApplicantionByCompetition(id: string): Promise<any> {
    return httpService.get(`${parseParamUrl(baseUrl, null)}/${id}/applicants`);
  }

  function changeStatus(competitionId: string, form: IApplicantCreateForm) {
    const createPostPath = options.httpService.getUrl(`/admin/competitions/${competitionId}/applicants-status`);
    return options.httpService.put(createPostPath, form);
  }
  function createApplicant(competitionId: string, form: IApplicantCreateForm) {
    const createPostPath = options.httpService.getUrl(`/admin/competitions/${competitionId}/applicants`);
    return options.httpService.post(createPostPath, form);
  }
  function payCompetitions(competitionId: string) {
    const url = options.httpService.getUrl(`${basePath}/${competitionId}/pay`);

    return options.httpService.put(url, {});
  }
  function deleteCompetition(competitionId: string) {
    const url = options.httpService.getUrl(`${basePath}/${competitionId}`);
    return options.httpService.del(url, {});

  }

  return {
    ...CRUDHttpService({
      ...options,
      basePath,
      serializeCreate,
      serializeUpdate
    }),
    updateStatusCompetition,
    deactiveCompetition,
    getApplicantionByCompetition,
    getCompetitionApplicants,
    changeStatus,
    createApplicant,
    getByIdAndCountry,
    payCompetitions,
    deleteCompetition,
  };
}

const serializeUpdate = (competition: ICompetition): any => {

  const newCompetition = omit(competition, ['id', 'createdAt', 'currentPhase']);

  return {
    ...newCompetition
  };
};

const serializeCreate = (competition: ICompetition) => {
  return omit(competition, ['status', 'currentPhase']);
};
