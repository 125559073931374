import { Maintenance } from './Container';
// import { MaintenanceCreate } from './Container/Create';
// import { MaintenanceDetail } from './Container/Detail';
import { MaintenanceList } from './Container/List';
import { MaintenanceUpdate } from './Container/Update';

export enum RouterDictionaryMaintenance {
  Maintenance = '/maintenance',
  // MaintenanceCreate = '/maintenance/create',
  // MaintenanceDetail = '/maintenance/:MaintenanceId',
  MaintenanceUpdate = '/maintenance/update/:MaintenanceId'
}

export const MaintenanceRoutes = {
  path: RouterDictionaryMaintenance.Maintenance,
  component: Maintenance,
  children: [
    {
      path: '',
      component: MaintenanceList,
      name: 'Maintenance List',
    },
    {
      path: RouterDictionaryMaintenance.MaintenanceUpdate,
      component: MaintenanceUpdate,
      name: 'Maintenance Update',
    }
  ]
};
