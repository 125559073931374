import { ReportCategory } from './Container';
import { ReportCategoryCreate } from './Container/Create';
import { ReportCategoryDetail } from './Container/Detail';
import { ReportCategoryList } from './Container/List';
import { ReportCategoryUpdate } from './Container/Update';

export enum RouterDictionaryReportCategory {
  ReportCategory = '/report-category',
  ReportCategoryCreate = '/report-category/create',
  ReportCategoryDetail = '/report-category/:reportCategoryId',
  ReportCategoryUpdate = '/report-category/update/:reportCategoryId'
}

export const ReportCategoryRoutes = {
  path: RouterDictionaryReportCategory.ReportCategory,
  component: ReportCategory,
  children: [
    {
      path: '',
      component: ReportCategoryList,
      name: 'Report Category List',
    },
    {
      path: RouterDictionaryReportCategory.ReportCategoryCreate,
      component: ReportCategoryCreate,
      name: 'Report Category Create'
    },
    {
      path: RouterDictionaryReportCategory.ReportCategoryDetail,
      component: ReportCategoryDetail,
      name: 'Report Category Detail'
    },
    {
      path: RouterDictionaryReportCategory.ReportCategoryUpdate,
      component: ReportCategoryUpdate,
      name: 'Report Category Update'
    }
  ]
};
