import { stripObject } from '@hgiasac/helper';
import { sfrHttpClient } from 'root/api/http';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IWalletState } from './mutations';
import { ActionTypeWallet, MutationTypeWallet } from './types';
import { omit } from 'lodash';
const { deleteById, create, list, getPaymentPackages, getPaymentList, getPaymentTransactions, getPaymentMethod, getPaymentPackage
  ,createPaymentPackage, updatePaymentPackage, updateTransactionNote  } = sfrHttpClient.wallet;

export const actions = {
  ...crudActions('payment_methods', {
    list, create, deleteById,
  }),
  async [ActionTypeWallet.GetPaymentPackages]({ commit, dispatch }: ActionContext<IWalletState, IState>, value) {
    try {
      commit(MutationTypeWallet.SetLoading);
      const data = await getPaymentPackages(value);
      commit(MutationTypeWallet.SetPaymentPackages, data);
      commit(MutationTypeWallet.SetLoaded);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeWallet.GetPaymentList]({ commit, dispatch }: ActionContext<IWalletState, IState>, value) {
    try {
      commit(MutationTypeWallet.SetLoading);
      const data = await getPaymentList(value);
      commit(MutationTypeWallet.SetPaymentList, data);
      commit(MutationTypeWallet.SetLoaded);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },

  async [ActionTypeWallet.GetPaymentMethods]({ commit, dispatch }: ActionContext<IWalletState, IState>, value) {
    try {
      commit(MutationTypeWallet.SetLoading);
      const data = await getPaymentMethod(value);
      commit(MutationTypeWallet.SetPaymentMethods, data);
      commit(MutationTypeWallet.SetLoaded);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },

  async [ActionTypeWallet.GetPaymentTransactions]({ commit, dispatch }: ActionContext<IWalletState, IState>, value) {
    try {
      commit(MutationTypeWallet.SetLoading);
      const data = await getPaymentTransactions(value);
      commit(MutationTypeWallet.SetPaymentTransactions, data);
      commit(MutationTypeWallet.SetLoaded);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },

  async [ActionTypeWallet.GetPaymentPackageById]({ commit, dispatch }: ActionContext<IWalletState, IState>, id) {
    try {
      commit(MutationTypeWallet.SetLoading);
      const data = await getPaymentPackage(id);
      commit(MutationTypeWallet.SetPaymentPackage, data);
      commit(MutationTypeWallet.SetLoaded);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },

  async [ActionTypeWallet.PaymentPackageCreate](
    { commit, dispatch }: ActionContext<IWalletState, IState>, {form, onSuccess, onFailure }
  ) {
      commit(MutationTypeWallet.SetLoading);
      console.log(form);
      let { amount, chargeAmount, packageType, status } = form;

      const value = {
        'amount': amount,
        'chargeAmount': chargeAmount,
        'packageType': packageType,
        'status': status
      };

      try {
        await createPaymentPackage(value)
          .then((result) => {
            commit(MutationTypeWallet.SetLoaded);
            if(onSuccess) {
              onSuccess(result);
            }
          });
      } catch (error) {
        commit(MutationTypeWallet.SetLoaded);
        dispatch(ActionType.CatchException, error);
        if (onFailure) {
          onFailure(error);
        }
      }
  },

  async [ActionTypeWallet.PaymentPackageUpdate](
    { commit, dispatch }: ActionContext<IWalletState, IState>, {form, onSuccess, onFailure }
  ) {
      commit(MutationTypeWallet.SetLoading);
      console.log(form);
      
      const id = form._id;
      let { amount, chargeAmount, packageType, status } = form;

      const value = {
        'amount': amount,
        'chargeAmount': chargeAmount,
        'packageType': packageType,
        'status': status
      };

      try {
        await updatePaymentPackage(id, value)
          .then((result) => {
            commit(MutationTypeWallet.SetLoaded);
            if(onSuccess) {
              onSuccess(result);
            }
          });
      } catch (error) {
        commit(MutationTypeWallet.SetLoaded);
        dispatch(ActionType.CatchException, error);
        if (onFailure) {
          onFailure(error);
        }
      }
  },

  async [ActionTypeWallet.TransactionNoteUpdate](
    { commit, dispatch }: ActionContext<IWalletState, IState>, {form, onSuccess, onFailure }
  ) {
      commit(MutationTypeWallet.SetLoading);
      console.log(form);
      
      const id = form.id;
      let { txnNote } = form;

      try {
        await updateTransactionNote(id, { txnNote })
          .then((result) => {
            commit(MutationTypeWallet.SetLoaded);
            if(onSuccess) {
              onSuccess(result);
            }
          });
      } catch (error) {
        commit(MutationTypeWallet.SetLoaded);
        dispatch(ActionType.CatchException, error);
        if (onFailure) {
          onFailure(error);
        }
      }
  }
}
