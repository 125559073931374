import { pick } from 'lodash';
import { parseParamUrl } from 'root/helpers';
import { IStickerCategories } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IStickerCategoriesHttpService extends ICRUDHttpService<IStickerCategories> {
  
}
export function StickerCategoriesHttpService(options: IHttpServiceClientOptions): IStickerCategoriesHttpService {
  const basePath = '/admin/sticker-categories';
  const { httpService, mock } = options;
  // const baseUrl = httpService.getUrl(basePath, mock);
  return {...CRUDHttpService({
    ...options,
    basePath,
  }),
  };
}


