import { Form } from 'element-ui';
import { replace } from 'lodash';
import * as moment from 'moment';
import { afterDate, ruleBeAURL, ruleRequired } from 'root/helpers';
import { INotification, INotificationCreateForm, NotificationCreateDefaultForm } from 'root/models';
import { getSelectArrayOfArray } from 'root/utils';
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
  template: require('./view.html'),
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean
  },
  watch: {
    data() {
      this.dataChange();
    },
    url(val) {
      this.form.content = `https://${replace(val, /^https?:\/\// , '')}`;
    }
  }
})
export class NotificationForm extends Vue {
  public $refs: {
    form: Form;
  };

  public form: INotificationCreateForm = NotificationCreateDefaultForm();
  public data: INotification;
  public url: string = '';

  public get rules() {
    return {
      title: [
        ruleRequired('Please input Content'),
      ],
      pto: [
        { required: true, message: 'Please select at least one type', trigger: 'blur' },
      ],
      type: [
        { type: 'array' },
      ],
      content: [
        ruleRequired('Please input a URL'),
        ruleBeAURL()
      ],
      expiryDate: [
        ruleRequired('Please pick a Date'),
        afterDate(moment(new Date()).format('YYYY-MM-DD'), 'Date must be after today')
      ]
    };
  }

  public get role() {
    return getSelectArrayOfArray(this.$store.state.global.globalConfig.role);
  }

  public handleSubmit() {
    this.$refs.form.validate((valid) => {
      if (!valid) {
        return;
      }

      this.$emit('submit', this.form);
    });
  }

  public mounted() {
    this.dataChange();
  }

  public dataChange() {
    if (!this.data) {
      return;
    }

    this.form = { ...this.data };
  }
}
