import Vue from 'vue';
import Component from 'vue-class-component';
import { GifCategoryActionType, GIF_CATEGORY_MODULE_NAME } from '../../Store/types';
import { mapState } from 'vuex';
import { IState } from 'root/store';
import { startCase, camelCase } from 'lodash';
import draggable from 'vuedraggable';
import { RouterDictionaryGifCategory } from '../..';
import { computedPagination } from 'root/store/helpers';


@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, GIF_CATEGORY_MODULE_NAME),
    ...mapState({
      loading: (state: IState) => state.gifCategory.loading,
      data: (state: IState) => state.gifCategory.data,
    })
  },
  components: {
    'draggable': draggable
  },
})
export class GifCategoryList extends Vue {
  public loading: boolean;
  public loadingDelete: boolean = false;
  public colsName: string[] = [
    'name', 'created by name', 'avatarUrl', 'total gif', 'action'
  ];
  public convertText(text: string) {
    return startCase(camelCase(text))
  }
  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(GifCategoryActionType.GifCategoryFilter);
    });
  }
  public handleEditBtn(row) {
    this.$router.push({
      path: `${RouterDictionaryGifCategory.GifCategory}/update/${row.id}`
    });
  }
  public confirmDelete(id) {
    this.$confirm('This will permanently delete this banner. Continue?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {      
      this.handleDelete(id);
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
  }
  public async handleDelete(id) {
    this.loadingDelete = true;
    await this.$store.dispatch(GifCategoryActionType.GifCategoryDelete, {
      id: id,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
    this.loadingDelete = false;
  }
  public handleDetail(gifId: number) {
    if(!gifId) return;
    this.$router.push(`/gif-category/${gifId}`);
  }
}
 
