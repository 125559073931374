import { Form } from 'element-ui';
import { LinkPreviewCard } from 'root/components/LinkPreviewCard';
import { StickerCategoryCreateFormDefault, StickerCategoryCreateForm, IStickerCategories } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import './view.scss';
import { ruleRequired, ruleRequiredNumber } from 'root/helpers';
import { getPublicLink, uploadImageS3 } from 'root/services/upload';
import { StickerCategoriesActionType } from '../../../Store/types';
import { RouterDictionaryStickerCategory } from '../../..';
import { mapState } from 'vuex';
import { IState } from 'root/store';
import { flatten, includes } from 'lodash';
import { isArray, isUndefined } from 'util';

@Component({
  template: require('./view.html'),
  components: {
    LinkPreviewCard
  },
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean,
  },
  computed: {
    ...mapState({
      loadingCreate: (state: IState) => state.stickerCategories.detailLoading,
    })
  },
  watch: {
    data() {
      this.dataChange();
    }
  },
})
export class StickerForm extends Vue {
  public $refs: {
    form: Form;
  };
  public loadingCreate: boolean;
  public form: StickerCategoryCreateForm = StickerCategoryCreateFormDefault();
  public data: IStickerCategories;
  public imageUploading: boolean = false;
  public isCreate: boolean;
  public mounted() {
    this.dataChange();
  }
  public dataChange() {
    if (!this.data) {
      return;
    }
    this.form = { ...this.data };
  }
  public get rules() {
    return {
      required: error => {
        return ruleRequired(error);
      },
      'name': [
        ruleRequired('Please input name')
      ],
      'price': [
        ruleRequiredNumber('Please input Price')
      ]
    };
  }
  public handleUpdate() {
    this.$store.dispatch(StickerCategoriesActionType.StickerCategoriesCreate, {
      form: this.form,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Create successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }
  public handleCreate() {
    this.$refs.form.validate((valid) => {
      if (!valid) {
        return;
      }
      else {
        this.$store.dispatch(StickerCategoriesActionType.StickerCategoriesCreate, {
          form: this.form,
          onSuccess: () => {
            this.$router.push({
              path: RouterDictionaryStickerCategory.StickerCategoriesList
            });
            this.$message({
              type: 'success',
              message: 'Create success'
            });
          },
          onFailure: () => {
            this.$message({
              type: 'error',
              message: 'Create failed'
            });
          }
        });
      }
    });
  }

  public handleRemove() {
    this.form.avatarUrl = '';
  }
  public handlePreviewImage(file) {
    this.imageUploading = true;
    uploadImageS3('user/avatar/', file.raw).then(async (result: any) => {
      this.imageUploading = false;
      this.form.avatarUrl = getPublicLink(result.key);
      this.$message({
        type: 'success',
        message: 'Upload successful'
      });
    }).catch((error) => {
      this.imageUploading = false;
      console.log(error);
      this.$message({
        type: 'error',
        message: 'Upload failed'
      });
    });
  }

  public async isValidForm() {
    const promises = flatten(Object.values(this.$refs).map((element: any) => {
      if (isArray(element)) {
        return (<any> element).map((form) => {
          return new Promise((resolve, _reject) => {
            form.validate((valid, result) => {
              resolve({ valid, result });
            });
          });
        });
      }

      return new Promise((resolve, _reject) => {
        element.validate((valid, result) => {
          resolve({ valid, result });
        });
      });
    }));

    return Promise.all(promises).then((arrayResolve) => {
      const errors = arrayResolve.map((resolve) => {
        if (isUndefined(resolve) || (<any> resolve).valid) {
          return;
        }
        this.$message({
          type: 'error',
          message: Object.values((<any> resolve).result)[0][0].message
        });

        return false;
      });

      return !includes(errors, false);
    });
  }

  public async handleSubmit() {
    const valid = await this.isValidForm();    
    if (!valid) {
      return;
    }
    this.$emit('submit', this.form);
  }
}