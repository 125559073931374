import { sfrHttpClient } from 'root/api/http';
import { crudActions } from 'root/store/helpers';
import { BANNER_MODULE_NAME, BannerActionType } from './types';
import { ActionContext } from "vuex";
import { IBannerState } from '.';
import { IState } from 'root/store';

const {
  create,
  filter,
  update,
  getById,
  deleteById,
  updatePositionBanners
} = sfrHttpClient.banner;

export const actions = {
  ...crudActions(BANNER_MODULE_NAME, {
    create,
    filter,
    update,
    getById,
    deleteById
  }),
  async [BannerActionType.UpdatePositionBanners](
    {}: ActionContext<IBannerState, IState> , form): Promise<any> {
    return updatePositionBanners(form);
  },
};
