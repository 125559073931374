import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    name: {
      type: String,
      required: true
    },
    checked: Boolean
  }
})
export class SFRCheckbox extends Vue {

  public handleChange(e) {
    this.$emit('change', e.target.checked);
  }
}
