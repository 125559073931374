import { createUserSendbird } from 'root/services';
import Vue from 'vue';
import Component from 'vue-class-component';
import { UserForm } from '../../Component/UserForm';
import { UserActionType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'user-form': UserForm
  }
})
export class UserCreate extends Vue {

  public uploading: boolean = false;

  public handleSubmit(form) {
    this.$store.dispatch(UserActionType.UserCreate, {
      form,
      onSuccess: (e) => {
        createUserSendbird({ user_id: e.id, nickname: e.username, profile_url: ''});
        this.$message({
          type: 'success',
          message: 'Create success'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'warning',
          message: `${error.message}`
        });
      }
    });
  }

}
