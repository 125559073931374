import { HttpService, IHttpServiceOptions } from '../Core';

import { CompetitionHttpService, ICompetitionHttpService } from './Competition';
import { FeedHttpService, IFeedHttpService } from './Feed';
import { GlobalHttpService, IGlobalHttpService } from './Global';
import { INotificationHttpService, NotificationHttpService } from './Notification';
import { IProfileHttpService, ProfileHttpService } from './Profile';
import { IPromotionHttpService, PromotionHttpService } from './Promotion';
import { IReportCategoryHttpService, ReportCategoryHttpService } from './ReportCategory';
import { IReportPostHttpService, ReportPostHttpService } from './ReportPost';
import { IReportComprtitionHttpService, ReportCompetitionHttpService } from './ReportCompetition';
import { ITemplateEmailHttpService, TemplateEmailHttpService } from './TemplateEmail';
import { IUserHttpService, UserHttpService } from './User';
import { ICategoryHttpService, CategoryHttpService } from './Category';
import { IBannerHttpService, BannerHttpService } from './Banner';
import { IVersionHttpService, VersionHttpService } from './Version';
import { IWalletHttpService, WalletHttpService } from './Wallet';
import { IMaintenanceHttpService, MaintenanceHttpService } from './Maintenance';
import { IBadwordHttpService, BadwordHttpService } from './Badword';
import { IGifHttpService, GifHttpService } from './Gif';
import { IGifCategoryHttpService, GifCategoryHttpService } from './GifCategory';
import { IStickerCategoriesHttpService, StickerCategoriesHttpService } from './StickerCategories';
import { IStickerHttpService, StickerHttpService } from './Sticker';

export interface ISFRHttpClient {
  global: IGlobalHttpService;
  user: IUserHttpService;
  profile: IProfileHttpService;
  promotion: IPromotionHttpService;
  competition: ICompetitionHttpService;
  feed: IFeedHttpService;
  reportCategory: IReportCategoryHttpService;
  reportPost: IReportPostHttpService;
  reportCompetition: IReportComprtitionHttpService;
  notification: INotificationHttpService;
  templateEmail: ITemplateEmailHttpService;
  category: ICategoryHttpService;
  banner: IBannerHttpService;
  version: IVersionHttpService;
  wallet: IWalletHttpService;
  maintenance: IMaintenanceHttpService;
  badword: IBadwordHttpService;
  gif: IGifHttpService;
  gifCategory: IGifCategoryHttpService;
  sticker: IStickerHttpService;
  stickerCategories: IStickerCategoriesHttpService;
}

export function SFRHttpClient(options: IHttpServiceOptions): ISFRHttpClient {

  const httpService = HttpService(options);

  return {
    global: GlobalHttpService({ httpService}),
    user: UserHttpService({ httpService}),
    profile: ProfileHttpService({ httpService}),
    promotion: PromotionHttpService({ httpService, mock: true }),
    competition: CompetitionHttpService({httpService}),
    feed: FeedHttpService({ httpService }),
    reportCategory:  ReportCategoryHttpService({httpService}),
    reportPost: ReportPostHttpService({httpService}),
    reportCompetition: ReportCompetitionHttpService({httpService}),
    notification: NotificationHttpService({httpService}),
    templateEmail: TemplateEmailHttpService({httpService}),
    category: CategoryHttpService({httpService}),
    banner: BannerHttpService({ httpService }),
    version: VersionHttpService({ httpService }),
    wallet: WalletHttpService({ httpService}),
    maintenance: MaintenanceHttpService({ httpService }),
    badword: BadwordHttpService({ httpService }),
    gif: GifHttpService({ httpService}),
    gifCategory: GifCategoryHttpService({ httpService }),
    sticker: StickerHttpService({ httpService}),
    stickerCategories: StickerCategoriesHttpService({ httpService})
  };
}
