import Vue from 'vue';
import Component from 'vue-class-component';
import './view.scss';

@Component({
  template: require('./view.html'),
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className;
      }

      return 'svg-icon';
    }
  }
})

export class SvgIcon extends Vue {
}
