import { Form } from 'element-ui';
import { cloneDeep, flatten, includes } from 'lodash';
import { IVersion, IVersionCreateForm, VersionCreateDefaultForm } from 'root/models';
import { isArray, isUndefined } from 'util';
import { ruleRequired } from 'root/helpers';
import { sfrHttpClient } from 'root/api/http';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: require('./view.html'),
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean
  },
  watch: {
    data() {
      this.dataChange();
    }
  },
})
export class VersionForm extends Vue {
  public $refs: {
    form: Form;
  };
  public form: IVersionCreateForm = VersionCreateDefaultForm();
  public data: IVersion;
  public loading: boolean;
  public get rules() {
    return {
      'version': [
        ruleRequired('Please input version')
      ],
      'platform': [
        ruleRequired('Please input platform')
      ]
    };
  }

  public async isValidForm() {
    const promises = flatten(Object.values(this.$refs).map((element: any) => {
      if (isArray(element)) {
        return (<any> element).map((form) => {
          return new Promise((resolve, _reject) => {
            form.validate((valid, result) => {
              resolve({ valid, result });
            });
          });
        });
      }

      return new Promise((resolve, _reject) => {
        element.validate((valid, result) => {
          resolve({ valid, result });
        });
      });
    }));

    return Promise.all(promises).then((arrayResolve) => {
      const errors = arrayResolve.map((resolve) => {
        if (isUndefined(resolve) || (<any> resolve).valid) {
          return;
        }
        this.$message({
          type: 'error',
          message: Object.values((<any> resolve).result)[0][0].message
        });

        return false;
      });

      return !includes(errors, false);
    });
  }

  public async handleSubmit() {
    const valid = await this.isValidForm();
    if (!valid) {
      return;
    }

    this.$emit('submit', this.form);

  }

  public mounted() {
    this.dataChange();
  }

  public dataChange() {
    if (!this.data) {
      return;
    }
    this.form = { ...this.data };
  }
}
