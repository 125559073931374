import Vue from "vue";
import Component from "vue-class-component";
// import '../../Container/CreateCompetition/styles.scss';
import { ruleEmail, ruleRequired, rulePhoneNumber } from "root/helpers";
import { SFRCheckbox } from "root/components/SFRCheckbox";
import { Form } from "element-ui";
import { cloneDeep } from "lodash";
import { IState } from "root/store";
import { mapState } from "vuex";
import { IApplicantCreateForm } from 'root/models';
@Component({
  template: require("./view.html"),
  components: {
    "sfr-checkbox": SFRCheckbox
  },
  props: {
    form: Object
  },

  computed: {
    ...mapState({
      countryOptions: (state: IState) => {
        const countries = cloneDeep(state.global.globalConfig.countries);
        if (countries.length < 1) {
          return [];
        }
        return countries.map((country: any) => {
          return {
            value: country.phoneCode,
            label: country.phoneCode
          };
        });
      }
    })
  }
})
export class Step1 extends Vue {
  public $refs: {
    form: Form;
  };
  public form: IApplicantCreateForm;
  // public above18: Boolean = true;

  public get rules() {
    return {
      required: function(error = "filed is required") {
        return ruleRequired(error);
      },
      email: ruleEmail("email.valid.signup")
    };
  }
  public handleAbove18(value) {
    this.form.applicant.above18 = value;
  }
}
