export enum PromotionType {
  Event = 'event',
  Competition = 'competition'
}
export interface IPromotionCreateForm {
  title: string;
  thumb_url?: string;
  subtitle?: string;
  type: PromotionType;
  startDate: Date;
  endDate: Date;
  // competition?: ICompetition;
  // event?: IEvent;
}

export interface IPromotion extends IPromotionCreateForm {
  _id: number;
}

export const PromotionCreateDefaultForm = (): IPromotionCreateForm => {
  return {
    title: '',
    thumb_url: '',
    subtitle: '',
    type: undefined,
    startDate: undefined,
    endDate: undefined
    // event: undefined
  };
};

export const promotionDetailDefault = (): IPromotion => {
  return {
    _id: null,
    title: '',
    thumb_url: '',
    subtitle: '',
    type: undefined,
    startDate: undefined,
    endDate: undefined
    // event: undefined
  };
};
