export enum CategoryType {
  User = "user",
  Post = "post",
  Competition = "competition"
}
export enum OrderCategoryType {
  ByFollower = "by follower",
  NoOrder = "no order",
  ByAlphabet = "by alphabet",
  Randomize = "randomize"
}

export interface ICategoryCreateForm {
  title: string;
  type: CategoryType;
  orderBy: OrderCategoryType;
  listIds: string[];
}

export interface ICategory extends ICategoryCreateForm {
  id: number;
  position: number;
  isSpecial: boolean;
}

export const CategoryCreateDefaultForm = (): ICategoryCreateForm => {
  return {
    title: '',
    type: CategoryType.User,
    orderBy: OrderCategoryType.NoOrder,
    listIds: []
  };
};
