import { Notification } from './Container';
import { NotificationCreate } from './Container/Create';
import { NotificationDetail } from './Container/Detail';
import { NotificationList } from './Container/List';
import { NotificationUpdate } from './Container/Update';

export enum RouterDictionaryNotification {
  Notification = '/notification',
  NotificationCreate = '/notification/create',
  NotificationDetail = '/notification/:NotificationId',
  NotificationUpdate = '/notification/update/:NotificationId'
}

export const NotificationRoutes = {
  path: RouterDictionaryNotification.Notification,
  component: Notification,
  children: [
    {
      path: '',
      component: NotificationList,
      name: 'Notification List',
    },
    {
      path: RouterDictionaryNotification.NotificationCreate,
      component: NotificationCreate,
      name: 'Notification List Create'
    },
    {
      path: RouterDictionaryNotification.NotificationDetail,
      component: NotificationDetail,
      name: 'Notification List Detail'
    },
    {
      path: RouterDictionaryNotification.NotificationUpdate,
      component: NotificationUpdate,
      name: 'Notification List Update'
    }
  ]
};
