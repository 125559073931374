import { IPaginationState, PaginationState } from '../types';

export interface ICRUDState<T extends object, D extends object = T, F = any> {
  data: T[];
  detailData: D[];
  loading: boolean;
  detailLoading: boolean;
  editting: boolean;
  filterResultIDs: string[];
  filterParams: F;
  pagination: IPaginationResult;
}

export function PaginationResult(
  params?: IPaginationState): IPaginationResult {
  return {
    total: 0,
    page: 1,
    size: 20,
    ...PaginationState(params),
  };
}

export function CRUDState<T extends object, D extends object = T>(params?: IPaginationState): ICRUDState<T, D> {
  return {
    data: [],
    detailData: [],
    loading: false,
    detailLoading: false,
    editting: false,
    filterResultIDs: [],
    filterParams: {},
    pagination: PaginationResult(params),
  };
}

export interface IPaginationResult {
  size: number;
  page: number;
  total: number;
}

export interface ICRUDFilterState<T extends object> {
  data: T[];
  pagination: IPaginationResult;
}

export interface IOptionCRUD {
  keyState?: string;
}

export function crudMutations<T extends object, D extends object = T, F = any>(
  name: string,
  idName = 'id', options: IOptionCRUD = {}) {
  const { keyState } = options;

  function setDataState(state: ICRUDState<T, D>, key: string, data: any) {
    if (keyState) {
      return state[keyState][key] = data;
    }

    return state[key] = data;
  }

  function updateModels(state: ICRUDState<T, D>, models: T[]) {
    if (!models || models.length === 0) {
      return;
    }
    const ids = models.map((m) => m[idName]);

    state.data = state.data.filter((m) => !ids.includes(m[idName]))
      .concat(models);
  }

  return {
    [`${name}FilterModels`](state: ICRUDState<T, D>, params: ICRUDFilterState<T>) {
      state.filterResultIDs = params.data.map((m) => m[idName]);

      updateModels(state, params.data);
      state.pagination = {
        ...state.pagination,
        ...params.pagination
      };
    },
    [`${name}Loading`](state: ICRUDState<T, D>) {
      setDataState(state, 'loading', true);
    },
    [`${name}Loaded`](state: ICRUDState<T, D>) {
      setDataState(state, 'loading', false);
    },
    [`${name}RefreshModels`](state: ICRUDState<T, D>, models: T[]) {
      setDataState(state, 'data', models);
    },
    [`${name}UpdateModels`](state: ICRUDState<T, D>, models: T[]) {
      updateModels(state, models);
    },
    [`${name}RemoveModelByIDs`](state: ICRUDState<T, D>, ids: string[]) {
      ids = ids.map((e) => e.toString());

      state.data = state.data
        .filter((m) => !ids.includes(m[idName].toString()));
      state.detailData = state.detailData
      .filter((m) => !ids.includes(m[idName].toString()));
    },
    [`${name}PaginationChange`](state: ICRUDState<T, D>, pagination: IPaginationState) {
      setDataState(state, 'pagination', pagination);
    },
    [`${name}PaginationReset`](state: ICRUDState<T, D>) {
      setDataState(state, 'pagination', PaginationState());
    },
    [`${name}FilterChange`](state: ICRUDState<T, D>, params: F) {
      setDataState(state, 'filterParams', params);
    },
    [`${name}DetailLoading`](state: ICRUDState<T, D>) {
      state.detailLoading = true;
    },
    [`${name}DetailLoaded`](state: ICRUDState<T, D>) {
      state.detailLoading = false;
    },
    [`${name}RefreshDetails`](state: ICRUDState<T, D>, models: D[]) {
      state.detailData = models;
    },
    [`${name}UpdateDetails`](state: ICRUDState<T, D>, models: D[]) {
      if (!models || models.length === 0) {
        return;
      }
      const ids = models.map((m) => m[idName]);

      state.detailData = state.detailData.filter((m) => !ids.includes(m[idName]))
        .concat(models);
    },
  };
}
