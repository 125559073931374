import { PaginationState, IPaginationState } from 'root/store';
import { computedPagination } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryCompetition } from '../..';
import { CompetitionActionType, COMPETITION_MODULE_NAME, CompetitionMutationType } from '../../Store/types';
import { FilterForm } from '../../Component/FilterForm';
import './view.scss';
import moment from 'moment';

@Component({
  template: require('./view.html'),

  computed: {
    ...computedPagination.call(this, COMPETITION_MODULE_NAME)
  },
  components: {
    'filter-form': FilterForm,
  },
})

export class CompetitionList extends Vue {
  public loadingChangeStatus: boolean = false;
  public pagination: IPaginationState;
  public getListCompetitions() {
    this.$store.dispatch(CompetitionActionType.CompetitionFilter);
  }

  public changeStatusCompetition(id, statusCompetition) {
    const form = {
      id: id,
      statusCompetition: statusCompetition
    }
    this.loadingChangeStatus = true;
    this.$store.dispatch(CompetitionActionType.UpdateStatusCompetition, form).then((res) => {
      this.$store.commit(CompetitionMutationType.UpdateCompetition, {id, statusCompetition})
      this.getListCompetitions()
      this.loadingChangeStatus = false;
      this.$message({
        type: 'success',
        message: 'Change status successful!'
      });
    }).catch((error) => {
      this.loadingChangeStatus = false;
      this.$message({
        type: 'error',
        message: `${error.message}`
      });
    });
  }

  public deactiveCompetition(id) {
    this.loadingChangeStatus = true;
    this.$store.dispatch(CompetitionActionType.DeactiveCompetition, id).then((res) => {
      this.getListCompetitions()
      this.loadingChangeStatus = false;
      this.$message({
        type: 'success',
        message: res.status === 'deleted' ? 'Deactive successful!' : 'Reactive successful!'
      });
    }).catch((error) => {
      this.loadingChangeStatus = false;
      this.$message({
        type: 'error',
        message: `${error.message}`
      });
    });
  }

  public handleResetList() {
    this.$nextTick(() => {
      this.$store.dispatch(CompetitionActionType.CompetitionFilter, {
        params: {
          pagination: PaginationState({
            page: 1
          })
        }
      });
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(CompetitionActionType.CompetitionFilter, {
        params: {
          pagination: PaginationState()
        }
      });
    });
  }

  public formatDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  public parseType(status, statusCompetition) {
    return status === 'active' && statusCompetition === 'approved' ? 'success' :
    status === 'deleted' && statusCompetition === 'approved' ? 'danger' : 'success';
  }

  public sort(field, type) {
    this.$store.dispatch(CompetitionActionType.CompetitionFilter, {
      params: {
        pagination: PaginationState(),
        filterParams: {
          sortByField: field, 
          sortBy: type,
        }
      }
    })
  }
}
