import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Form } from 'element-ui';
import { ruleEmail, ruleRequired } from 'root/helpers';
import { ICompetitionCreateForm, ICompetitionWinner, ICompetitionWinnerDefault, statusPayment, ICompetition } from 'root/models';
import { SFRCheckbox } from 'root/components/SFRCheckbox';
import moment from 'moment';
import { mapState } from "vuex";
import { IState } from "root/store";
import { CompetitionActionType, CompetitionMutationType } from '../../Store/types';
// import { RouteDictionaryWallet } from "root/pages/Wallet";

@Component({
  template: require("./view.html"),
  components: {
    "sfr-checkbox": SFRCheckbox,
    "step-1": Step1,
    "step-2": Step2,
    "step-3": Step3,
  },
  props: {
    isUpdate: Boolean,
    form: Object
  },
  computed: {
    ...mapState({
      isSelectTimeline: (state: IState) => state.competition.isSelectTimeline,
      isSubmited: (state: IState) => state.competition.isSubmited
    })
  }
})
export class FormCompetition extends Vue {
  public $refs: {
    form: Form;
  };
  public isUpdate: boolean;
  public isSelectTimeline: boolean;
  public statusPayment: statusPayment;
  public form: ICompetitionCreateForm;
  public data: ICompetition;
  public competitionId: string;
  public get rules() {
    return {
      required: ruleRequired("should.update.correctly"),
      email: ruleEmail("email.valid.signup")
    };
  }
  public step: number = 1;

  public prize: ICompetitionWinner = ICompetitionWinnerDefault();

  public next() {
    this.$refs.form.validate(async valid => {
      if (valid) {
        if ( this.step == 2 ) {
          if( !!this.form ) {
            let item = this.form.round;
            let i;
            let flag = false;
            let now = new Date()
            for(i = 0 ; i < item.length ; i++ ){
              if (!this.isSelectTimeline) {
                flag = true;
                this.$message({
                  type: 'error',
                  message: `Please select timeline plan`,
                  duration: 5000,
                });
                break;
              } else if (moment(now).isAfter(item[i].registrationPeriod)) {
                flag = true;
                this.$message({
                  type: 'error',
                  message: `${this.form.round[i].roundName}: RegistrationPeriod must be later than current time`,
                  duration: 5000,
                });
                break;
              } else if(moment(item[i].registrationPeriod).isAfter(item[i].votingPeriod) || moment(item[i].registrationPeriod).isSame(item[i].votingPeriod)){
                flag = true;
                this.$message({
                  type: 'error',
                  message: `${this.form.round[i].roundName}: Round Voting Period must be later than Round Registration Period`,
                  duration: 5000,
                });
                break;
              } else if ( item[i].isReUpload && (moment(item[i].reUploadDate).isAfter(item[i].votingPeriod) || moment(item[i].reUploadDate).isSame(item[i].votingPeriod)) ) {
                flag = true;
                this.$message({
                  type: 'error',
                  message: `${this.form.round[i].roundName}: Round Extra Voting Period must be later than Round Extra Reupload`,
                  duration: 5000,
                });
                break;
              } else if (moment(item[i].votingPeriod).isAfter(item[i].announcement) || moment(item[i].votingPeriod).isSame(item[i].announcement)) {
                flag = true;
                this.$message({
                  type: 'error',
                  message: `${this.form.round[i].roundName}: Round Announcement must be later than Round Voting Period`,
                  duration: 5000,
                });
                break;
              } else if (moment(item[i].reUploadDate).isAfter(item[i].announcement) || moment(item[i].reUploadDate).isSame(item[i].announcement)) {
                flag = true;
                this.$message({
                  type: 'error',
                  message: `${this.form.round[i].roundName}: Round Extra Announcement must be later than Round Extra Reupload`,
                  duration: 5000,
                });
                break;
              } else if (item[i + 1] && item[i].announcement && item[i + 1].reUploadDate &&  i + 1 < item.length && (moment(item[i].announcement).isAfter(item[i + 1].reUploadDate) || moment(item[i].announcement).isSame(item[i + 1].reUploadDate))) {
                flag = true;
                this.$message({
                  type: 'error',
                  message: `${this.form.round[i + 1].roundName}: Round ${this.form.round[i + 1].roundName} extra Reupload must be later than Round ${this.form.round[i].roundName} Announcement`,
                  duration: 5000,
                });
                break;
              } else if (item[i + 1] && item[i].announcement && item[i + 1].votingPeriod && i + 1 < item.length && !(item[i + 1].isReUpload) && (moment(item[i].announcement).isAfter(item[i + 1].votingPeriod) || moment(item[i].announcement).isSame(item[i + 1].votingPeriod))) {
                flag = true;
                this.$message({
                  type: 'error',
                  message: `${this.form.round[i + 1].roundName}: Round ${this.form.round[i + 1].roundName} extra Voting Period must be later than Round ${this.form.round[i].roundName} Announcement`,
                  duration: 5000,
                });
                break;
              }
            }
            if (!flag) {
              // await this.submit();
              this.step++;
              window.scrollTo(0, 0);
            }
          }
        } else {
          this.step++;
          window.scrollTo(0, 0);
        }
      } 
      else {
        let elementError =  await document.getElementsByClassName('is-error');
        if( elementError && elementError !== null) 
        await elementError[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      }
    });
  }

  public prev() {
    this.$refs.form.validate(async valid => {
      window.scrollTo(0, 0);
        if (this.step >= 2) {
          this.step--;
        }
    });
  }

  public mounted() {
    if(this.isUpdate) {
      if(this.$route.query.step) {
        this.step = Number(this.$route.query.step);
      }
      this.$store.commit(CompetitionMutationType.SetSelectedTimeline);
    }
  }
  public async handleDelete() {
    const id = this.$route.params.competitionId;
    this.$store.dispatch(CompetitionActionType.CompetitionDelete, { competitionId: id,
      onSuccess: () => {
        this.$message({
          type: "success",
          message: "delete successful"
        });
        this.$router.push('/competition');
      },
      onFailure: (error) => {
        this.$message({
          type: "error",
          message: "delete failure"
        });
      }
    })
  }

  public async submit() {
    this.$store.commit(CompetitionMutationType.SetSubmited)
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        this.$emit('submit', (res) => {          
          if(res.code === 'balance_is_not_enough') {
            this.$store.commit(CompetitionMutationType.SetNotSubmit)
            // this.openTopup(res.missing_balance)
          } else {
            this.data = res;
            this.competitionId = res.id;
            this.$store.commit(CompetitionMutationType.SetNotSubmit)
            this.$store.commit(CompetitionMutationType.UnsetSelectedTimeline);
          }

        })        
        await this.$refs.form.clearValidate();
        return;
      } else {
        this.$store.commit(CompetitionMutationType.SetNotSubmit)
      }
    });
    
    return;
    // });
  }

  // public openTopup(missing_balance) {
  //   let message_1 = `Your wallet balance is missing `
  //   let message_2 = ` to proceed`
  //   const h = this.$createElement;

  //   this.$msgbox({
  //     message: h('p', null, [
  //       h('span', null, message_1),
  //       h('i', { class: 'font-family-bold sfr-icon  sfr-icon__music-price-black', style: 'width: 12px; height: 12px;' }, ''),
  //       h('span', { class: 'font-family-bold' } , `${missing_balance ? missing_balance.toFixed(2) : 0}`),
  //       h('span', null, message_2),
  //       h('br', null, null),
  //       h('p', null, 'Do you want to topup your wallet now?')
  //     ]),
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes',
  //     cancelButtonText: 'No'
  //   }).then(action => {
  //     this.$router.push(RouteDictionaryWallet.WalletTopupRouter)
  //   })
  // }
}
