import { Storage } from 'aws-amplify';
import { IFile } from '../../models/File';

export function uploadImageS3(prefix: string, file: IFile): Promise<object> {
  return Storage.put(
    `${prefix}-${Date.now()}.${file.name}`,
    file,
    {
      contentType: 'img/*'
    }
  );
}

export function getPublicLink(fileName: string): string {
  const s3Link: string = `https://s3-${process.env.AWS_REGION}.amazonaws.com/${process.env.AWS_BUCKET_NAME}/public/`;

  return s3Link + fileName;
}
