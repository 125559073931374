import { Form } from 'element-ui';
import { LinkPreviewCard } from 'root/components/LinkPreviewCard';
import { GifCategoryCreateFormDefault, IGifCategoryCreateForm, IGifCategory } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import './view.scss';
import { ruleRequired } from 'root/helpers';
import { getPublicLink, uploadImageS3 } from 'root/services/upload';
import { GifCategoryActionType } from '../../Store/types';
import { RouterDictionaryGifCategory } from '../../';
@Component({
  template: require('./view.html'),
  components: {
    LinkPreviewCard
  },
  props: {
    data: Object,
    loading: Boolean,
    isCreate: Boolean,
  },
  watch: {
    data() {
      this.dataChange();
    }
  },
  
})
export class GifForm extends Vue {
  public $refs: {
    form: Form;
  };
  public loading: boolean;
  public loadingCreate: boolean = false;
  public form: IGifCategoryCreateForm = GifCategoryCreateFormDefault();
  public data: IGifCategory;
  public imageUploading: boolean = false;
  public imageGifsUploading: boolean = false;
  public isCreate: boolean;

  public mounted() {
    this.dataChange();
  }

  public dataChange() {
    if (!this.data) {
      return;
    }
    if( this.data.id) {
      const { id: remove, ...dataUpdate } = this.data;
      this.form = { ...dataUpdate };
    }else {
      this.form = {...this.data};
    }    
  }

  public get rules() {
    return {
      required: error => {
        return ruleRequired(error);
      },
      'name': [
        ruleRequired('Please input name')
      ],
    };
  }

  public async handleUpdate() {
    this.$refs.form.validate((valid) => {
      if (!valid) {
        return;
      }
      else {
        this.loadingCreate = true;
        const body = {
          avatarUrl: this.form.avatarUrl,
          name: this.form.name,
          createdByName: this.form.createdByName,
        }
        this.$store.dispatch(GifCategoryActionType.GifCategoryUpdate, {
        id: this.$route.params.gifID,
        form: body,
        onSuccess: () => {
          this.$message({
            type: 'success',
            message: 'Update successful'
          });
          this.$router.push({
            path: RouterDictionaryGifCategory.GifCategory
          });
        },
        onFailure: (error) => {
          this.$message({
            type: 'error',
            message: `${error.message}`
          });
        }
      });
      this.loadingCreate = false;
      }
    });
  }

  public async handleCreate() {
    this.$refs.form.validate((valid) => {      
      if (!valid) {
        return;
      }
      else {
        this.loadingCreate = true;
        const body = {
          avatarUrl: this.form.avatarUrl,
          name: this.form.name,
          createdByName: this.form.createdByName,
        }
        this.$store.dispatch(GifCategoryActionType.GifCategoryCreate, {
          form: body,
          onSuccess: () => {
            this.$router.push({
              path: RouterDictionaryGifCategory.GifCategory
            });
            this.$message({
              type: 'success',
              message: 'Create success'
            });
          },
          onFailure: () => {
            this.$message({
              type: 'error',
              message: 'Create failed'
            });
          }
        });
        this.loadingCreate = false;
      }
    });
  }


  public handleRemove() {
      this.form.avatarUrl = '';
  }
  
  public async handlePreviewImage(file) {
    this.imageUploading = true;
    await uploadImageS3('user/avatar/', file.raw).then(async (result: any) => {
      this.imageUploading = false;
      this.form.avatarUrl = getPublicLink(result.key);    
      this.$message({
        type: 'success',
        message: 'Upload image successful'
      });
    }).catch((error) => {
      this.imageUploading = false;
      console.log(error);
      this.$message({
        type: 'error',
        message: 'Upload image failed'
      });
    });
  }
}
