export const VERSION_MODULE_NAME = 'version';

export enum VersionActionType {
  VersionCreate = 'versionCreate',
  VersionGetById = 'versionGetById',
  VersionFilter = 'versionFilter',
  VersionDelete = 'versionDelete',
  VersionUpdate = 'versionUpdate',
  VersionPaginationChange = 'versionPaginationChange'
}

export enum VersionMutationType {
  VersionLoading = 'versionLoading',
  VersionLoaded = 'versionLoaded',
  VersionRefreshModels = 'versionRefreshModels',
  VersionFilterModels = 'versionFilterModels',
}

export enum VersionGetterType {
  Version = 'version',
  Versions = 'versions',
  VersionFilterResults = 'versionFilterResults'
}
