import { isEmpty } from 'lodash';
import { SearchUser } from 'root/models/Search';
import { PaginationState } from 'root/store';
import { getSelectArrayOfArray } from 'root/utils';
import Vue from 'vue';
import Component from 'vue-class-component';
import { UserActionType, USER_MODULE_NAME } from '../../Store/types';
import './style.scss';

@Component({
  props: {
    sorting: {
      type: String,
      default: ''
    }
  },
  template: require('./view.html'),
})

export class SearchForm extends Vue {

  public searchFields: string[] = SearchUser;
  public roles: object[] =
    getSelectArrayOfArray(this.$store.state.global.globalConfig.role);
  public professions: object[] =
    getSelectArrayOfArray(this.$store.state.global.globalConfig.profession);
  public sizes: number[] = getSelectArrayOfArray([20, 50, 100, 200]);

  public size: number = 20;
  public searchText: string = '';
  public selectedRoles: string[] = [];
  public selectedProfessions: string[] = [];
  public sorting: string;

  public get filterParams() {
    const roleParams: string = !isEmpty(this.selectedRoles)
      ? this.selectedRoles.join(',') : '';
    const professionParams: string = !isEmpty(this.selectedProfessions)
      ? this.selectedProfessions.join(',') : '';

    return {
      name: this.searchText,
      roles: roleParams,
      professions: professionParams,
      sort: this.sorting
    };
  }

  public handleSizeChange() {
    this.$store.dispatch(UserActionType.UserPaginationChange, {
      pagination: PaginationState({
        size: this.size
      })
    });
  }

  public handleSearchBtn() {
    this.$store.dispatch(UserActionType.UserFilter, {
      params : {
        filterParams: this.filterParams,
        pagination: PaginationState({
          size: this.size
        })
      }
    });
  }

  public handleResetBtn() {
    this.searchText = '';
    this.selectedProfessions = [];
    this.selectedRoles = [];

    this.$store.commit(`${USER_MODULE_NAME}FilterChange`, this.filterParams);

    this.$emit('reset');

  }

}
