import Vue from 'vue';
import Component from 'vue-class-component';
import CountTo from 'vue-count-to';
import './view.scss';

@Component({
  template: require('./view.html'),
  props: {
    icon: String,
    className: String,
    title: String,
    value: Number
  },
  components: {
    'count-to': CountTo
  }
})

export class Panel extends Vue {

}
