import { IUser } from './User';

export enum ContentType {
  Normal = 'normal',
  ReportEvent = 'report_event',
  ReportCompetition = 'report_competition',
  ReportNormal = 'report_normal',
}

export enum FeedStatus {
  Active = 'active',
  Suspended = 'suspended',
  Deleted = 'deleted'
}

export interface IAttachment {
  fileName: string;
  fileType: string;
  fileSize: number;
  fileUrl: string;
  thumbnailUrl: string;
  customThumbnailUrl: string;
  metadata?: IAttachmentMetaData;
  thumbnail?: File;
  fileUrl_540?: string;
  fileUrl_720?: string;
  fileInputUrl?: string;
  fileOutputUrl?: string;
}
export interface IAttachmentMetaData {
  width: number;
  height: number;
}

export interface IComment {
  id: string;
  content: string;
  owner: IUser;
  createdAt: Date;
  childComments: IComment[];
}
export interface IFeedCreateForm {
  title: string;
  attachments?: IAttachment[];
  comments: IComment[];
  tagFriendIds?: string[];
  owner: IUser;
  tagFriends: IUser[];
  isPinned: boolean;
  isAdvertisement: boolean;
  contentType: ContentType;
  status: FeedStatus;
  isFeatured: boolean;
  displayText?: string;
  isDownload?: boolean;
}

export interface IFeed extends IFeedCreateForm {
  id: number;
  createdDate: Date;
  updatedDate: Date;
  views: number;
  countLike: number;
  countRepost: number;
  countComment: number;
}

export const FeedCreateDefaultForm = (): IFeedCreateForm => {
  return {
    title: '',
    attachments: undefined,
    tagFriendIds: [],
    owner: undefined,
    tagFriends: [],
    isPinned: undefined,
    isAdvertisement: undefined,
    contentType: undefined,
    comments: [],
    status: FeedStatus.Active,
    isFeatured: undefined,
    displayText: '',
    isDownload: false
  };
};

export interface IExternalLink {
  title?: string;
  description?: string;
  thumbnailUrl?: string;
  link: string;
}