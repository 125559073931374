import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { IState } from 'root/store';
import { GifCategoryActionType } from '../../Store/types';
import { IGifCategory, IGifCreateForm, GifCreateFormDefault } from 'root/models';
import { GifCategoryGetterType } from '../../Store/types';
import { Form } from 'element-ui';
import { ruleRequired } from 'root/helpers';
import { getPublicLink, uploadImageS3 } from 'root/services/upload';
import { flatten, includes } from 'lodash';
import { isArray, isUndefined } from 'util';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      loading: (state: IState) => state.gifCategory.detailLoading,
    })
  },
})
export class GifCategoryDetail extends Vue {
  public $refs: {
    form: Form;
  };
  public isOpenAddGif : boolean = false;
  public loading: boolean;
  public imageUploading: boolean = false;
  public uploading: boolean = false;
  public dialogVisibleDetail:boolean = false;
  public form: IGifCreateForm = GifCreateFormDefault();
  public gifDetail: IGifCreateForm = GifCreateFormDefault();
  public get gifID(): string {
    return this.$route.params.gifID;
  }

  public get detail(): IGifCategory {
    return this.$store.getters[GifCategoryGetterType.GifCategory](this.gifID);
  }
  public mounted() {    
    this.$nextTick(() => { 
      this.$store.dispatch(GifCategoryActionType.GifCategoryGetById, {
        id: this.gifID
      });
    });
  }

  public handleDetailGif(item) {
    this.dialogVisibleDetail = true;
    this.gifDetail = item;
  }

  public handleClosePopupDetail() {
    this.dialogVisibleDetail = false;
  }

  public deleteGif(value){
    const id = value.id;
    this.$confirm('This will permanently delete this Gif. Continue?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.confirmDelete(id);
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
  }

  public confirmDelete(id) {
    this.$store.dispatch(GifCategoryActionType.GifDelete, {
      id: id,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
        this.$store.dispatch(
          GifCategoryActionType.GifCategoryGetById, {
            id: this.gifID
          }
        );
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public get rules() {
    return {
      required: error => {
        return ruleRequired(error);
      },
      'category': [
        ruleRequired('Please input category')
      ],
    };
  }

  public handlePreviewImage(file) {
    this.imageUploading = true;
    uploadImageS3('user/avatar/', file.raw).then(async (result: any) => {
      this.imageUploading = false;
      this.form.url = getPublicLink(result.key);
      this.$message({
        type: 'success',
        message: 'Upload successful'
      });
    }).catch((error) => {
      this.imageUploading = false;
      console.log(error);
      this.$message({
        type: 'error',
        message: 'Upload failed'
      });
    });
  }

  public async isValidForm() {
    const promises = flatten(Object.values(this.$refs).map((element: any) => {
      if (isArray(element)) {
        return (<any> element).map((form) => {
          return new Promise((resolve, _reject) => {
            form.validate((valid, result) => {
              resolve({ valid, result });
            });
          });
        });
      }
      return new Promise((resolve, _reject) => {
        element.validate((valid, result) => {
          resolve({ valid, result });
        });
      });
    }));

    return Promise.all(promises).then((arrayResolve) => {
      const errors = arrayResolve.map((resolve) => {
        if (isUndefined(resolve) || (<any> resolve).valid) {
          return;
        }
        this.$message({
          type: 'error',
          message: Object.values((<any> resolve).result)[0][0].message
        });
        return false;
      });
      return !includes(errors, false);
    });
  }

  public handleClosePopup() {
    this.isOpenAddGif = false;
    this.form = {
      "gifCategoryId": '',
      "hint": '',
      "url": '',
      "name": '',
      "category": null
    }
  } 

  public handleRemove(){
    this.form.url = "";
  }

  public async handleSubmit() {
    const valid = await this.isValidForm();
    if (!valid) {
      return;
    } 
    const body = {
      "gifCategoryId": this.gifID,
      "hint": this.form.hint,
      "url": this.form.url,
      "name": this.form.name,
    }
    this.uploading = true;
    this.$store.dispatch(GifCategoryActionType.GifCreate, {
      form: body,
      onSuccess: () => {
        this.uploading = false;
        this.$message({
          type: 'success',
          message: 'Create success'
        });
        this.$store.dispatch(
          GifCategoryActionType.GifCategoryGetById, {
            id: this.gifID
          }
        );
        this.handleClosePopup();
      },
      onFailure: () => {
        this.$message({
          type: 'error',
          message: 'Create failed'
        });
      }
    });
  }
}

