export interface IBadwordCreateForm {
  language: string,
  badWord: string[]
}

export interface IBadword extends IBadwordCreateForm {
  id: string
}

export const BadwordCreateDefaultForm = (): IBadwordCreateForm => {
  return {
    language: '',
    badWord: []
  };
};
