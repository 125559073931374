import { omit } from 'lodash';
import { ITemplateEmail } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';
export type ITemplateEmailHttpService = ICRUDHttpService<ITemplateEmail>;

export function TemplateEmailHttpService(options: IHttpServiceClientOptions): ITemplateEmailHttpService {

  const basePath = '/admin/template-emails';

  return CRUDHttpService({
    ...options,
    basePath,
    serializeUpdate
  });
}

const serializeUpdate = (templateEmail: ITemplateEmail): any => {

  const newTemplateEmail = omit(templateEmail, ['id']);

  return {
    ...newTemplateEmail
  };
};
