import { User } from './Container';
import { UserCreate } from './Container/Create';
import { UserDetail } from './Container/Detail';
import { UserList } from './Container/List';
import { UserUpdate } from './Container/Update';

export enum RouterDictionaryUser {
  User = '/user',
  UserCreate = '/user/create',
  UserDetail = '/user/:userId',
  UserUpdate = '/user/update/:userId',
}

export const UserRoutes = {
  path: RouterDictionaryUser.User,
  component: User,
  children: [
    {
      path: '',
      component: UserList,
      name: 'User List',
    },
    {
      path: RouterDictionaryUser.UserCreate,
      component: UserCreate,
      name: 'User Create'
    },
    {
      path: RouterDictionaryUser.UserDetail,
      component: UserDetail,
      name: 'User Detail'
    },
    {
      path: RouterDictionaryUser.UserUpdate,
      component: UserUpdate,
      name: 'User Update'
    }
  ]
};
