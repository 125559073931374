import Vue from "vue";
import Component from "vue-class-component";
import _ from "lodash";
import { omit } from "lodash";
import { uploadToAWS } from "root/services";
import { Form } from "element-ui";
import { IState } from "root/store";
import { mapState } from "vuex";
import { removeEmptyField } from "root/helpers";
import { stripObject } from "@hgiasac/helper";
import "./view.scss";
import { CompetitionActionType, CompetitionMutationType } from "../../Store/types";
import { ICompetition, PrizeType } from "root/models";
import { FormCompetition } from "root/pages/Competition/Component/FormCompetition";
import { ActionType } from "root/store";

@Component({
  template: require("./view.html"),
  components: {
    "form-competition": FormCompetition,
  },
  computed: {
    ...mapState({
      form: (state: IState) => state.competition.detail,
      detailLoading: (state: IState) => state.competition.detailLoading,
    }),
  },
  watch: {
    form() {
      if (this.form) {
        this.form.winner = this.form.winner
          .filter((e: any) => {
            if (e.type === PrizeType.Standard) {
              return false;
            }
            return true;
          })
          .map((e: any) => {
            return { title: e.title };
          });
      }
    },
  },
})
export class CompetitionUpdate extends Vue {
  public previewLoading: boolean = false;
  public confirmFileType: boolean = false;
  public uploading: boolean = false;
  public progressing: number = 0;
  public loading: boolean = false;
  public cancel: boolean = false;
  public $refs: {
    form: Form;
  };

  public form: ICompetition;

  public get competitionId(): string {
    return this.$route.params.competitionId;
  }

  public mounted() {
    this.fetchData();
  }

  public async fetchData() {
    const id = this.competitionId;
    await this.$store.dispatch(CompetitionActionType.CompetitionGetById, {
      id,
      onSuccess: () => {
        return;
      },
      onFailure: () => {
        this.$router.push("/my-competition");
      },
    });
  }

  public async submit(call) {
    try {
      if (!!this.form.image && typeof this.form.image != "string") {
        this.form.image = await uploadToAWS("competition", this.form.image, { maxHeight: 1000, maxWidth: 1000 });
      }

      if (!!this.form.logo && typeof this.form.logo != "string") {
        this.form.logo = await uploadToAWS("competition", this.form.logo, { maxHeight: 1000, maxWidth: 1000 });
      }
      this.form.round = this.form.round.map(removeEmptyField);
      const formData = omit(this.form, "owner", "timeLine");
      const {
        name,
        shortName,
        description,
        country,
        image,
        howItWorks,
        rulesAndRegulations,
        isFaq,
        faq,
        isOtherInfo,
        otherInfo,
        logo,
        round,
        winner,
        allAges,
        toAge,
        fromAge,
        additionalContestants,
      } = formData;
      const customData = {
        name: name,
        shortName: shortName,
        description: description,
        country: country,
        image: image,
        howItWorks: howItWorks,
        rulesAndRegulations: rulesAndRegulations,
        isFaq: isFaq,
        faq: faq,
        isOtherInfo: isOtherInfo,
        otherInfo: otherInfo,
        logo: logo,
        additionalContestants: additionalContestants,
        round: round.map((item) => {
          return {
            ...(item.reUploadDate && { reUploadDate: item.reUploadDate, endReUploadDate: item.endReUploadDate }),
            ...(item.type !== 'standard' && { isReUpload: item.isReUpload }),
            roundName: item.roundName,
            registrationPeriod: item.registrationPeriod,
            votingPeriod: item.votingPeriod,
            announcement: item.announcement,
            type: item.type,
            endRegistrationPeriod: item.endRegistrationPeriod,
            endVotingPeriod: item.endVotingPeriod,
            numberWildcards: item.numberWildcards,
          };
        }),
        winner: winner.map((item) => {
          return {
            title: item.title,
          };
        }),
        allAges: allAges,
        toAge: toAge,
        fromAge: fromAge,
      };
      this.$store.dispatch(CompetitionActionType.CompetitionUpdate, {
        id: this.competitionId,
        form: customData,
        onSuccess: () => {
          this.$router.push("/competition");
          this.$message({
            type: "success",
            message: this.$t("update.successful").toString(),
          });
          // call();
          this.$store.commit(CompetitionMutationType.SetNotSubmit);
        },
        onFailure: () => {
          this.loading = false;
          this.$store.commit(CompetitionMutationType.SetNotSubmit);
        },
      });
    } catch (error) {
      this.loading = false;
      this.$store.commit(CompetitionMutationType.SetNotSubmit);
      this.$store.dispatch(ActionType.CatchException, error);
    }
  }

  public handleDelete() {
    this.$store.dispatch(CompetitionActionType.ToggleActive, {
      competitionId: this.competitionId,
      onSuccess: () => {
        this.$router.push("/competition");
        this.$message({
          type: "success",
          message: this.$t("deleted").toString(),
        });
      },
      onFailure: () => {
        this.loading = false;
      },
    });
  }

  public confirmDelete() {
    this.$confirm("This will deactive this competition. Continue?", "Warning", {
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      type: "warning",
    })
      .then(() => {
        this.handleDelete();
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Deactive canceled",
        });
      });
  }
}
