import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from "../Core";
import { IWallet, WalletPackageForm } from "root/models";
export interface IWalletHttpService extends ICRUDHttpService<IWallet> {
  getPaymentPackages(filter: any): Promise<any>;
  getPaymentList(filter: any): Promise<any>;
  getPaymentTransactions(filter: any): Promise<any>;
  getPaymentMethod(filter: any): Promise<any>;
  getPaymentPackage(id: string): Promise<any>;
  createPaymentPackage(item: WalletPackageForm): Promise<any>;
  updatePaymentPackage(id: string, item: WalletPackageForm): Promise<any>;
  updateTransactionNote(id: string, txnNote: any): Promise<any>;
}

export function WalletHttpService(options: IHttpServiceClientOptions): IWalletHttpService {
  const basePath = "/payment_methods";
  const paymentPath = "/admin/payment_packages";
  const listPath = "/admin/wallets";
  const transactionsPath = "/admin/transactions";
  const paymentMethosPath = "/admin/payment_methods";
  function getPaymentPackages(filter): Promise<any> {
    const url = options.httpService.getUrl(`${paymentPath}`);
    return options.httpService.get(url, {
      params: {
        page: filter.page,
        size: filter.size,
      },
    });
  }

  function getPaymentList(filter): Promise<any> {
    const url = options.httpService.getUrl(`${listPath}`);
    return options.httpService.get(url, {
      params: {
        page: filter.page,
        size: filter.size,
        sortByField: filter.sortByField,
        sortBy: filter.sortBy,
        name: filter.name,
      },
    });
  }

  function getPaymentMethod(filter): Promise<any> {
    const url = options.httpService.getUrl(`${paymentMethosPath}`);
    return options.httpService.get(url, {
      params: {
        page: filter.page,
        size: filter.size,
        sortByField: filter.sortByField,
        sortBy: filter.sortBy,
        name: filter.name,
      },
    });
  }

  function getPaymentTransactions(filter): Promise<any> {
    const url = options.httpService.getUrl(`${transactionsPath}`);
    return options.httpService.get(url, {
      params: {
        page: filter.page,
        size: filter.size,
        sortByField: filter.sortByField,
        sortBy: filter.sortBy,
        name: filter.name,
      },
    });
  }

  function getPaymentPackage(id): Promise<any> {
    const url = options.httpService.getUrl(`${paymentPath}/${id}`);
    return options.httpService.get(url);
  }

  function updatePaymentPackage(id, item): Promise<any> {
    const url = options.httpService.getUrl(`${paymentPath}/` + id);
    return options.httpService.put(url, item);
  }

  function updateTransactionNote(id, item): Promise<any> {
    const url = options.httpService.getUrl(`${transactionsPath}/` + id);
    return options.httpService.put(url, item);
  }

  function createPaymentPackage(item): Promise<any> {
    const url = options.httpService.getUrl(`${paymentPath}`);
    return options.httpService.post(url, item);
  }

  return {
    ...CRUDHttpService<IWallet>({
      ...options,
      basePath,
    }),
    getPaymentPackages,
    getPaymentList,
    getPaymentTransactions,
    getPaymentMethod,
    createPaymentPackage,
    getPaymentPackage,
    updatePaymentPackage,
    updateTransactionNote,
  };
}
