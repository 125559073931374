import { formatTimeDuration } from 'root/helpers/formatter';
import { RouterDictionaryFeed } from 'root/pages/Feed';
import { FeedUpdate } from 'root/pages/Feed/Container/Update';
import { RouterDictionaryUser } from 'root/pages/User';
import { PaginationState } from 'root/store';
import { computedPagination } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryReportPost } from '../..';
import { ReportSearchForm } from '../../Component/SearchForm';
import { ReportPostActionType, REPORTPOST_MODULE_NAME } from '../../Store/types';
import './view.scss';
@Component({
  template: require('./view.html'),
  computed: {
    ...computedPagination.call(this, REPORTPOST_MODULE_NAME)
  },
  components: {
    'report-search-form': ReportSearchForm,
    'feed-update-form': FeedUpdate
  }
})

export class ReportPostList extends Vue {

  public dialogPostVisible: boolean = false;
  public dialogReferenceId: string = '';

  public tableRowClassName({ row }) {
    if (row) {
      switch (row.status) {
      case 'active':
        return 'success-row';
      case 'suspended':
        return 'warning-row';
      case 'deleted':
        return 'deleted-row';
      }
    }

    return '';
  }

  public getPostLink(id) {
    return `${RouterDictionaryFeed.Feed}/update/${id}`;
  }

  public getUserLink(id) {
    return `${RouterDictionaryUser.User}/update/${id}`;
  }

  public formatTimeDuration(time) {
    return formatTimeDuration(time);
  }

  public openPostDialog(id) {
    this.dialogReferenceId = id;
    this.dialogPostVisible = true;
  }

  public handleUpdate() {
    this.$store.dispatch(ReportPostActionType.ReportPostFilter, {
      params: {
        pagination: PaginationState()
      }
    });
  }
  public handleEditBtn(row) {
    this.$router.push({
      path: `${RouterDictionaryReportPost.ReportPost}/update/${row.id}`
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ReportPostActionType.ReportPostFilter, {
        params: {
          pagination: PaginationState()
        }
      });
    });
  }
}
