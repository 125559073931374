import { IReportCategory } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryReportCategory } from '../..';
import { ReportCategoryForm } from '../../Component/ReportCategoryForm';
import { ReportCategoryActionType, ReportCategoryGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'reportCategory-form': ReportCategoryForm
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.reportCategory.detailLoading
    })
  }
})

export class ReportCategoryUpdate extends Vue {

  public get reportCategoryId(): string {
    return this.$route.params.reportCategoryId;
  }

  public get detail(): IReportCategory {
    return this.$store
      .getters[ReportCategoryGetterType.ReportCategory](this.reportCategoryId);
  }

  public handleSubmit(form) {
    this.$store.dispatch(ReportCategoryActionType.ReportCategoryUpdate, {
      form,
      id: this.reportCategoryId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Update successful'
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public handleDelete() {
    this.$store.dispatch(ReportCategoryActionType.ReportCategoryDelete, {
      id: this.reportCategoryId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Delete successful'
        });
        this.$router.push({
          path: RouterDictionaryReportCategory.ReportCategory
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'error',
          message: `${error.message}`
        });
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        ReportCategoryActionType.ReportCategoryGetById, {
          id: this.reportCategoryId
        }
      );
    });
  }
}
