// import { User } from 'firebase';
import { IBannerState } from 'root/pages/Banner/Store';
import { IProfile } from 'root/models';
import { ICompetitionState } from 'root/pages/Competition/Store';
import { IFeedState } from 'root/pages/Feed/Store';
import { INotificationState } from 'root/pages/Notification/Store';
import { IProfileState } from 'root/pages/Profile/Store';
import { IPromotionState } from 'root/pages/Promotion/Store';
import { IReportCategoryState } from 'root/pages/ReportCategory/Store';
import { IReportPostState } from 'root/pages/ReportPost/Store';
import { IReportCompetitionState } from 'root/pages/ReportCompetition/Store';
import { IUserState } from 'root/pages/User/Store';
import { ICategoryState } from 'root/pages/Category/Store';
import { IVersionState } from 'root/pages/Version/Store';
import { IWalletState } from 'root/pages/Wallet/Store';
import { IGifState } from 'root/pages/Gif/Store';
import { IGifCategoryState } from 'root/pages/GifCategory/Store';
import { IStickerCategoriesState } from 'root/pages/StickerCategories/Store';
import { IStickerState } from 'root/pages/Sticker/Store';

// define interface
export interface IGlobalState {
  drawer: boolean;
  isLoading: boolean;
  lastRoutePath: string;
  currentLocale: string;
  authUser: IProfile;
  topAlert: IAlertState;
  signInErrorMessage: string;
  layout: Layout;
  globalConfig: IGlobalConfiguration;
  menuIsCollapse: boolean;
}

export enum TypeAlert {
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  Error = 'error'
}

export interface IAlertState {
  opened: boolean;
  message: string;
  type: TypeAlert;
  icon: string;
}

export interface IRouteState {
  path: string;
  params: object;
  query: object;
}

export interface IPaginationState {
  page?: number;
  size?: number;
}

export interface IState {
  global: IGlobalState;
  route: IRouteState;
  promotion: IPromotionState;
  competition: ICompetitionState;
  user: IUserState;
  feed: IFeedState;
  profile: IProfileState;
  reportCategory: IReportCategoryState;
  reportPost: IReportPostState;
  reportCompetition: IReportCompetitionState;

  notification: INotificationState;
  category: ICategoryState;
  banner: IBannerState;
  version: IVersionState;
  wallet: IWalletState;
  gif: IGifState;
  gifCategory: IGifCategoryState;
  stickerCategories: IStickerCategoriesState;
  sticker: IStickerState;

}

export interface IFormState<T> {
  payload: T;
  valid: boolean;
  errorMessage: string;
}

export interface IBreadcrumbItem {
  text: string;
  link?: string;
}

export interface IGlobalConfiguration {
  systemMessage: string;
  systemStatus: number;
  role: string[];
  countries: string[];
  gender: string[];
  profession: string[];
}

export enum Layout {
  Home = 'home-layout',
  Login = 'login-layout'
}

export interface IAvatarDefault {
  user: string;
  image: string;
  video: string;
  project: string;
  plan: string;
  message: string;
}

export interface ICustomLazy {
  src: string;
  loading?: string;
  error?: string;
}

// function
export function PaginationState(params?: IPaginationState): IPaginationState {
  return {
    page: 1,
    size: 20,
    ...params,
  };
}

// define type screen

export enum ViewPortSize {
  Mobile = 768,
  Tablet = 1023,
  Desktop = 1215,
  Widescreen = 1407,
}

// define type mutation and action

export enum MutationType {
  // global
  SetCurrentLocale = 'setCurrentLocale',
  ClosePlashScreen = 'closePlashScreen',
  WindowResized = 'windowResized',
  OpenPlashScreen = 'openPlashScreen',
  SetLayout = 'setLayout',
  SetGlobalConfig = 'setGlobalConfig',
  SetMenuCollapse = 'setMenuCollapse',
  OpenTopAlert = 'openTopAlert',
  CloseTopAlert = 'closeTopAlert',
  // users
  SetListUsers = 'setListUsers',
  CreateUser = 'createUser',
  UpdateUser = 'updateUser',
  // login
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  SetProfile = 'setProfile',

  // profile
  LoadingProfile = 'loadingProfile',
  ClearStore = 'clearStore'
}

export enum ActionType {
  // global
  InitializeSettings = 'initializeSettings',
  CatchException = 'catchException',
  SetLayout = 'setLayout',
  SetGlobalConfig = 'setGlobalConfig',
  GoBack = 'goBack',
  GoToRoute = 'goToRoute',
  CollapseMenu = 'collapseMenu',
  OpenTopAlert = 'openTopAlert',
  CloseTopAlert = 'closeTopAlert',

  // navigator
  DirectNavigator = 'directNavigator',

  // login
  ChangeStateAuth = 'changeStateAuth',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',

  // profile
  UpdateProfile = 'updateProfile',
  LoadingProfile = 'loadingProfile',
  ReloadProfile = 'reloadProfile',

  // user-notification-setting
  UserNotificationSettingUpdate = 'userNotificationSettingUpdate'
}
