import Vue from 'vue';
import Component from 'vue-class-component';
import { ReportCategoryForm } from '../../Component/ReportCategoryForm';
import { ReportCategoryActionType } from '../../Store/types';
import { RouterDictionaryReportCategory } from '../../index';

@Component({
  template: require('./view.html'),
  components: {
    'reportCategory-form': ReportCategoryForm
  }
})

export class ReportCategoryCreate extends Vue {

  public loading: boolean = false;

  public handleSubmit(form) {
    this.loading = true;
    this.$store.dispatch(ReportCategoryActionType.ReportCategoryCreate, {
      form,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Create successful'
        });
        this.$router.push({
          path: RouterDictionaryReportCategory.ReportCategory
        });
      },
      onFailure: (error) => {
        this.$message({
          type: 'warning',
          message: `${error.message}`
        });
      }
    });
  }

}
