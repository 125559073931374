import { FirebaseError } from 'firebase';
import { ruleRequired } from 'root/helpers';
import { IFormLogin, SFRAuth } from 'root/services';
import Vue from 'vue';
import Component from 'vue-class-component';

import './login.scss';

@Component({
  template: require('./view.html'),
})

export class LoginContainer extends Vue {

  public email: string = '';
  public password: string = '';
  public loading: boolean = false;
  public form: IFormLogin = {
    email: '',
    password: ''
  };
  public $refs: {
    loginForm: any
  };

  public showPassword: boolean = false;

  public get rules() {
    return {
      email: [
        ruleRequired('Username or email is required')
      ],
      password: [
        ruleRequired('Password is required')
      ]
    };
  }

  public handleSubmit() {
    this.$refs.loginForm.validate((valid) => {
      if (valid) {
        this.loading = true;

        return SFRAuth.signInWithEmailAndPassword(this.form).then(() => {
          this.loading = false;
        }).catch((error: FirebaseError) => {
          this.loading = false;
          this.$message.error(error.message);
        });
      }

      return;
    });

  }
}
