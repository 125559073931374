import { IUser } from './User';

export enum NotificationStatus {
  Active = 'active',
  Suspended = 'suspended',
  Deleted = 'deleted'
}

export interface INotificationCreateForm {
  title: string;
  content: string;
  type: string[];
  pto: string;
  expiryDate: Date;
}

export interface INotification extends INotificationCreateForm {
  id: string;
  createdAt: Date;
  sender: IUser;
}

export const NotificationCreateDefaultForm = (): INotificationCreateForm => {
  return {
    title: '',
    content: '',
    type: [],
    pto: '',
    expiryDate: undefined
  };
};
