import { ITemplateEmail } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { TEMPLATEEMAIL_MODULE_NAME } from './types';

export type ITemplateEmailState = ICRUDState<ITemplateEmail>;

export const defaultState: ITemplateEmailState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations(TEMPLATEEMAIL_MODULE_NAME),
};
