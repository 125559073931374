import { Form } from 'element-ui';
import { EventCreateDefaultForm, IEventCreateForm } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: require('./view.html'),
})
export class EventCreate extends Vue {
  public $refs: {
    form: Form
  };
  public form: IEventCreateForm = EventCreateDefaultForm();

}
