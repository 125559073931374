import { isEqual } from "lodash";
import { ICompetition } from "root/models/Competition";
import { IState, ActionType } from "root/store";
import Vue from "vue";
import Component from "vue-class-component";
import { mapState } from "vuex";
import { RouterDictionaryCompetition } from "../..";
import { CompetitionActionType } from "../../Store/types";
import "./view.scss";
@Component({
  template: require("./view.html"),
  computed: {
    ...mapState({
      data: (state: IState) => state.competition.detail,
      detailLoading: (state: IState) => state.competition.detailLoading,
    }),
  },
})
export class CompetitionDetail extends Vue {
  public get competitionId(): string {
    return this.$route.params.competitionId;
  }

  public data: ICompetition;

  public get getWinner() {
    const winner = this.data.winner.filter((item) => item.applicant);
    return winner;
  }

  public handleEditBtn() {
    this.$router.push({
      path: `${RouterDictionaryCompetition.Competition}/update/${this.competitionId}`,
    });
  }
  public handleViewApplicant() {
    this.$router.push({
      path: `${RouterDictionaryCompetition.Competition}/${this.competitionId}/applicant`,
    });
  }

  public handleNewApplicantBtn() {
    this.$router.push({
      path: `${RouterDictionaryCompetition.Competition}/${this.competitionId}/applicant/new`,
    });
  }

  public tableRowClassName({ row }) {
    // if (row && this.detail.currentPhase
    //     && isEqual(row.type, this.detail.currentPhase.type)
    // ) {
    //   return 'success-row';
    // }

    return "";
  }

  public mounted() {
    // fetch("http://ip-api.com/json")
    //   .then(x=>x.json())
    //   .then((res) => {
    const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.$store.dispatch(CompetitionActionType.GetCompetitionDetail, {
      id: this.competitionId,
      currentCountry: tzid,
      onSuccess: () => {
        return;
      },
      onFailure: (error) => {
        this.$store.dispatch(ActionType.CatchException, error);
        this.$router.push("/");
      },
    });
    //     })
    // }
  }
}
