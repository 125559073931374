import { PaginationState } from 'root/store';
import { getSelectArrayOfArray } from 'root/utils';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ReportCompetitionActionType } from '../../Store/types';
@Component({
  template: require('./view.html'),
})

export class ReportSearchForm extends Vue {

  public sizes: number[] = getSelectArrayOfArray([20, 50, 100, 200]);

  public size: number = 20;
  public keywords: string = "";

  
  
  public handleSizeChange() {
    this.$store.dispatch(ReportCompetitionActionType.ReportCompetitionPaginationChange, {
      pagination: PaginationState({
        size: this.size
      })
    });
  }

  public handleSearchBtn() {
    const dataFilter = {
      keywords: this.keywords,
    };
    this.$store.dispatch(ReportCompetitionActionType.ReportCompetitionFilter, {
      params: {
        filterParams: dataFilter,
        pagination: PaginationState({
          size: this.size,
        }),
      },
    });
  }

  public handleResetBtn() {
    this.$store.dispatch(ReportCompetitionActionType.ReportCompetitionFilter, {
      params: {
        pagination: PaginationState({
          size: this.size
        })
      }
    });
  }
}
