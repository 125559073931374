export const BANNER_MODULE_NAME = 'banner';

export enum BannerActionType {
  BannerCreate = 'bannerCreate',
  BannerGetById = 'bannerGetById',
  BannerFilter = 'bannerFilter',
  BannerDelete = 'bannerDelete',
  BannerUpdate = 'bannerUpdate',
  BannerPaginationChange = 'bannerPaginationChange',
  UpdatePositionBanners = 'updatePositionBanners'
}

export enum BannerMutationType {
  BannerLoading = 'bannerLoading',
  BannerLoaded = 'bannerLoaded',
  BannerRefreshModels = 'bannerRefreshModels',
  BannerFilterModels = 'bannerFilterModels',
}

export enum BannerGetterType {
  Banner = 'banner',
  Banners = 'banners',
  BannerFilterResults = 'bannerFilterResults'
}
