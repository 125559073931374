import { GifCategory } from './Container';
import { GifCategoryCreate } from './Container/Create';
import { GifCategoryDetail } from './Container/Detail';
import { GifCategoryList } from './Container/List';
import { GifCategoryUpdate } from './Container/Update';

export enum RouterDictionaryGifCategory {
  GifCategory = '/gif-category',
  GifCategoryCreate = '/gif-category/create',
  GifCategoryDetail = '/gif-category/:gifID',
  GifCategoryList = '/gif-category/list',
  GifCategoryUpdate = '/gif-category/update/:gifID',
}

export const GifCategoryRoutes = {
  path: RouterDictionaryGifCategory.GifCategory,
  component: GifCategory,
  children: [
    {
      path: '',
      component: GifCategoryList,
      name: 'Gif Category List',
    },
    {
      path: RouterDictionaryGifCategory.GifCategoryCreate,
      component: GifCategoryCreate,
      name: 'Gif Category Create'
    },
    {
      path: RouterDictionaryGifCategory.GifCategoryDetail,
      component: GifCategoryDetail,
      name: 'Gif Category Detail'
    },
    {
      path: RouterDictionaryGifCategory.GifCategoryUpdate,
      component: GifCategoryUpdate,
      name: 'Gif Category Update'
    }
  ]
};
