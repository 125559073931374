import { Wallet } from './Container';
import { WalletPackages } from './Container/Packages';
import { WalletList } from './Container/List';
import { WalletPackagesCreate } from './Container/Create';
import { WalletTransactions } from './Container/Transactions';
import { WalletPayments } from './Container/Payments';
import { WalletPackagesUpdate } from './Container/PackagesUpdate';


export enum RouterDictionaryWallet {
  Wallet = '/wallet',
  WalletPackages = '/wallet/packages',
  WalletTransactions = '/wallet/transactions',
  WalletPackagesUpdate = '/wallet/packages/:id',
  WalletPayments = '/wallet/payments',
  WalletList = '/wallet/list',
  WalletPackagesCreate = '/wallet/create',
}

export const WalletRoutes = {
  path: RouterDictionaryWallet.Wallet,
  component: Wallet,
  children: [
    {
      path: '',
      component: Wallet,
      name: 'Wallet',
    },
    {
      path: RouterDictionaryWallet.WalletPackages,
      component: WalletPackages,
      name: 'Wallet Packages',
    },
  
    {
      path: RouterDictionaryWallet.WalletTransactions,
      component: WalletTransactions,
      name: 'Wallet Transactions',
    },
    {
      path: RouterDictionaryWallet.WalletPayments,
      component: WalletPayments,
      name: 'Wallet Payment',
    },
    {
      path: RouterDictionaryWallet.WalletPackagesUpdate,
      component: WalletPackagesUpdate,
      name: 'Packages Update',
    },
    {
      path: RouterDictionaryWallet.WalletList,
      component: WalletList,
      name: 'Wallet List',
    },
    {
      path: RouterDictionaryWallet.WalletPackagesCreate,
      component: WalletPackagesCreate,
      name: 'Wallet Create Packages',
    },
  ]
};
