import moment from "moment";
import { IUser } from "./.";
import { IPaginationState } from "root/store";
import { IApplicant } from "./Applicant";
export interface ICompetition extends ICompetitionCreateForm {
  id: string;
  totalPrice: number;
  priceDetail: IPriceDetail;
  status: string;
  owner?: IUser;
  statusRound: string;
  country: string;
  timeLine: string[];
  statusCompetition: string;
  currentRoundName: string;
  currentRoundNumber?: number;
  createdAt?: Date;
  statusPayment?: statusPayment;
  wasApplied?: boolean;
}

export interface IPriceDetail {
  additionalContestants: number;
  extraPrize: number;
  extraRound: number;
  faq: number;
  otherInfo: number;
  standardRound: number;
  totalPrice: number;
}

export enum statusPayment {
  Pending = "pending",
  Paid = "paid",
  Refund = "refund",
}

export interface ICompetitionRound {
  _id?: string;
  roundName: string;
  registrationPeriod?: Date;
  endRegistrationPeriod?: Date;
  reUploadDate?: Date;
  endReUploadDate?: Date;
  votingPeriod: Date;
  endVotingPeriod: Date;
  numberWildcards: number;
  announcement: Date;
  type: CompetitionRoundType;
  roundId: string;
  isAddedWildcards?: boolean;
  isReUpload?: boolean;
}

export interface ICompetitionWinner {
  applicant?: IApplicant;
  number?: number;
  title: string;
  type?: string;
}

export enum CompetitionRoundType {
  Standard = "standard",
  Extra = "extra",
}

export interface ICompetitionCreateForm {
  name: string;
  shortName: string;
  description: string;
  image: any;
  logo: any;
  howItWorks: string;
  rulesAndRegulations: string;
  isFaq: boolean;
  faq: string;
  isOtherInfo: boolean;
  otherInfo: string;
  round: ICompetitionRound[];
  winner: ICompetitionWinner[];
  country: string;
  allAges: boolean;
  fromAge: number;
  toAge: number;
  additionalContestants: number;
}

export interface ICompetitionEditForm {
  description: string;
  image: any;
  logo: any;
  rulesAndRegulations: string;
}

export interface ICompetitionFilterParams {
  name: string;
  countryName: string;
  ageRange: string;
  type: string;
}

export const ICompetitionFilterParamsDefault = (): ICompetitionFilterParams => {
  return {
    name: "",
    countryName: "",
    ageRange: "",
    type: "CompetitionFilterType.OnGoing",
  };
};

export const CompetitionEditFormDefault = (): ICompetitionEditForm => {
  return {
    description: "",
    image: "",
    logo: "",
    rulesAndRegulations: "",
  };
};

export const CompetitionCreateFormDefault = (): ICompetitionCreateForm => {
  return {
    name: null,
    shortName: "",
    description: "",
    image: "",
    logo: "",
    howItWorks: "",
    rulesAndRegulations: "",
    isFaq: false,
    faq: "",
    isOtherInfo: false,
    otherInfo: "",
    round: [],
    winner: [],
    country: null,
    fromAge: 0,
    toAge: 0,
    allAges: false,
    additionalContestants: 0,
  };
};

export const CompetitionDefault = (): ICompetition => {
  return {
    id: null,
    name: "",
    shortName: "",
    description: "",
    image: "",
    logo: "",
    howItWorks: "",
    rulesAndRegulations: "",
    isFaq: false,
    faq: "",
    isOtherInfo: false,
    otherInfo: "",
    round: [],
    winner: [],
    owner: null,
    totalPrice: 0,
    priceDetail: {
      additionalContestants: 0,
      extraPrize: 0,
      extraRound: 0,
      faq: 0,
      otherInfo: 0,
      standardRound: 0,
      totalPrice: 0,
    },
    statusRound: "",
    country: "",
    timeLine: [],
    statusCompetition: "",
    status: "",
    currentRoundName: "",
    fromAge: 0,
    toAge: 0,
    allAges: false,
    additionalContestants: 0,
  };
};

export const ICompetitionRoundDefault = (): ICompetitionRound => {
  return {
    roundName: "",
    registrationPeriod: null,
    votingPeriod: null,
    endRegistrationPeriod: null,
    endVotingPeriod: null,
    numberWildcards: 0,
    announcement: null,
    reUploadDate: null,
    endReUploadDate: null,
    type: CompetitionRoundType.Extra,
    roundId: null,
    isAddedWildcards: false,
    isReUpload: false,
  };
};

export function serializeICompetitionRound(model: ICompetitionRound): ICompetitionRound {
  if (!model) {
    return null;
  }
  if (model.type == CompetitionRoundType.Standard) {
    return {
      roundName: model.roundName,
      votingPeriod: model.votingPeriod,
      announcement: model.announcement,
      registrationPeriod: model.registrationPeriod,
      endRegistrationPeriod: model.endRegistrationPeriod,
      endVotingPeriod: model.endVotingPeriod,
      numberWildcards: model.numberWildcards,
      type: model.type,
      roundId: model.roundId,
    };
  }
  return {
    roundName: model.roundName,
    registrationPeriod: model.registrationPeriod,
    votingPeriod: model.votingPeriod,
    endRegistrationPeriod: model.endRegistrationPeriod,
    endVotingPeriod: model.endVotingPeriod,
    numberWildcards: model.numberWildcards,
    announcement: model.announcement,
    reUploadDate: model.reUploadDate,
    endReUploadDate: model.endReUploadDate,
    type: model.type,
    roundId: model.roundId,
    isAddedWildcards: model.isAddedWildcards,
    isReUpload: model.isReUpload,
  };
}

export const ICompetitionWinnerDefault = (): ICompetitionWinner => {
  return {
    title: "",
  };
};

export const CompetitionItemDefault = (): ICompetitionCreateForm => {
  return {
    name: "",
    shortName: "",
    description: "",
    image: "",
    logo: "",
    howItWorks: "",
    rulesAndRegulations: "",
    isFaq: false,
    faq: "",
    isOtherInfo: false,
    otherInfo: "",
    round: [],
    winner: [],
    country: "",
    fromAge: 0,
    toAge: 0,
    allAges: false,
    additionalContestants: 0,
  };
};

export function serializeCompetitionItemDefault(model: ICompetition): ICompetitionCreateForm {
  if (!model) {
    return null;
  }
  return {
    name: model.name,
    shortName: model.shortName,
    description: model.description,
    image: model.image,
    logo: model.logo,
    howItWorks: model.howItWorks,
    rulesAndRegulations: model.rulesAndRegulations,
    isFaq: model.isFaq,
    faq: model.faq,
    isOtherInfo: model.isOtherInfo,
    otherInfo: model.otherInfo,
    round: model.round.map(serializeICompetitionRound),
    winner: model.winner,
    country: model.country,
    fromAge: model.fromAge,
    toAge: model.toAge,
    allAges: model.allAges,
    additionalContestants: model.additionalContestants,
  };
}

export enum StatusCompetition {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
}

export const formatCompetitionDate = (date) => {
  return moment(date).format("Do MMM YYYY");
};

export enum CompetitionFilterType {
  OnGoing = "ongoing",
  UpComing = "upcoming",
  Completed = "completed",
  Pending = "pending",
  All = "",
}

export enum CompetitionPrizeType {
  The1stPrize = "1st_prize",
  The2ndPrize = "2nd_prize",
  The3rdPrize = "3rd_prize",
  WildCards = "wild_cards",
  Semi_Final = "Semi_final",
}

export interface ICompetitionFilter extends IPaginationState {
  type: CompetitionFilterType;
}

export interface winner {
  prize: CompetitionPrizeType;
  ApplicantId: string;
}

export enum PrizeType {
  Standard = "standard",
  Extra = "extra",
}
