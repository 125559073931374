import Vue from 'vue';
import Component from 'vue-class-component';
// import '../../Container/CreateCompetition/styles.scss';
import { generateTimeLineCompetition, ruleEmail, ruleRequired } from 'root/helpers';
import { SFRCheckbox } from 'root/components/SFRCheckbox';
import { ICompetitionCreateForm } from 'root/models';

@Component({
  template: require('./view.html'),
  components: {
    'sfr-checkbox': SFRCheckbox
  },

  props: {
    form: Object,
    isUpdate: Boolean,
  }
})
export class Step3 extends Vue {
  public form: ICompetitionCreateForm;
  public timelines(): string[]{
    return generateTimeLineCompetition(this.form)
  }
  public ExtraPrice() {
    const price = (this.form.winner.length) * 9.99;
    return price;
  }

  public previewAttachmentUrl(file: any) {
    if(typeof file == 'string'){
      return file;
    }
    if(file instanceof Blob){
      return window.URL.createObjectURL(file);
    }
  }
  public RoundPrice() {
    let price = 0;
    if(this.form.round.length >1) {
      for (let i = 1; i < this.form.round.length; i++) {
        if (this.form.round[i].isAddedWildcards == true) {
          price += 10.99;
        } else {
          price += 9.99;
        }
      }
    }
    return price;
  }
  public TotalPrice() {
    let sum = this.ExtraPrice() + this.RoundPrice();
    if (this.form.isFaq == true) {
      sum += 9.99;
    }
    if (this.form.isOtherInfo == true) {
      sum += 9.99;
    }
    return sum;
  }

  public get rules() {
    return {
      required: ruleRequired('should.update.correctly'),
      email: ruleEmail('email.valid.signup')
    };
  }
}
