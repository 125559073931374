import Vue from 'vue';
import Component from 'vue-class-component';
import { BannerForm } from '../../Component/BannerForm';
import { BannerActionType } from '../../Store/types';
import { RouterDictionaryBanner } from '../..';
@Component({
  template: require('./view.html'),
  components: {
    'banner-form': BannerForm
  }
})
export class BannerCreate extends Vue {

  public uploading: boolean = false;

  public handleSubmit(form) {
    this.uploading = true;
    this.$store.dispatch(BannerActionType.BannerCreate, {
      form,
      onSuccess: () => {
        this.uploading = false;
        this.$message({
          type: 'success',
          message: 'Create success'
        });
        this.$router.push({
          path: RouterDictionaryBanner.Banner
        });
      },
      onFailure: () => {
        this.$message({
          type: 'error',
          message: 'Create failed'
        });
      }
    });
  }

}
