import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterDictionaryWallet } from '../..';
import { PackagesForm } from '../../Component/PackagesForm';
import { ActionTypeWallet } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'package-form': PackagesForm
  },
})

export class WalletPackagesCreate extends Vue {
  public uploading: boolean = false;

  public handleSubmit(form) {
    this.uploading = true;
    this.$store.dispatch(ActionTypeWallet.PaymentPackageCreate, {
      form: form,
      onSuccess: () => {
        this.uploading = false;
        this.$message({
          type: 'success',
          message: 'Create success'
        });
        this.$router.push({
          path: RouterDictionaryWallet.WalletPackages
        });
      },
      onFailure: () => {
        this.$message({
          type: 'error',
          message: 'Create failed'
        });
      }
    });
  }
}
