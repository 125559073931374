import { ReportPost } from './Container';
import { ReportPostCreate } from './Container/Create';
import { ReportPostDetail } from './Container/Detail';
import { ReportPostList } from './Container/List';
import { ReportPostUpdate } from './Container/Update';

export enum RouterDictionaryReportPost {
  ReportPost = '/report-post',
  ReportPostCreate = '/report-post/create',
  ReportPostDetail = '/report-post/:reportPostId',
  ReportPostUpdate = '/report-post/update/:reportPostId'
}

export const ReportPostRoutes = {
  path: RouterDictionaryReportPost.ReportPost,
  component: ReportPost,
  children: [
    {
      path: '',
      component: ReportPostList,
      name: 'Report Post List',
    },
    {
      path: RouterDictionaryReportPost.ReportPostCreate,
      component: ReportPostCreate,
      name: 'Create'
    },
    {
      path: RouterDictionaryReportPost.ReportPostDetail,
      component: ReportPostDetail,
      name: 'Detail'
    },
    {
      path: RouterDictionaryReportPost.ReportPostUpdate,
      component: ReportPostUpdate,
      name: 'Update'
    }
  ]
};
