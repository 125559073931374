export const FEED_MODULE_NAME = 'feed';

export enum FeedActionType {
  FeedCreate = 'feedCreate',
  FeedUpdate = 'feedUpdate',
  FeedFilter = 'feedFilter',
  FeedGetById = 'feedGetById',
  FeedDelete = 'feedDelete',
  FeedPaginationChange = 'feedPaginationChange',
  FeedDeleteComment = 'FeedDeleteComment'
}

export enum FeedMutationType {
  FeedLoading = 'feedLoading',
  FeedLoaded = 'feedLoaded',
  FeedRefreshModels = 'feedRefreshModels',
  FeedUpdateModels = 'feedUpdateModels',
  FeedFilterModels = 'feedFilterModels',
}

export enum FeedGetterType {
  Feed = 'feed',
  Feeds = 'feeds',
  FeedFilterResults = 'feedFilterResults'
}
