import Vue from 'vue';
import Component from 'vue-class-component';
import { Breadcrumb } from './Breadcrumb/component';
import { MyProfile } from './MyProfile/component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    breadcrumb: Breadcrumb,
    'my-profile': MyProfile
  }
})
export class SFRHeader extends Vue {

}
