import { IReportCategory } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RouterDictionaryReportCategory } from '../..';
import { ReportCategoryActionType, ReportCategoryGetterType } from '../../Store/types';
import './view.scss';
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.reportCategory.detailLoading
    })
  }
})

export class ReportCategoryDetail extends Vue {

  public get reportCategoryId(): string {
    return this.$route.params.reportCategoryId;

  }

  public get detail(): IReportCategory {
    return this.$store
      .getters[ReportCategoryGetterType.ReportCategory](this.reportCategoryId);
  }

  public handleEditBtn() {
    this.$router.push({
      path: `${RouterDictionaryReportCategory.ReportCategory}/update/${this.reportCategoryId}`
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(
        ReportCategoryActionType.ReportCategoryGetById, {
          id: this.reportCategoryId
        }
      );
    });
  }
}
