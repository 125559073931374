import { IPromotion } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { PromotionForm } from '../../Component/Form';
import { PromotionActionType, PromotionGetterType } from '../../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'promotion-form': PromotionForm
  },
  computed: {
    ...mapState({
      detailLoading: (state: IState) => state.promotion.detailLoading
    })
  },
})
export class PromotionDetail extends Vue {

  public get promotionId(): string {
    return this.$route.params.promotionId;
  }

  public get detail(): IPromotion {
    return this.$store
      .getters[PromotionGetterType.Promotion](this.promotionId);
  }

  public handleSubmit(form) {
    this.$store.dispatch(PromotionActionType.PromotionUpdate, {
      form,
      id: this.promotionId,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: 'Update successfull'
        });
      }
    });
  }

  public mounted() {

    this.$nextTick(() => {
      this.$store.dispatch(
        PromotionActionType.PromotionGetById, {
          id: this.promotionId
        });
    });

  }
}
